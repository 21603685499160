<template>
  <v-container>
    <div class="d-flex justify-center align-center mt-3">
      <div class="d-flex flex-row">
        <div class="consigne landscape-mode" style="margin-top: 170px">
          {{ $t("SignatureMob.title_signature", language) }}
        </div>
        <div class="d-flex justify-center mb-4 drawer-container">
          <drawer
            ref="drawer"
            style="height: 80%; margin-left: -250px"
            :clear.sync="clearCanvas"
            @empty="canvasEmpty = $event"
            @end="send"
          ></drawer>
        </div>
        <div class="d-flex flex-column justify-space-between my-5">
          <div class="eff">
            <v-btn
              class="landscape-mode btn"
              color="#d70000"
              :disabled="canvasEmpty || uploading"
              @click="$refs.drawer.clearCanvas()"
            >
              <v-icon left>mdi-backup-restore</v-icon>
              {{ $t("SignatureMob.clear", language) }}
            </v-btn>
          </div>
          <div
            v-show="uploading"
            class="text-center align-middle landscape-mode"
            style="height: 300px"
          >
            <v-progress-circular
              :size="40"
              :width="7"
              indeterminate
            ></v-progress-circular>
            <br />
            <span>{{ $t("SignatureMob.wait", language) }}</span>
          </div>
          <v-btn
            color="#2fc977"
            class="eff1 landscape-mode btn"
            :disabled="canvasEmpty || uploading"
            @click="validate"
            :loading="uploading"
            >{{ $t("SignatureMob.send", language) }}</v-btn
          >
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Drawer from "@/components/DrawerAppMobile";
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";

export default {
  name: "SignatureApp",
  data() {
    return {
      clearCanvas: false,
      canvasEmpty: true,
      uploading: false,
      qrCode: null,
      signature: null,
      language: "",
    };
  },
  components: { Drawer },
  methods: {
    validate() {
      this.uploading = true;
      this.$refs.drawer.validateCanvas();
    },
    async send({ signatureUrl }) {
      //need to pin signature on the qrcode
      if (this.$route.params.uid !== undefined) {
        const signatureFormData = new FormData();
        const signatureBlob = await (await fetch(signatureUrl)).blob();
        signatureFormData.append("file", signatureBlob);
        signatureFormData.append("type", "signature");
        requestService.post(`/file/upload`, signatureFormData).then((data) => {
          this.$store.commit("signatureUid", data.data.uid);
          requestService
            .get(
              `/service/pin-signature-qrcode/${data.data.uid}/${this.$route.params.uid}`
            )
            .then(() => {
              this.$router.push({ name: "DataLinked" });
            });
        });
      }
    },
    load() {
      //need to take photo and link it with the qrcode (uid)
      if (this.$store.params.uid !== undefined) {
        requestService
          .get(`/service/qrcode-check/${this.$store.params.uid}`)
          .then((data) => {
            this.qrCode = data.data;
            if (data.data === null) {
              //Qrcode already consumed
              this.$router.push({ name: "QrCodeConsumed" });
            } else if (!data.data.needSignature) {
              this.$router.push({ name: "ErrorPage" });
            } else {
              this.signatureUrl = data.data.signatureUrl;
            }
          });
      }
    },
  },
  mounted() {
    this.load();
    this.language = localStorageService.getLanguage();
  },
  updated() {
    localStorageService.setStripeClientSecret(null);
  },
};
</script>

<style scoped>
.landscape-mode {
  writing-mode: sideways-lr;
}
.consigne {
  transform: rotate(270deg);
  transform-origin: center center;
  align-items: center;
  justify-content: center;
  display: inline-block;
  max-width: 400px;
  min-width: 400px;
  max-height: 110px;
  min-height: 110px;

  /*height: 100vh;*/
}
.btn {
  height: 50px;
  width: 150px;
  max-height: 50px;
  max-width: 150px;
  min-height: 50px;
  min-width: 150px;
  transform: rotate(270deg);
  text-transform: none;
}
@media only screen and (max-width: 700px) {
  .eff {
    color: white;
    margin-top: 60%;
    margin-left: -100px;
  }
  .eff1 {
    color: white;
    margin-bottom: 300%;
    margin-left: -100px;
  }
}
@media only screen and (min-width: 700px) {
  .eff {
    color: white;
    margin-top: 60%;
    margin-left: -100px;
  }
  .eff1 {
    color: white;
    margin-bottom: 300%;
    margin-left: -100px;
  }
}
@media only screen and (max-width: 280px) {
}
</style>
