var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"country"},[_c('p',[_vm._v(_vm._s(_vm.$t("printedCopy.title_print", _vm.language)))])]),_c('div',{staticClass:"price"},[_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.pricePrint == null ? "" : _vm.pricePrint.local_currency_symbol)+" "+_vm._s(_vm.pricePrint == null ? "" : _vm.pricePrint.amount_in_local_currency)),_c('span',{staticClass:"suite"},[_vm._v(_vm._s(_vm.$t("printedCopy.copy", _vm.language)))])])]),_c('div',{staticClass:"plan"},[_c('div',{staticClass:"list"},[_c('v-list',[_c('v-list-item',{staticStyle:{"min-height":"30px"}},[_c('v-icon',{staticStyle:{"margin-right":"5px","color":"#2fc977"},attrs:{"size":"large"}},[_vm._v("mdi-check-circle-outline")]),_c('span',{staticClass:"font"},[_vm._v(_vm._s(_vm.$t("printedCopy.photoQualityPrint", _vm.language)))])],1),(_vm.showParagraph)?_c('v-list-item',{staticStyle:{"min-height":"30px"}},[_c('v-icon',{staticStyle:{"margin-right":"5px","color":"#2fc977"},attrs:{"size":"large"}},[_vm._v("mdi-check-circle-outline")]),_c('span',{staticClass:"font"},[_vm._v(_vm._s(_vm.$t("printedCopy.guarantee", _vm.language)))])],1):_vm._e(),_c('v-list-item',{staticStyle:{"min-height":"30px"}},[_c('v-icon',{staticStyle:{"margin-right":"5px","color":"#2fc977"},attrs:{"size":"large"}},[_vm._v("mdi-check-circle-outline")]),_c('span',{staticClass:"font"},[_vm._v(_vm._s(_vm.$t("printedCopy.delivered", _vm.language)))])],1)],1)],1)]),(_vm.boardDetoured.base64 != null)?_c('div',{staticClass:"image"},[_c('div',[_c('v-img',{attrs:{"width":"310","height":"200","aspect-ratio":1,"src":(_vm.priceOPVerification != null && _vm.priceOPVerification != undefined) ||
          _vm.pricePhoto.purpose.verification_needed
            ? `data:image/png;base64,${_vm.boardDetoured.base64}`
            : `data:image/png;base64,${_vm.boardResized.base64}`,"cover":""}})],1),_c('br'),_c('div',[(_vm.boardDetoured.count === 6)?_c('div',{staticClass:"card"},[_c('span',[_vm._v(_vm._s(_vm.pricePhoto.purpose.photo_board_description)+" ")])]):_vm._e(),_c('div',{staticClass:"all"},[_c('v-btn-toggle',{staticClass:"number",attrs:{"variant":"outlined","divided":""},model:{value:(_vm.toggle_none),callback:function ($$v) {_vm.toggle_none=$$v},expression:"toggle_none"}},[_c('v-btn',{attrs:{"disabled":_vm.boardCount === 0},on:{"click":_vm.decrementBoardCount}},[_c('div',[_c('v-img',{attrs:{"width":"10","height":"2","src":require("../../assets/images/Rectangle 97.png")}})],1)]),_c('v-btn',[_c('span',[_vm._v(_vm._s(_vm.boardCount))])]),_c('v-btn',{attrs:{"disabled":_vm.boardCount === 1},on:{"click":_vm.incrementBoardCount}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)]),_c('br')]):_vm._e(),_c('br'),(_vm.boardEphotoDetoured.base64 != null)?_c('div',{staticClass:"image"},[_c('div',[_c('v-img',{staticStyle:{"margin-top":"5%","margin-bottom":"5%"},attrs:{"width":"310","height":"200","cover":"","aspect-ratio":1,"src":(_vm.priceOPVerification != null && _vm.priceOPVerification != undefined) ||
          _vm.pricePhoto.purpose.verification_needed
            ? `data:image/png;base64,${_vm.boardEphotoDetoured.base64}`
            : `data:image/png;base64,${_vm.boardEphotoResized.base64}`}})],1),_c('br'),_c('div',[_c('div',{staticClass:"all"},[_c('v-btn-toggle',{staticClass:"number",attrs:{"variant":"outlined","divided":""},model:{value:(_vm.toggle_exclusive),callback:function ($$v) {_vm.toggle_exclusive=$$v},expression:"toggle_exclusive"}},[_c('v-btn',{attrs:{"disabled":_vm.ephotoBoardCount === 0},on:{"click":_vm.decrementEphotoBoardCount}},[_c('div',[_c('v-img',{attrs:{"width":"10","height":"2","src":require("../../assets/images/Rectangle 97.png")}})],1)]),_c('v-btn',[_c('span',[_vm._v(_vm._s(_vm.ephotoBoardCount))])]),_c('v-btn',{attrs:{"disabled":_vm.ephotoBoardCount === 1},on:{"click":_vm.incrementEphotoBoardCount}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)])]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-around align-center flex-column flex-sm-row"},[_c('button',{staticClass:"take3",attrs:{"rounded":""},on:{"click":_vm.next}},[_c('span',{staticClass:"textpl"},[_vm._v(_vm._s(_vm.$t("printedCopy.proceed_price", _vm.language))+" "+_vm._s(_vm.total)+" "+_vm._s(_vm.pricePrint == null ? "" : _vm.pricePrint.local_currency_symbol))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }