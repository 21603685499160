<template>
  <v-container>
    <div class="country">
      <p>{{ $t("ChoosePlan.title_choose_plan", language) }}</p>
    </div>
    <v-row style="font-family: Georama">
      <v-col cols="5">
        <button class="btn" @click="selectedBasic">
          <div class="search-icon">
            <div
              class="d-flex justify-space-around align-center flex-column flex-sm-row"
            >
              <v-btn
                class="basique"
                style="
                  background-color: #a5a5a5;
                  color: white;
                  border-radius: 25px;
                  height: 20px;
                  margin-top: -8%;
                "
              >
                <span class="textbasique">{{
                  $t("ChoosePlan.basique", language)
                }}</span>
              </v-btn>
            </div>
          </div>
          <v-img
            class="noir"
            src="../../assets/images/Gratuit.png"
            width="48"
            height="48"
            style="margin-top: 8px"
            cover
          ></v-img>
          <h6 class="document">{{ $t("ChoosePlan.Gratuit", language) }}</h6>
        </button>
      </v-col>
      <v-col cols="7">
        <button
          class="btn1"
          v-if="price != undefined && price != null"
          @click="selectedPro"
        >
          <div class="search-icon">
            <!-- <v-img
              style="border-radius: 25px; margin-top: -20px"
              width="87"
              height="24"
              src="../../assets/images/choix.png"
            ></v-img>-->
            <v-btn
              class="meilleurChoix"
              style="
                background-color: #000000;
                color: white;
                border-radius: 25px;
                height: 20px;
              "
            >
              <span class="textbasique">{{
                $t("ChoosePlan.Best_choice", language)
              }}</span>
            </v-btn>
          </div>
          <v-img
            class="noir"
            src="../../assets/images/Pro2.png"
            width="58"
            height="48"
            cover
          ></v-img>
          <h6 class="document1">
            {{ price.local_currency_symbol }}
            {{ price.amount_in_local_currency }}
          </h6>
        </button>
      </v-col>
    </v-row>
    <br />
    <div
      class="bg-surface-variant mb-6"
      style="margin-left: -2%; font-family: Georama"
    >
      <v-row align="start" no-gutters class="ma-2">
        <v-col cols="8">
          <v-sheet style="font-weight: bold; font-size: 12px">
            {{ $t("ChoosePlan.feature", language) }}
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet style="margin-right: 3px">
            <v-img
              style="border-radius: 25px"
              width="65"
              height="18"
              src="../../assets/images/point1.png"
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet>
            <v-img
              style="border-radius: 25px"
              width="65"
              height="18"
              src="../../assets/images/point2.png"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>
      <hr class="line" />
      <v-row align="start" no-gutters>
        <v-col cols="8">
          <v-sheet style="font-size: small; color: #949494" class="ma-2">
            {{ $t("ChoosePlan.Compliance_guarantee", language) }}
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-2">
            <v-img
              width="8"
              height="2"
              style="margin-left: 15%"
              src="../../assets/images/Rectangle 97.png"
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-1">
            <v-img
              width="15"
              height="15"
              style="margin-left: 15%"
              src="../../assets/images/check-circle-Bold.png"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row align="start" no-gutters>
        <v-col cols="8">
          <v-sheet style="font-size: small; color: #949494" class="ma-2">
            {{ $t("ChoosePlan.Background_removal", language) }}
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-2">
            <v-img
              width="8"
              height="2"
              style="margin-left: 15%"
              src="../../assets/images/Rectangle 97.png"
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-1">
            <v-img
              width="15"
              height="15"
              style="margin-left: 15%"
              src="../../assets/images/check-circle-Bold.png"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row align="start" no-gutters>
        <v-col cols="8">
          <v-sheet style="font-size: small; color: #949494" class="ma-2">
            {{ $t("ChoosePlan.Photo_check_by_trained_expert", language) }}
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-2">
            <v-img
              width="8"
              height="2"
              style="margin-left: 15%"
              src="../../assets/images/Rectangle 97.png"
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-1">
            <v-img
              width="15"
              height="15"
              style="margin-left: 15%"
              src="../../assets/images/check-circle-Bold.png"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row align="start" no-gutters>
        <v-col cols="8">
          <v-sheet style="font-size: small; color: #949494" class="ma-2">
            {{ $t("ChoosePlan.Adjustments", language) }}
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-2">
            <v-img
              width="8"
              height="2"
              style="margin-left: 15%"
              src="../../assets/images/Rectangle 97.png"
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-1">
            <v-img
              width="15"
              height="15"
              style="margin-left: 15%"
              src="../../assets/images/check-circle-Bold.png"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <div v-if="more" style="margin-left: -2%; font-family: Georama">
      <v-row align="start" no-gutters>
        <v-col cols="8">
          <v-sheet style="font-size: small; color: #949494" class="ma-2">
            {{ $t("ChoosePlan.Receive_immediately_in_e-mail", language) }}
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-2">
            <v-img
              width="8"
              height="2"
              style="margin-left: 15%"
              src="../../assets/images/Rectangle 97.png"
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-1">
            <v-img
              width="15"
              height="15"
              style="margin-left: 15%"
              src="../../assets/images/check-circle-Bold.png"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row align="start" no-gutters>
        <v-col cols="8">
          <v-sheet style="font-size: small; color: #949494" class="ma-2">
            {{ $t("ChoosePlan.Printable_template", language) }}
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-2">
            <v-img
              width="8"
              height="2"
              style="margin-left: 15%"
              src="../../assets/images/Rectangle 97.png"
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-1">
            <v-img
              width="15"
              height="15"
              style="margin-left: 15%"
              src="../../assets/images/check-circle-Bold.png"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row align="start" no-gutters>
        <v-col cols="8">
          <v-sheet style="font-size: small; color: #949494" class="ma-2">
            {{ $t("ChoosePlan.receive_exact", language) }}
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-2">
            <v-img
              width="8"
              height="2"
              style="margin-left: 15%"
              src="../../assets/images/Rectangle 97.png"
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-1">
            <v-img
              width="15"
              height="15"
              style="margin-left: 15%"
              src="../../assets/images/check-circle-Bold.png"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row align="start" no-gutters>
        <v-col cols="8">
          <v-sheet style="font-size: small; color: #949494" class="ma-2">
            {{ $t("ChoosePlan.Print_order", language) }}
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-2">
            <v-img
              width="8"
              height="2"
              style="margin-left: 15%"
              src="../../assets/images/Rectangle 97.png"
            ></v-img>
          </v-sheet>
        </v-col>
        <v-col cols="2">
          <v-sheet class="pa-2 ma-1">
            <v-img
              width="15"
              height="15"
              style="margin-left: 15%"
              src="../../assets/images/check-circle-Bold.png"
            ></v-img>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <div class="lineview" v-if="more == false">
      <hr style="color: #f7f7f7" />
    </div>
    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
      v-if="more == false"
    >
      <v-btn class="take1" color="white" rounded @click="viewAll()">
        <span class="text5">{{ $t("ChoosePlan.view_all", language) }}</span>
      </v-btn>
    </div>
    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <button
        class="take3"
        color="indigo-darken-3"
        rounded
        @click="next"
        v-if="price == undefined || price == null"
      >
        <span class="textpl">{{ $t("ChoosePlan.button", language) }}</span>
      </button>
    </div>
  </v-container>
</template>

<script>
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "ChoosePlan",
  data: () => ({
    price: {},
    more: false,
    language: "",
  }),
  methods: {
    // Method to toggle the paragraph's visibility using a store mutation
    toggleParagraph() {
      this.$store.commit("toggleParagraph"); // Call the "toggleParagraph" mutation from the Vuex store
    },
    viewAll() {
      this.more = true;
    },
    // Method to navigate to the "PrintedCopy" route
    next() {
      this.$router.push({ name: "PrintedCopy" });
    },
    // Method to handle selection of basic option
    selectedBasic() {
      localStorageService.setPriceOPOfferVerification(null); // Set price OP offer verification to null
      this.toggleParagraph(); // Toggle the paragraph's visibility using the store mutation
      this.$router.push({ name: "PrintedCopy" }); // Navigate to the "PrintedCopy" route
    },
    // Method to handle selection of pro option
    selectedPro() {
      localStorageService.setPriceOPOfferVerification(this.price); // Set price OP offer verification to "this.price"
      this.$router.push({ name: "PrintedCopy" }); // Navigate to the "PrintedCopy" route
    },
  },
  mounted() {
    this.price = localStorageService
      .getPrice()
      .optional_offers.find((x) => x.product.verification_needed);

    localStorageService.setStripeClientSecret(null);
    var price = localStorageService.getPrice();
    this.language = localStorageService.getLanguage();

    var countryCode = localStorageService.getCountryCode();
    if (
      price === null ||
      price === undefined ||
      countryCode === null ||
      countryCode === undefined
    ) {
      this.$router.push({ name: "welcomeMobile" });
      return;
    }

    var photoUidDetoured = localStorageService.getPhotoUidDetoured();
    if (photoUidDetoured === null || photoUidDetoured === undefined) {
      this.$router.push({
        name: "TakePhotoMobile",
        params: { countryCode: countryCode, priceId: this.price.id },
      });
      return;
    }

    var email = localStorageService.getEmailOwner();
    if (email === null || email === undefined) {
      this.$router.push({
        name: "EmailApp",
        params: { country: localStorageService.getCountryCode() },
      });
      return;
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
@media only screen and (max-width: 700px) {
  .text1 {
    text-transform: capitalize;
  }
  .basique {
    width: 50px;
    height: 10px;
    background: #a5a5a5;
    margin-right: 50%;
    margin-top: -18%;
  }
  .meilleurChoix {
    width: 120px;
    height: 10px;
    background: #000000;
    margin-right: 50%;
    margin-top: -18%;
    color: white;
  }
  .textbasique {
    font-size: 10px;
    text-transform: capitalize;
  }
  .optionprint1 {
    margin-left: 75%;
    font-size: 14px;
    margin-top: -6%;
  }
  .optionprint2 {
    margin-left: 88%;
    font-size: 14px;
    margin-top: -6%;
    color: #2fc977;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    margin-top: 25%;
    width: 350px;
    height: 52px;
    background: #efefef;
    border-radius: 28px;
  }
  .textpl {
    width: 153px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Secondary Gray */

    color: #4d4d4d;

    opacity: 0.35;
    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text5 {
    width: 51px;
    height: 20px;
    text-transform: lowercase;
    font-family: "Georama";
    font-style: normal;
    font-weight: bold;
    font-size: 13px;

    /* identical to box height, or 125% */

    text-align: center;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .features {
    width: 220px;
    height: 24px;
    left: 0px;
    top: 0px;
    margin-top: 20%;
    margin-left: 4%;
    font-weight: bold;
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .mini {
    text-transform: lowercase;
  }

  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 5%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #000000;
  }
  .document1 {
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Accent */

    color: #2fc977;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    margin-top: 2%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: -3px;
    margin-bottom: 10px;
    z-index: 5;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
  }
  .btn {
    align-content: center;
    box-sizing: border-box;
    border: 1px solid #efefef;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 16px;

    width: 169px;
    height: 160px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn1 {
    align-content: center;
    box-sizing: border-box;
    border: 1px solid #efefef;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 16px;
    margin-left: 15%;
    width: 169px;
    height: 160px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .ronde {
    border-radius: 20%;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 20%;

    /* Tetriary Ligjht */

    color: #efefef;
  }
  .search-icon {
    align-self: flex-end;
    margin-left: -25%;
  }
  .feabasic {
    margin-left: 55%;
    margin-top: -7%;
  }
  .feapro {
    margin-left: 78%;
    margin-top: -7%;
  }
  .textfeature {
    margin-left: 4%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 15px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;
  }
  .allit {
    margin-left: 80%;
    margin-top: -3%;
  }
  .gotit {
    margin-left: 93%;
    margin-top: -3%;
  }
  .line {
    width: 340px;
    height: 1px;

    /* Tetriary Ligjht */

    color: white;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: 2%;
    margin-top: 4%;
    margin-bottom: 2%;
  }
  .lineview {
    width: 340px;
    height: 1px;
    left: 0px;

    /* Tetriary Ligjht */

    background: #efefef;
  }
  .take1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;

    width: 116px;
    height: 38px;
    margin-top: -5%;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .text1 {
    text-transform: capitalize;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 20px;
    min-width: 64px;
    padding: 0 16px;
  }
  .basique {
    width: 50px;
    height: 10px;
    background: #a5a5a5;
    margin-right: 55%;
    margin-top: -3%;
  }
  .meilleurChoix {
    width: 120px;
    height: 10px;
    background: #000000;
    margin-right: 55%;
    margin-top: -3%;
  }
  .textbasique {
    font-size: 10px;
    text-transform: capitalize;
  }
  .optionprint1 {
    margin-left: 75%;
    font-size: 14px;
    margin-top: -6%;
  }
  .optionprint2 {
    margin-left: 88%;
    font-size: 14px;
    margin-top: -6%;
    color: #2fc977;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    margin-top: 19%;
    width: 500px;
    height: 52px;
    margin-right: 4%;
    background: #efefef;
    border-radius: 28px;
  }
  .textpl {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    text-align: center;
    color: #4d4d4d;
    opacity: 0.35;
    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text5 {
    width: 51px;
    height: 20px;
    text-transform: lowercase;
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin-right: 2%;
    /* identical to box height, or 125% */

    text-align: center;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .features {
    width: 220px;
    height: 24px;
    left: 0px;
    top: 0px;
    margin-top: 20%;
    margin-left: 4%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #000000;
  }
  .mini {
    text-transform: lowercase;
  }
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 5%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Primary dark */

    color: #000000;
  }
  .document1 {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Accent */

    color: #2fc977;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    margin-top: 2%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    color: #2fc977;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: -3px;
    margin-bottom: 10px;
    z-index: 5;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
  }
  .btn {
    box-sizing: border-box;
    border: 1px solid #efefef;

    /* Auto layout */

    padding: 14px 38px 16px;
    gap: 16px;

    width: 269px;
    height: 160px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 6%;
  }
  .btn1 {
    box-sizing: border-box;
    border: 1px solid #efefef;

    /* Auto layout */

    padding: 14px 38px 16px;
    gap: 16px;

    width: 269px;
    height: 160px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 8%;
  }
  .ronde {
    border-radius: 20%;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 20%;

    /* Tetriary Ligjht */

    color: #efefef;
  }
  .search-icon {
    align-self: flex-end;
    margin-left: -18%;
  }
  .feabasic {
    margin-left: 75%;
    margin-top: -5%;
  }
  .feapro {
    margin-left: 90%;
    margin-top: -3%;
  }
  .textfeature {
    left: 0px;
    margin-top: 3%;
    margin-left: 4%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    font-size: 20px;
    line-height: 45px;
    letter-spacing: 0.2px;
    color: #4d4d4d;
    font-stretch: 96;
  }
  .allit {
    margin-left: 80%;
    margin-top: -3%;
  }
  .gotit {
    margin-left: 93%;
    margin-top: -3%;
  }
  .line {
    width: 720px;
    height: 1px;

    /* Tetriary Ligjht */

    background: #efefef;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: 2%;
    margin-top: 4%;
    margin-bottom: 2%;
  }
  .lineview {
    width: 720px;
    height: 1px;
    left: 0px;

    /* Tetriary Ligjht */

    background: #efefef;
  }
  .take1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;

    width: 116px;
    height: 38px;
    margin-top: -3%;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .text1 {
    text-transform: capitalize;
  }
  .basique {
    width: 50px;
    height: 10px;
    background: #a5a5a5;
    margin-right: 35%;
    margin-top: -3%;
  }
  .meilleurChoix {
    width: 120px;
    height: 10px;
    background: #000000;
    margin-right: 50%;
    margin-top: -3%;
  }
  .textbasique {
    font-size: 10px;
    text-transform: capitalize;
  }
  .optionprint1 {
    margin-left: 75%;
    font-size: 14px;
    margin-top: -6%;
  }
  .optionprint2 {
    margin-left: 88%;
    font-size: 14px;
    margin-top: -6%;
    color: #2fc977;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    margin-top: 15%;
    width: 300px;
    height: 52px;
    background: #efefef;
    border-radius: 28px;
    margin-left: 40%;
  }
  .textpl {
    width: 153px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Secondary Gray */

    color: #4d4d4d;

    opacity: 0.35;
    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text5 {
    width: 51px;
    height: 20px;
    text-transform: lowercase;
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-right: 2%;
    /* identical to box height, or 125% */

    text-align: center;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .features {
    width: 220px;
    height: 24px;
    margin-top: 20%;
    margin-left: 4%;
    font-family: "Georama";
    font-style: normal;
    font-weight: bold;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .mini {
    text-transform: lowercase;
  }
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 5%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Primary dark */

    color: #000000;
  }
  .document1 {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Accent */

    color: #2fc977;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    margin-top: 2%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: -3px;
    margin-bottom: 10px;
    z-index: 5;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
  }
  .btn {
    align-content: center;
    box-sizing: border-box;
    border: 1px solid #efefef;

    padding: 12px 32px 16px;
    gap: 16px;
    width: 160px;
    height: 160px;
    background: #ffffff;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn1 {
    align-content: center;
    box-sizing: border-box;
    border: 1px solid #efefef;

    color: #2fc977;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;

    width: 169px;
    padding: 12px 32px 16px;
    gap: 16px;
    width: 160px;
    height: 136px;
    margin-left: 80%;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .ronde {
    border-radius: 20%;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 20%;

    /* Tetriary Ligjht */

    color: #efefef;
  }

  .search-icon {
    align-self: flex-end;
    margin-left: -25%;
  }
  .feabasic {
    width: 46;
    height: 24;
    margin-left: 67%;
    margin-top: -6%;
  }
  .feapro {
    width: 46;
    height: 24;
    margin-left: 85%;
    margin-top: -9%;
  }
  .textfeature {
    width: 218px;
    height: 24px;
    left: 0px;
    top: 0px;
    margin-left: 4%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;
  }
  .allit {
    width: 18px;
    height: 18px;
    margin-left: 105%;
    margin-top: -3%;
  }
  .gotit {
    width: 18px;
    height: 18px;
    margin-left: 120%;
    margin-top: -7%;
  }
  .line {
    width: 350px;
    height: 1px;

    /* Tetriary Ligjht */

    background: #efefef;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin-left: 4%;
  }
  .lineview {
    width: 350px;
    height: 1px;
    left: 0px;

    /* Tetriary Ligjht */

    background: #efefef;
  }
  .take1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    width: 116px;
    height: 38px;
    margin-top: -5%;
    margin-left: 40%;
    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
}
</style>
