<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <!-- product and country -->
        <v-row class="productPanel mx-0" style="margin-bottom: 2%">
          <v-col cols="2" class="d-flex align-center">
            <v-img
              width="24"
              max-width="24"
              height="24"
              style="border-radius: 5px"
              class="vector align-self-auto"
              :src="loadImg(countryCode)"
            ></v-img>
            <span class="ml-2 fontStyle15">{{ pays }}</span>
          </v-col>
          <div class="vertical-hr"></div>
          <v-col cols="4" class="d-flex align-center single-line">
            <v-img
              width="24"
              max-width="24"
              height="24"
              style="border-radius: 5px"
              class="vector align-self-auto"
              :src="loadImg(countryCode)"
            ></v-img>
            <span class="ml-2 fontStyle15">{{ document }}</span>
          </v-col>
        </v-row>
        <!-- end product and country -->

        <v-sheet>
          <v-card
            style="
              background-color: #efefef;
              border-radius: 10px;
              display: flex; /* Ajout de la propriété display: flex */
            "
            class="pa-5"
          >
            <v-row>
              <v-col cols="4">
                <!-- photo -->
                <div style="display: flex; align-items: center">
                  <img
                    class="noir"
                    :src="
                      pricePhoto.purpose.verification_needed == false &&
                      priceOPVerification == null &&
                      priceOPVerification == undefined
                        ? `data:image/png;base64,${photoResized}`
                        : `data:image/png;base64,${photo}`
                    "
                    height="200"
                    object-fit="contain"
                  />
                </div>
                <!-- end photo display -->
              </v-col>
              <v-col cols="1" style="display: flex; align-items: center">
              </v-col>
              <v-col cols="auto" style="display: flex; align-items: left">
                <!-- photoboard -->
                <v-img
                  width="310"
                  height="200"
                  :aspect-ratio="1"
                  :src="
                    boardEphotoDetoured !== null &&
                    boardEphotoDetoured.base64 != null &&
                    pricePrint != null &&
                    !(
                      ephotoBoardCount == null ||
                      ephotoBoardCount == undefined ||
                      ephotoBoardCount == '0'
                    )
                      ? `data:image/png;base64,${boardEphotoDetoured.base64}`
                      : pricePhoto.purpose.verification_needed == false &&
                        priceOPVerification == null &&
                        priceOPVerification == undefined &&
                        pricePrint != null &&
                        !(
                          boardCount == null ||
                          boardCount == undefined ||
                          boardCount == '0'
                        )
                      ? `data:image/png;base64,${
                          boardResized !== null && boardResized.base64 !== null
                            ? boardResized.base64
                            : ''
                        }`
                      : `data:image/png;base64,${
                          boardDetoured !== null &&
                          boardDetoured.base64 !== null
                            ? boardDetoured.base64
                            : ''
                        }`
                  "
                  cover
                ></v-img>
                <!-- end board display -->
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
        <div
          class="d-flex justify-space-around"
          v-if="
            pricePhoto.purpose.verification_needed == false &&
            priceOPVerification == null &&
            priceOPVerification == undefined &&
            pricePrint != null &&
            (boardCount == null || boardCount == undefined || boardCount == '0')
          "
        >
          <div></div>
        </div>
        <div v-else>
          <!-- message alert for wrong promo code  -->
          <v-alert
            v-if="erreurPromo"
            type="error"
            style="background-color: #d70000; color: white"
            class="mt-5"
          >
            {{ $t("Desktop.yourOrderDetailDesktop.codePromo_error", language) }}
          </v-alert>
          <!-- end message alert for wrong promo code  -->

          <!-- promo code form  -->
          <div v-if="promo == null || promo == undefined">
            <h4 style="margin-top: 8%; margin-bottom: 13px" class="fontStyle15">
              {{ $t("Desktop.yourOrderDetailDesktop.codePromo", language) }}
            </h4>
            <v-row>
              <v-sheet style="background: transparent" class="d-flex flex-row">
                <v-text-field
                  v-model="codePromo"
                  class="fontStyle15 ml-2"
                  :placeholder="
                    $t('Desktop.yourOrderDetailDesktop.discount_code', language)
                  "
                  required
                  outlined
                  :color="isClicked ? '#2fc977' : ''"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  style="width: 410px"
                >
                </v-text-field>
                <v-btn
                  class="apply rounded-lg py-6 mt-1 ml-3"
                  style="background-color: #dedede"
                  @click="appliquerCodePromo"
                  >{{
                    $t("Desktop.yourOrderDetailDesktop.apply", language)
                  }}</v-btn
                >
              </v-sheet>
            </v-row>
          </div>
          <!-- end promo code form  -->

          <!-- bill detail -->
          <v-card
            style="border-radius: 10px"
            class="pa-5 mt-7 fontStyle15"
            width="540"
          >
            <v-row v-if="pricePhoto.purpose.verification_needed">
              <v-col>
                <v-sheet style="font-weight: bold" class="">{{
                  $t("Desktop.yourOrderDetailDesktop.verified_photo1", language)
                }}</v-sheet>
              </v-col>
              <v-col class="prix">
                <v-sheet>
                  {{ pricePhoto.amount_in_local_currency }}
                  {{ pricePhoto.local_currency_symbol }}
                </v-sheet>
              </v-col>
            </v-row>
            <v-row
              v-else-if="
                priceOPVerification != null && priceOPVerification != undefined
              "
            >
              <v-col>
                <v-sheet style="font-weight: bold">{{
                  $t("Desktop.yourOrderDetailDesktop.package", language)
                }}</v-sheet>
              </v-col>
              <v-col class="prix">
                <v-sheet>
                  {{ priceOPVerification.amount_in_local_currency }}
                  {{ priceOPVerification.local_currency_symbol }}
                </v-sheet>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>
                <v-sheet style="font-weight: bold">{{
                  $t("Desktop.yourOrderDetailDesktop.basic_package", language)
                }}</v-sheet>
              </v-col>
              <v-col class="prix">
                <v-sheet>
                  {{ pricePhoto.amount_in_local_currency }}
                  {{ pricePhoto.local_currency_symbol }}
                </v-sheet>
              </v-col>
            </v-row>
            <v-row
              v-if="
                pricePrint != null &&
                pricePrint != undefined &&
                ((boardCount != null &&
                  boardCount != undefined &&
                  boardCount != '0') ||
                  (ephotoBoardCount != null &&
                    ephotoBoardCount != undefined &&
                    ephotoBoardCount != '0'))
              "
            >
              <v-col>
                <v-sheet style="font-weight: bold">{{
                  $t("Desktop.yourOrderDetailDesktop.print_summary1", language)
                }}</v-sheet>
              </v-col>
              <v-col class="prix">
                <v-sheet
                  >{{
                    Number(
                      pricePrint !== null && pricePrint !== undefined
                        ? pricePrint.amount_in_local_currency
                        : 0
                    ) *
                    (Number(
                      boardCount != null && boardCount != undefined
                        ? boardCount
                        : 0
                    ) +
                      Number(
                        ephotoBoardCount != null &&
                          ephotoBoardCount != undefined
                          ? ephotoBoardCount
                          : 0
                      ))
                  }}
                  {{ pricePrint.local_currency_symbol }}
                </v-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="promo !== null && promo !== undefined">
                <v-sheet style="font-weight: bold">
                  {{
                    $t("Desktop.yourOrderDetailDesktop.discount_code", language)
                  }}
                </v-sheet>
              </v-col>
              <v-col class="prix" v-if="promo !== null && promo !== undefined">
                <v-sheet>
                  {{
                    promo.amount_in_local_currency > total
                      ? total
                      : promo.amount_in_local_currency
                  }}
                  {{ promo.local_currency_symbol }}
                </v-sheet>
              </v-col>
            </v-row>
            <hr class="mt-5 mb-5 separator" />
            <v-row style="font-weight: bold">
              <v-col>
                <v-sheet>{{
                  $t("Desktop.yourOrderDetailDesktop.total", language)
                }}</v-sheet>
              </v-col>
              <v-col class="prix">
                <v-sheet>
                  {{
                    promo != null && promo != undefined
                      ? promo.amount_in_local_currency >= total
                        ? 0
                        : total - promo.amount_in_local_currency
                      : total
                  }}
                  {{ pricePhoto.local_currency_symbol }}
                </v-sheet>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <!-- end bill detail -->
      </v-col>
      <v-col cols="6">
        <v-card
          v-if="
            pricePhoto.purpose.verification_needed == false &&
            priceOPVerification == null &&
            priceOPVerification == undefined &&
            pricePrint != null &&
            (boardCount == null || boardCount == undefined || boardCount == '0')
          "
          style="background-color: #efefef; border-radius: 10px"
          class="pa-5 ml-10 mt-5"
        >
          <div class="plan fontStyle15">
            <span class="fontStyle22">{{
              $t("Desktop.yourOrderDetailDesktop.title_summary", language)
            }}</span>
            <div class="my-5">
              {{
                $t("Desktop.yourOrderDetailDesktop.sent_to", language).replace(
                  "#email",
                  email
                )
              }}
              <!-- <br /><span>Réception de l'émail dans {{ time }} minutes</span> -->
            </div>
            <div class="list">
              <v-list
                style="background-color: #efefef"
                v-if="pricePhoto.purpose.verification_needed"
              >
                <v-list-item
                  style="min-height: 30px"
                  v-if="pricePhoto.purpose.ephoto_needed"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t("Desktop.yourOrderDetailDesktop.code_ants", language)
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Compliance_guarantee_desktop",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Background_removal",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Photo_check_by_trained_expert",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t("Desktop.yourOrderDetailDesktop.Adjustments", language)
                  }}</span>
                </v-list-item>
              </v-list>
              <v-list
                style="background-color: #efefef"
                v-else-if="
                  priceOPVerification != null &&
                  priceOPVerification != undefined
                "
              >
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Compliance_guarantee_desktop",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Background_removal",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Photo_check_by_trained_expert",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t("Desktop.yourOrderDetailDesktop.Adjustments", language)
                  }}</span>
                </v-list-item>
              </v-list>
              <v-list style="background-color: #efefef" v-else>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#a5a5a5"
                    class="icon-green"
                    >mdi-close-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Compliance_guarantee_desktop",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#a5a5a5"
                    class="icon-green"
                    >mdi-close-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Background_removal",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#a5a5a5"
                    class="icon-green"
                    >mdi-close-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Photo_check_by_trained_expert",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#a5a5a5"
                    class="icon-green"
                    >mdi-close-circle-outline</v-icon
                  ><span class="font">{{
                    $t("Desktop.yourOrderDetailDesktop.Adjustments", language)
                  }}</span>
                </v-list-item>
              </v-list>
            </div>
          </div>
          <div class="container">
            <hr class="separator" />
          </div>

          <section
            v-if="adresse != null && adresse != undefined"
            class="fontStyle15 ml-3"
          >
            <h4 style="margin-top: 3%" class="mb-3">
              {{ $t("Desktop.yourOrderDetailDesktop.printed_to", language) }}
            </h4>

            <p>
              <span>{{ adresse.firstName }} {{ adresse.lastName }}</span
              ><br />
              <span>{{ adresse.address }}, {{ adresse.nomBL }}</span
              ><br />
              <span
                >{{ adresse.postalCode }} {{ adresse.city }},
                {{ adresse.country }}</span
              >
            </p>
          </section>

          <div class="d-flex justify-space-around">
            <v-btn
              v-if="goBack"
              @click="goBackAction"
              style="
                margin-left: 10%;
                border: none;
                background-color: transparent;
                color: black;
              "
              text
            >
              <v-icon>mdi-chevron-left</v-icon>
              <span
                class="d-flex justify-space-around px-3 rounded-pill bottom fontStyle15"
                style="text-decoration: underline"
                >{{ $t("Desktop.yourOrderDetailDesktop.back", language) }}</span
              >
            </v-btn>
            <v-dialog
              v-model="dialogBasic"
              width="auto"
              class="px-3 rounded-pill bottom fontStyle15"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="
                    background-color: #000000;
                    color: white;
                    font-family: Georama;
                    text-transform: none;
                  "
                  class="pa-4 text-center rounded-pill"
                  v-bind="attrs"
                  v-on="on"
                  ><span style="font-weight: 600; font-size: 16px">{{
                    $t("Desktop.yourOrderDetailDesktop.receive_email", language)
                  }}</span>
                </v-btn>
              </template>
              <v-card>
                <v-card-text style="text-align: center" class="fontStyle15">
                  <p style="padding-top: 5%; font-family: Georama">
                    {{ $t("Desktop.yourOrderDetailDesktop.pop_up", language)
                    }}<br />
                    <span
                      ><p>
                        {{
                          $t(
                            "Desktop.yourOrderDetailDesktop.pop_up_sure",
                            language
                          )
                        }}
                      </p></span
                    >
                  </p>
                </v-card-text>
                <v-card-actions style="margin-right: 40%">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    style="
                      background-color: #000000;
                      color: white;
                      font-family: Georama;
                      text-transform: none;
                    "
                    variant="text"
                    @click="redirect"
                    ><span style="font-weight: 600; font-size: 16px">{{
                      $t("Desktop.yourOrderDetailDesktop.pop_up_no", language)
                    }}</span>
                  </v-btn>
                  <v-btn
                    rounded
                    style="
                      background-color: #000000;
                      color: white;
                      margin-left: 5%;
                      font-family: Georama;
                      text-transform: none;
                    "
                    variant="text"
                    @click="generatePaymentIntent"
                    ><span style="font-weight: 600; font-size: 16px">{{
                      $t("Desktop.yourOrderDetailDesktop.pop_up_yes", language)
                    }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card>
        <v-card
          elevation="0"
          v-else
          style="background-color: #efefef; border-radius: 10px"
          class="pa-5 ml-10 mt-5"
        >
          <div class="plan fontStyle15">
            <span class="fontStyle22">{{
              $t("Desktop.yourOrderDetailDesktop.title_summary", language)
            }}</span>
            <div class="my-5">
              {{
                $t("Desktop.yourOrderDetailDesktop.sent_to", language).replace(
                  "#email",
                  email
                )
              }}
              <!-- <br /><span>Réception de l'émail dans {{ time }} minutes</span> -->
            </div>
            <div class="list">
              <v-list
                style="background-color: #efefef"
                v-if="pricePhoto.purpose.verification_needed"
              >
                <v-list-item
                  style="min-height: 30px"
                  v-if="pricePhoto.purpose.ephoto_needed"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t("Desktop.yourOrderDetailDesktop.code_ants", language)
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Compliance_guarantee_desktop",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Background_removal",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Photo_check_by_trained_expert",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t("Desktop.yourOrderDetailDesktop.Adjustments", language)
                  }}</span>
                </v-list-item>
              </v-list>
              <v-list
                style="background-color: #efefef"
                v-else-if="
                  priceOPVerification != null &&
                  priceOPVerification != undefined
                "
              >
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Compliance_guarantee_desktop",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Background_removal",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Photo_check_by_trained_expert",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="font">{{
                    $t("Desktop.yourOrderDetailDesktop.Adjustments", language)
                  }}</span>
                </v-list-item>
              </v-list>
              <v-list style="background-color: #efefef" v-else>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#a5a5a5"
                    class="icon-green"
                    >mdi-close-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Compliance_guarantee_desktop",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#a5a5a5"
                    class="icon-green"
                    >mdi-close-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Background_removal",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#a5a5a5"
                    class="icon-green"
                    >mdi-close-circle-outline</v-icon
                  ><span class="font">{{
                    $t(
                      "Desktop.yourOrderDetailDesktop.Photo_check_by_trained_expert",
                      language
                    )
                  }}</span>
                </v-list-item>
                <v-list-item style="min-height: 30px"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#a5a5a5"
                    class="icon-green"
                    >mdi-close-circle-outline</v-icon
                  ><span class="font">{{
                    $t("Desktop.yourOrderDetailDesktop.Adjustments", language)
                  }}</span>
                </v-list-item>
              </v-list>
            </div>
          </div>
          <div class="container">
            <hr class="separator" />
          </div>

          <section
            v-if="adresse != null && adresse != undefined"
            class="fontStyle15 ml-3"
          >
            <h4 style="margin-top: 3%" class="mb-3">
              {{ $t("Desktop.yourOrderDetailDesktop.printed_to", language) }}
            </h4>

            <p>
              <span>{{ adresse.firstName }} {{ adresse.lastName }}</span
              ><br />
              <span>{{ adresse.address }}, {{ adresse.nomBL }}</span
              ><br />
              <span
                >{{ adresse.postalCode }} {{ adresse.city }},
                {{ adresse.country }}</span
              >
            </p>
          </section>
          <div class="d-flex justify-space-around">
            <v-btn
              v-if="goBack"
              @click="goBackAction"
              style="
                margin-left: 30%;
                border: none;
                background-color: transparent;
                color: black;
              "
              text
            >
              <v-icon>mdi-chevron-left</v-icon>
              <span
                class="px-3 rounded-pill bottom fontStyle15"
                style="text-decoration: underline"
                >{{ $t("Desktop.yourOrderDetailDesktop.back", language) }}</span
              >
            </v-btn>
            <v-btn
              style="background-color: black; color: white; font-weight: bold"
              class="px-3 rounded-pill bottom fontStyle15"
              @click="generatePaymentIntent"
              >{{
                $t("Desktop.yourOrderDetailDesktop.finalise_order", language)
              }}</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="auto">
          <v-card width="500">
            <div style="text-align: right">
              <v-icon right size="large" @click="dialog = false"
                >mdi-close-circle</v-icon
              >
            </div>
            <v-col>
              <v-card class="" elevation="0">
                <stripe-element-payment
                  ref="paymentRef"
                  :pk="pk"
                  :elements-options="elementsOptions"
                  :confirm-params="confirmParams"
                  :locale="language"
                />
                <v-divider
                  inset
                  color="#212121"
                  style="margin: 20px"
                ></v-divider>
                <v-container
                  class="bg-surface-variant"
                  style="margin-top: 20px"
                >
                  <v-row>
                    <v-col class="">
                      <v-sheet class="d-flex flex-row align-center justify-end">
                        <h4>
                          {{ $t("yourOrderDetailMobile.total", language) }} :
                          {{
                            promo != null && promo != undefined
                              ? promo.amount_in_local_currency >= total
                                ? 0
                                : total - promo.amount_in_local_currency
                              : total
                          }}
                          {{ pricePhoto.local_currency_symbol }}
                        </h4>
                        <v-btn
                          @click="pay"
                          rounded
                          class="ml-10"
                          style="
                            background-color: #000000;
                            color: white;
                            font-family: Georama;
                            text-transform: none;
                          "
                          variant="text"
                        >
                          {{
                            $t(
                              "Desktop.yourOrderDetailDesktop.finalise_order",
                              language
                            )
                          }}
                        </v-btn>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-container>
</template>
<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
//import { isMobile } from "mobile-device-detect";
export default {
  name: "RecapOrder",
  components: {
    StripeElementPayment,
  },
  data: () => ({
    selectedOffers: [],
    shipmentPanel: [],
    verificationPanel: [],
    processingPanel: {},
    totalAmount: 0.0,
    currency: "EUR",
    signatureUid: null,
    priceId: null,
    board: null,
    photoUrl: null,
    pricePhoto: null,
    errors: [],
    email: "",
    confEmail: "",
    check: "",
    purpose: null,
    countryCode: localStorageService
      .getPrice()
      .use_in_country.country_code.toLowerCase(),
    allowNavigation: false,
    isClicked: false,
    pays: localStorage.getItem("selectedCountry"),
    document: localStorage.getItem("selectedProduct"),
    images: [],
    countryFlag: "",
    boardDetoured: {},
    priceOPVerification: null,
    boardEphotoDetoured: {},
    boardResized: {},
    boardEphotoResized: {},
    photoUid: null,
    photoUidResized: null,
    photoResized: null,
    photo: null,
    imageWidth: "35mm",
    imageHeight: "45mm",
    ex4: true,
    goBack: true,
    language: "",
    adresse: "",
    codePromo: "",
    erreurPromo: false,
    promo: null,
    pricePrint: {},
    boardCount: 0,
    ephotoBoardCount: 0,
    time: null,
    dialog: false,
    pk: process.env.VUE_APP_ENV_STRIPE_PUB_KEY,
    stripeClientSecret: null,
    elementsOptions: {
      appearance: {}, // appearance options
    },
    confirmParams: {
      return_url: process.env.VUE_APP_URL + "/mobile/winner", // success url
    },
  }),
  methods: {
    redirect() {
      this.$router.push({
        name: "ChoosePlanDesk",
      });
    },
    goBackAction() {
      this.$router.back();
      document.activeElement.blur();
    },
    loadImg(img) {
      return require(`../../assets/images/Drapeaux/${img}.jpg`);
    },
    handleFocus() {
      this.isClicked = true;
    },
    handleBlur() {
      this.isClicked = false;
    },
    async loadCountries(countryCode) {
      var language = this.language;
      requestService
        .get(`/country/customized?country_code=${countryCode}`, null, null, {
          language: language,
        })
        .then((data) => {
          this.countries = data.data.Countries.filter(
            (element) =>
              element.country_merci_facteur != null &&
              element.country_merci_facteur != "" &&
              element.send_authorized == true
          );
        });
    },
    appliquerCodePromo() {
      const countryCode = localStorageService.getCountryCode();
      requestService
        .get(
          `/promotion/check/${this.codePromo}/for/${countryCode}`,
          null,
          null
        )
        .then((data) => {
          if (this.codePromo === data.data.code) {
            this.promo = {
              amount_in_local_currency: data.data.amount_in_local_currency,
              local_currency_symbol: data.data.local_currency_symbol,
              data: data.data,
            };
            localStorageService.setPromo(this.promo);
          }
        })
        .catch(() => {
          this.erreurPromo = true;
          console.error(`Le code promo est invalide`);
        });
    },
    async generatePaymentIntent() {
      this.dialogBasic = false;
      this.progress = true;
      var _this = this;
      this.stripeClientSecret = localStorageService.getStripeClientSecret();
      if (
        this.stripeClientSecret == null ||
        this.stripeClientSecret == undefined ||
        this.stripeClientSecret == "null"
      ) {
        var photoUid = localStorageService.getPhotoUid();
        var signatureUid = localStorageService.getSignatureUid();
        var address = localStorageService.getAdresse();
        var orderItems = [
          {
            quantity: 1,
            document: {
              photo: photoUid == null ? undefined : photoUid,
              signature: signatureUid == null ? undefined : signatureUid,

              firstName: address == null ? null : address.firstName,
              lastName: address == null ? null : address.lastName,
              address: address == null ? null : address.address,
              postalCode: address == null ? null : address.postalCode,
              city: address == null ? null : address.city,
              country: address == null ? null : address.country,
            },
            priceId: this.pricePhoto.id,
          },
        ];
        if (
          this.priceOPVerification !== null &&
          this.priceOPVerification !== undefined
        ) {
          orderItems.push({
            quantity: 1,
            priceId: this.priceOPVerification.id,
          });
        }
        if (
          this.pricePrint !== null &&
          this.pricePrint !== undefined &&
          this.boardCount !== 0 &&
          this.boardCount > 0
        ) {
          orderItems.push({
            quantity: this.boardCount,
            priceId: this.pricePrint.id,
          });
        }
        if (
          this.pricePrint !== null &&
          this.pricePrint !== undefined &&
          this.ephotoBoardCount !== 0 &&
          this.ephotoBoardCount > 0
        ) {
          orderItems.push({
            quantity: this.ephotoBoardCount,
            priceId: this.pricePrint.id,
          });
        }

        const orderFormData = JSON.stringify({
          emailOwner: this.email,
          orderItems: orderItems,
          promotion:
            this.promo !== null && this.promo !== "null"
              ? this.promo.data.code
              : null,
        });
        requestService
          .post("order/anonymous", orderFormData, null, {
            "content-type": "application/json",
          })
          .then((data) => {
            localStorageService.setStripeClientSecret(
              data.data.stripe_client_secret
            );

            _this.stripeClientSecret = data.data.stripe_client_secret;
            _this.elementsOptions.clientSecret = _this.stripeClientSecret;

            if (data.data.stripe_client_secret === null) {
              _this.dialog = false;
              this.$router.push({
                name: "BravoAppDesk",
              });
            } else {
              _this.dialog = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.elementsOptions.clientSecret = this.stripeClientSecret;
        this.dialog = true;
      } //paymentIntent.client_secret;
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
    next() {
      if (
        this.elementsOptions.clientSecret !== null &&
        this.elementsOptions.clientSecret !== undefined
      ) {
        this.dialog = true;
      }
    },
    load() {
      this.email = localStorageService.getEmailOwner();
      this.adresse = localStorageService.getAdresse();
      this.codePromo = "";
      //this.countryCode = localStorageService.getCountryCode().toLowerCase();
      this.countryFlag = "";
      this.boardDetoured = localStorageService.getPhotoBoardDetoured();
      this.boardEphotoDetoured =
        localStorageService.getPhotoBoardDetouredEphoto();
      this.boardResized = localStorageService.getPhotoBoardResized();
      this.boardEphotoResized =
        localStorageService.getPhotoBoardResizedEphoto();
      this.priceOPVerification =
        localStorageService.getPriceOPOfferVerification();
      this.pricePhoto = localStorageService.getPrice();
      this.countryCode =
        this.pricePhoto.use_in_country.country_code.toLowerCase();
      this.selectedProduct = this.pricePhoto.purpose.label;
      this.selectedCounty = this.pricePhoto.use_in_country.country_name;

      this.boardCount = localStorageService.getBoardPrintCount();
      this.ephotoBoardCount = localStorageService.getBoardEphotoPrintCount();

      this.dialog = false;
      this.pk = process.env.VUE_APP_ENV_STRIPE_PUB_KEY;
      this.stripeClientSecret = null;
      this.elementsOptions = {
        appearance: {}, // appearance options
      };
      this.confirmParams = {
        return_url: process.env.VUE_APP_RETURN_URL_STRIPE, // success url
      };
      this.pricePrint = localStorageService.getPriceOPShipment();
      this.count = localStorage.getItem("count");

      this.photo = localStorageService.getPhotoBase64Detoured();
      this.photoResized = localStorageService.getPhotoBase64Resize();
      this.promo = localStorageService.getPromo();

      //this.generatePaymentIntent();
      this.countryFlag =
        "../../assets/images/Drapeaux/" + this.countryCode + ".jpg";
      this.language = localStorageService.getLanguage();
      this.images = require.context(
        "../../assets/images/Drapeaux/",
        false,
        /\.jpg$/
      );
      requestService.get(`/time-photo-treatment/found/time`).then((data) => {
        this.time = data.data;
        //console.log(this.time);
      });
    },
  },
  computed: {
    total() {
      return (
        Number(this.pricePhoto.amount_in_local_currency) +
        Number(
          this.pricePrint != null && this.pricePrint != undefined
            ? this.pricePrint.amount_in_local_currency
            : 0
        ) *
          (Number(
            this.boardCount != null && this.boardCount != undefined
              ? this.boardCount
              : 0
          ) +
            Number(
              this.ephotoBoardCount != null &&
                this.ephotoBoardCount != undefined
                ? this.ephotoBoardCount
                : 0
            )) +
        Number(
          this.priceOPVerification != null &&
            this.priceOPVerification != undefined
            ? this.priceOPVerification.amount_in_local_currency
            : 0
        )
      ).toFixed(2);
    },
  },
  mounted() {
    this.loadCountries();
    this.load();
    //this.language = localStorageService.getLanguage();
    this.adresse = localStorageService.getAdresse();
    //alert(localStorageService.getPriceOPShipment());
    localStorageService.setStripeClientSecret(null);
  },
};
</script>
<style scoped>
.vertical-hr {
  border: none;
  border-left: 1px solid #efefef;
  height: 50px; /* Définissez la hauteur souhaitée */
  margin: 0 70px; /* Ajoutez des marges si nécessaire */
}
.plus {
  font-weight: bold;
  margin-left: 10%;
  font-size: 24px;
}
.apply {
  text-transform: none;
  background-color: #dedede;
}
.prix {
  text-align: right;
}
.icon {
  margin-right: 10px;
  color: #000000;
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
  font-size: 15px;
  font-family: Georama;
  font-weight: bold;
}
.plan {
  margin-left: 1%;
}
.single-line {
  white-space: nowrap;
}
.list {
  margin-left: -20px;
}

.bottom {
  text-transform: none;
}
.search-input-container1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.textarea {
  border: 1px solid black;
  border-radius: 5px;
  width: 70%;
  padding: 2%;
}
.textarea1 {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  margin-top: 2%;
  width: 94%;
  margin-left: 0.5%;
}
.fontStyle15 {
  font-size: 17px;
  line-height: 19.91px;
  font-weight: 400;
  font-family: Georama;
}
.container {
  position: relative;
  font-family: Georama;
}
.separator {
  border: none;
  height: 1px;
  background-color: black;
}
.custom-button {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  background-color: grey;
  text-transform: none;
}
.productPanel {
  border: 1px solid #efefef;
  background: white;
  border-radius: 5px;
  padding: 5px;
  margin-top: 2%;
  width: 97%;
}
.fontStyle22 {
  font-size: 22px;
  line-height: 26.27px;
  font-weight: 600;
  font-family: Georama;
}
.fontStyle13 {
  font-size: 15px;
  line-height: 15.52px;
  font-weight: 400;
  font-family: Georama;
  color: #4c4c4c;
  margin-bottom: 20px;
}
.icon-green {
  margin-right: 10px;
  margin-left: 2px;
  border-radius: 50%;
}
</style>
