<template>
  <component
    :is="layout"
    :style="
      $route.meta.layout === 'desktop'
        ? $route.name === 'BravoAppDesk'
          ? 'background-image: url(\'https://storage.googleapis.com/smid-static/bg_bravo.png\'); background-size: cover;'
          : 'background-image: url(\'https://storage.googleapis.com/smid-static/bg_grey_2.png\'); background-size: cover;'
        : ''
    "
  >
    <v-main style="position: absolute; width: 100%; height: 100%">
      <div>
        <keep-alive>
          <router-view :key="$route.path" />
        </keep-alive>
      </div>
      <v-snackbar
        :value="alert.active"
        @input="$store.dispatch('alert/updateActive', $event)"
        :color="alert.type"
      >
        {{ alert.message }}
        <v-btn dark text @click="$store.dispatch('alert/updateActive', false)"
          >OK
        </v-btn>
      </v-snackbar>
    </v-main>
  </component>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";

const defaultLayout = "content-only";

export default Vue.extend({
  name: "App",
  data: () => ({
    //
  }),
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
    ...mapState(["account", "alert"]),
    alert() {
      return this.$store.state.alert;
    },
  },
  /*created() {
    this.unwatchUser = this.$store.watch(
      (state) => {
        return state.account.status.loggedIn;
      },
      (newValue) => {
        if (!newValue) {
          this.logout();
        }
      }
    );
  },*/
  methods: {
    ...mapActions({
      logout: "account/logout",
      clearAlert: "alert/clear",
    }),
  },
  beforeDestroy() {
    this.unwatchUser();
  },
});
</script>

<style lang="scss">
.view-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 500px;
  background-color: transparent;
}
@media (min-width: 500px) {
  .view-container {
    border: 1px solid #ccc;
    margin: 2rem 0.5rem;
    background-color: transparent;
  }
}
</style>
