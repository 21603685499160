<template>
  <v-container>
    <div>
      <h2>Page not found</h2>
      <i class="fas fa-exclamation-triangle red--text"></i>
      <p>
        We're sorry, the page you requested could not be found. Please go back
        to the homepage or contact us at admin@bibekshakya.com
      </p>
      <h1>404</h1>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",

  data: () => ({}),
};
</script>
