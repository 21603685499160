<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-sheet style="background: transparent">
          <v-container>
            <h4
              style="font-weight: 600; font-size: 22px"
              v-if="
                boardDetoured !== null &&
                boardDetoured.base64 != null &&
                boardEphotoDetoured !== null &&
                boardEphotoDetoured.base64 != null
              "
            >
              {{ $t("Desktop.printedCopyDesktop.which_template", language) }}
            </h4>
            <v-row>
              <v-card
                v-if="boardDetoured !== null && boardDetoured.base64 != null"
                class="pa-5 planche"
                style="
                  border-radius: 10px;
                  width: 90%;
                  border: 1px solid #2fc977;
                "
              >
                <v-img
                  width="310"
                  height="200"
                  :aspect-ratio="1"
                  :src="
                    pricePhoto.purpose.verification_needed == false &&
                    priceOPVerification == null &&
                    priceOPVerification == undefined
                      ? `data:image/png;base64,${boardResized.base64}`
                      : `data:image/png;base64,${boardDetoured.base64}`
                  "
                />
                {{ boardDetoured.messagePhotoBoard }}
                <div class="all">
                  <v-btn-toggle
                    v-model="toggle_none"
                    variant="outlined"
                    divided
                    class="number"
                  >
                    <v-btn
                      @click="decrementBoardCount"
                      :disabled="boardCount === 0"
                    >
                      <div>
                        <v-img
                          width="10"
                          height="2"
                          src="../../assets/images/Rectangle 97.png"
                        ></v-img>
                      </div>
                    </v-btn>

                    <v-btn>
                      <span>{{ boardCount }}</span>
                    </v-btn>

                    <v-btn
                      @click="incrementBoardCount"
                      :disabled="boardCount === 1"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-card>
            </v-row>
            <v-row>
              <v-card
                v-if="
                  boardEphotoDetoured !== null &&
                  boardEphotoDetoured.base64 != null
                "
                class="pa-5 planche"
                style="
                  border-radius: 10px;
                  width: 90%;
                  border: 1px solid #2fc977;
                "
              >
                <v-img
                  width="310"
                  height="200"
                  :aspect-ratio="1"
                  :src="`data:image/png;base64,${boardEphotoDetoured.base64}`"
                />
                {{ boardEphotoDetoured.messagePhotoBoard }}
                <div class="all">
                  <v-btn-toggle
                    v-model="toggle_exclusive"
                    variant="outlined"
                    divided
                    class="number"
                  >
                    <v-btn
                      @click="decrementEphotoBoardCount"
                      :disabled="ephotoBoardCount === 0"
                    >
                      <div>
                        <v-img
                          width="10"
                          height="2"
                          src="../../assets/images/Rectangle 97.png"
                        ></v-img>
                      </div>
                    </v-btn>

                    <v-btn>
                      <span>{{ ephotoBoardCount }}</span>
                    </v-btn>

                    <v-btn
                      @click="incrementEphotoBoardCount"
                      :disabled="ephotoBoardCount === 1"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </v-card>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col cols="5">
        <v-sheet>
          <v-container>
            <v-alert
              v-if="errors.length"
              style="background-color: #d70000; color: white; margin-top: 5%"
            >
              <ul style="display: flex; align-items: center">
                <li
                  v-for="error in errors"
                  :key="error.id"
                  style="display: flex; align-items: center"
                >
                  <v-img
                    src="../../assets/images/exclamation-circle-Bold1.png"
                    max-width="20"
                    max-height="20"
                    style="margin-right: 15px"
                  ></v-img>
                  <span>{{ error }}</span>
                </li>
              </ul>
            </v-alert>
            <h3 style="margin-bottom: 5%; font-weight: 600; font-size: 22px">
              {{ $t("Desktop.printedCopyDesktop.title_shipping", language) }}
            </h3>
            <v-row>
              <v-col>
                <p class="r5">
                  {{ $t("Desktop.printedCopyDesktop.firstname", language) }}*
                </p>
                <v-text-field
                  v-model="prenom"
                  required
                  placeholder="Mariem"
                  outlined
                  :color="isClicked ? '#2fc977' : ''"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  class="r7"
                ></v-text-field>
              </v-col>
              <v-col>
                <p class="r5">
                  {{ $t("Desktop.printedCopyDesktop.lastname", language) }}*
                </p>
                <v-text-field
                  v-model="nom"
                  placeholder="Smith"
                  required
                  outlined
                  :color="isClicked ? '#2fc977' : ''"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  class="r7"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p>{{ $t("Desktop.printedCopyDesktop.boite", language) }}</p>
                <v-text-field
                  v-model="nomBL"
                  placeholder="Dorian Jones"
                  outlined
                  :color="isClicked ? '#2fc977' : ''"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  class="r7"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="r5">
                  {{ $t("Desktop.printedCopyDesktop.address", language) }}*
                </p>
                <v-text-field
                  v-model="adresse"
                  placeholder="13 Willow street"
                  required
                  outlined
                  :color="isClicked ? '#2fc977' : ''"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  class="r7"
                ></v-text-field>
              </v-col>
              <v-col>
                <p class="r5">
                  {{ $t("Desktop.printedCopyDesktop.addressCompl", language) }}
                </p>
                <v-text-field
                  v-model="complementAdresse"
                  placeholder="building A, apartment 26"
                  outlined
                  :color="isClicked ? '#2fc977' : ''"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  style="font-style: italic; font-size: 15; font-weight: 400"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="r5">
                  {{ $t("Desktop.printedCopyDesktop.ville", language) }}*
                </p>
                <v-text-field
                  v-model="ville"
                  placeholder="Ashburn"
                  required
                  outlined
                  :color="isClicked ? '#2fc977' : ''"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  class="r7"
                ></v-text-field>
              </v-col>
              <v-col>
                <p class="r5">
                  {{ $t("Desktop.printedCopyDesktop.code_postal", language) }}*
                </p>
                <v-text-field
                  v-model="codePostal"
                  placeholder="VA 20147"
                  required
                  outlined
                  :color="isClicked ? '#2fc977' : ''"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  class="r7"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="pays != 'France'">
                <p class="r5">
                  {{ $t("Desktop.printedCopyDesktop.region", language) }}
                </p>
                <v-text-field
                  v-model="region"
                  placeholder="Virginia"
                  outlined
                  :color="isClicked ? '#2fc977' : ''"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  style="font-style: italic; font-size: 15; font-weight: 400"
                ></v-text-field>
              </v-col>
              <v-col>
                <p class="r5">
                  {{ $t("Desktop.printedCopyDesktop.country", language) }}*
                </p>
                <div class="search-input-container">
                  <v-select
                    :items="countries"
                    item-text="country_name"
                    item-value="country_merci_facteur"
                    class="search-input r7"
                    type="text"
                    persistent-hint
                    v-model="pays"
                    :placeholder="`${pays.country_name}`"
                    outlined
                    :color="isClicked ? '#2fc977' : ''"
                    @focus="handleFocus"
                    @blur="handleBlur"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
            <h6 style="margin-left: 3%; font-weight: 400; font-size: 17px">
              {{ $t("Desktop.printedCopyDesktop.info", language) }}
            </h6>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col cols="3">
        <v-sheet>
          <v-container>
            <v-card
              style="background-color: #efefef; border-radius: 10px"
              class="pa-5"
            >
              <div class="plan">
                <h4 style="font-weight: 600; font-size: 22px">
                  {{ $t("Desktop.printedCopyDesktop.title_summary", language) }}
                </h4>
                <div class="list">
                  <v-list style="background-color: #efefef">
                    <v-list-item
                      style="min-height: 30px"
                      v-if="
                        pricePhoto.purpose.verification_needed == true ||
                        (priceOPVerification != null &&
                          priceOPVerification != undefined)
                      "
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font r7">{{
                        $t(
                          "Desktop.printedCopyDesktop.verified_photo",
                          language
                        )
                      }}</span>
                    </v-list-item>
                    <v-list-item
                      style="min-height: 30px"
                      v-if="
                        !(
                          pricePhoto.purpose.verification_needed == true ||
                          (priceOPVerification != null &&
                            priceOPVerification != undefined)
                        )
                      "
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font r7">{{
                        $t(
                          "Desktop.printedCopyDesktop.unverified_photo",
                          language
                        )
                      }}</span>
                    </v-list-item>
                    <v-list-item style="min-height: 30px"
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font r7">{{
                        $t(
                          "Desktop.printedCopyDesktop.summary_planche",
                          language
                        )
                      }}</span>
                    </v-list-item>
                    <v-list-item
                      style="min-height: 30px"
                      v-if="
                        boardEphotoDetoured != null &&
                        boardEphotoDetoured != undefined &&
                        boardEphotoDetoured.base64 != null &&
                        boardEphotoDetoured.base64 != undefined
                      "
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font r7">{{
                        pricePhoto.use_in_country.country_code == "FR"
                          ? $t(
                              "Desktop.printedCopyDesktop.ephoto_code",
                              language
                            )
                          : $t("Desktop.printedCopyDesktop.uk_code", language)
                      }}</span>
                    </v-list-item>
                    <v-list-item
                      style="min-height: 30px"
                      v-if="
                        (pricePrint != null &&
                          pricePrint != undefined &&
                          boardCount != null &&
                          boardCount != undefined &&
                          boardCount != '0') ||
                        (ephotoBoardCount != null &&
                          ephotoBoardCount != undefined &&
                          ephotoBoardCount != '0')
                      "
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font r7">{{
                        $t("Desktop.printedCopyDesktop.print_summary", language)
                      }}</span>
                    </v-list-item>
                  </v-list>
                  <v-btn
                    style="
                      background-color: black;
                      color: white;
                      font-weight: bold;
                      margin-left: 10%;
                      margin-top: 7%;
                      margin-bottom: 3%;
                      text-transform: none;
                    "
                    align-center
                    class="px-5 rounded-pill bottom"
                    @click="checkForm"
                    ><span style="font-weight: 600; font-size: 16px">{{
                      $t("Desktop.printedCopyDesktop.validate_order", language)
                    }}</span></v-btn
                  >
                  <v-btn
                    v-if="goBack"
                    @click="goBackAction"
                    style="
                      margin-right: 60%;
                      border: none;
                      background-color: transparent;
                      color: black;
                    "
                    text
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                    <span
                      class="rounded-pill bottom"
                      style="
                        text-decoration: underline;
                        font-size: 17px;
                        font-weight: 600;
                      "
                      >{{
                        $t("Desktop.printedCopyDesktop.back", language)
                      }}</span
                    >
                  </v-btn>
                </div>
              </div>
            </v-card>
          </v-container>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "AddressLivraison",
  data: () => ({
    errors: [],
    boardDetoured: {},
    boardResized: {},
    boardEphotoDetoured: {},
    countries: [],
    pays: null,
    nom: "",
    prenom: "",
    adresse: "",
    codePostal: "",
    complementAdresse: "",
    nomBL: "",
    ville: "",
    region: "",
    email: localStorageService.getEmailOwner(),
    boardCount: 0,
    ephotoBoardCount: 0,
    priceOPVerification: {},
    pricePhoto: {},
    pricePrint: null,
    goBack: true,
  }),
  methods: {
    goBackAction() {
      this.$router.back();
      document.activeElement.blur();
    },
    handleFocus() {
      this.isClicked = true;
    },
    handleBlur() {
      this.isClicked = false;
    },
    checkForm(e) {
      this.errors = [];
      if (
        !this.nom ||
        !this.prenom ||
        !this.adresse ||
        !this.ville ||
        !this.codePostal ||
        !this.pays
      ) {
        this.errors.push(
          this.$t("AddressLivraison.address_error", this.language)
        );
        return;
      } else {
        this.next();
      }
      e.preventDefault();
    },
    incrementBoardCount() {
      this.boardCount++;
      this.decrementEphotoBoardCount();
      this.incrementBoardCountExecuted = true;
    },
    decrementBoardCount() {
      if (this.boardCount > 0) {
        this.boardCount--;
      }
    },
    incrementEphotoBoardCount() {
      this.ephotoBoardCount++;
      this.decrementBoardCount();
      this.incrementEphotoBoardCountExecuted = true;
    },
    decrementEphotoBoardCount() {
      if (this.ephotoBoardCount > 0) {
        this.ephotoBoardCount--;
      }
    },
    next() {
      localStorageService.setBoardPrintCount(this.boardCount);
      localStorageService.setBoardEphotoPrintCount(this.ephotoBoardCount);
      localStorageService.setPriceOPShipment(this.pricePrint);
      localStorageService.setAdresse({
        firstName: this.nom,
        lastName: this.prenom,
        address: this.adresse,
        postalCode: this.codePostal,
        complementAdresse: this.complementAdresse,
        nomBL: this.nomBL,
        city: this.ville,
        region: this.region,
        //doit renvoyer this.country_merci_facteur du flux customized
        country: this.pays,
      });
      this.$router.push({
        name: "RecapOrder",
      });
    },
    async loadCountries() {
      var countryCode = localStorageService.getCountryCode();
      var language = this.language;
      requestService
        .get(`/country/customized?country_code=${countryCode}`, null, null, {
          language: language,
        })
        .then((data) => {
          this.countries = data.data.Countries.filter(
            (element) =>
              element.country_merci_facteur != null &&
              element.country_merci_facteur != "" &&
              element.send_authorized == true
          );
          var defaultCountry =
            this.pays !== undefined && this.pays !== null
              ? this.pays.country_code
              : localStorageService.getCountryCode();
          var selectedCountries = data.data.Countries.filter(
            (element) =>
              element.country_merci_facteur !== null &&
              element.country_merci_facteur !== "" &&
              element.send_authorized === true &&
              element.country_code === defaultCountry
          );
          if (selectedCountries.length > 0) {
            this.pays = selectedCountries[0];
          }
        });
    },
    load() {
      this.boardDetoured = localStorageService.getPhotoBoardDetoured();
      this.boardEphotoDetoured =
        localStorageService.getPhotoBoardDetouredEphoto();
      this.boardResized = localStorageService.getPhotoBoardResized();
      this.boardEphotoResized =
        localStorageService.getPhotoBoardResizedEphoto();
      this.photoUid = null;
      this.photoUidResized = null;
      this.signatureUid = null;

      this.boardCount = localStorageService.getBoardPrintCount();
      this.ephotoBoardCount = localStorageService.getBoardEphotoPrintCount();
      this.boardCount =
        this.boardCount === null || this.boardCount === undefined
          ? 1
          : Number(this.boardCount);
      this.ephotoBoardCount =
        this.ephotoBoardCount === null || this.ephotoBoardCount === undefined
          ? this.boardCount === 1
            ? 0
            : 1
          : Number(this.ephotoBoardCount);
      this.priceOPVerification =
        localStorageService.getPriceOPOfferVerification();
      this.pricePhoto = localStorageService.getPrice();
      this.pricePrint = localStorageService
        .getPrice()
        .optional_offers.find((x) => x.product.send_by_post_needed);
    },
  },
  mounted() {
    var address = localStorageService.getAdresse();
    if (address !== null && address !== undefined) {
      this.nom = address.firstName;
      this.prenom = address.lastName;
      this.adresse = address.address;
      this.codePostal = address.postalCode;
      this.complementAdresse = address.complementAdresse;
      this.nomBL = address.nomBL;
      this.ville = address.city;
      this.region = address.region;
      this.pays = address.country;
    }
    this.language = localStorageService.getLanguage();
    this.price = localStorageService
      .getPrice()
      .optional_offers.find((x) => x.product.send_by_post_needed);

    localStorageService.setStripeClientSecret(null);
    var price = localStorageService.getPrice();
    this.language = localStorageService.getLanguage();

    var countryCode = localStorageService.getCountryCode();
    if (
      price === null ||
      price === undefined ||
      countryCode === null ||
      countryCode === undefined
    ) {
      alert("toto");
      this.$router.push({ name: "welcome" });
      return;
    }
    var photoUidDetoured = localStorageService.getPhotoUidDetoured();
    if (photoUidDetoured === null || photoUidDetoured === undefined) {
      this.$router.push({
        name: "TakePhoto",
        params: { country: localStorageService.getCountryCode() },
      });
      return;
    }

    var email = localStorageService.getEmailOwner();
    if (email === null || email === undefined) {
      this.$router.push({
        name: "TraitePhoto",
        params: { country: localStorageService.getCountryCode() },
      });
      return;
    }
    this.load();
    this.loadCountries();
  },
};
</script>
<style>
.bottom {
  text-transform: none;
}
.container {
  font-family: Georama;
  padding-bottom: 5px;
}
.list {
  margin-left: -18px;
}
.r5 {
  font-weight: 400;
  font-size: 15px;
}
.r7 {
  font-weight: 400;
  font-size: 17px;
}
.planche {
  margin-top: 15%;
}
.all {
  margin-top: -9%;
  background: #ffffff;
}
.number {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 26px;
  margin-left: 50%;
  margin-top: 10%;
}
</style>
