<template>
  <v-container class="home">
    <div class="image">
      <div class="ma-4">
        <v-img src="../../assets/images/home-removebg-preview.png"></v-img>
      </div>
      <br />
    </div>
    <div class="list">
      <div>
        <v-list>
          <v-list-item
            ><v-icon style="margin-right: 10px; color: springgreen"
              >mdi-check-circle-outline</v-icon
            ><span class="font"> Photo acceptance guaranteed</span>
          </v-list-item>
          <v-list-item
            ><v-icon style="margin-right: 10px; color: springgreen"
              >mdi-check-circle-outline</v-icon
            ><span class="font">Fast processing & delivery</span>
          </v-list-item>
          <v-list-item
            ><v-icon style="margin-right: 10px; color: springgreen"
              >mdi-check-circle-outline</v-icon
            ><span class="font">Everything is done for you </span>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <div class="take">
      <div
        class="d-flex justify-space-around align-center flex-column flex-sm-row"
      >
        <v-btn @click="next" rounded color="black" dark>
          <span class="text">Take <span class="mini">A</span> Photo</span>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "homeApp",
  methods: {
    next() {
      this.$router.push({
        name: "chooseCountry",
      });
    },
  },
};
</script>
<style scoped>
/* Styles pour les écrans de petite taille */
@media only screen and (max-width: 700px) {
  .home {
    justify-content: center;
    align-items: center;
    margin-top: 20%;
  }
  .take {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30%;
    color: black;
  }
  .list {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 50%;
  }
  .text {
    text-transform: capitalize;
  }
  .font {
    font-family: Georama;
    font-style: normal;
    font-weight: 350;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #4d4d4d;
    font-stretch: 96;
  }
  .mini {
    text-transform: lowercase;
  }
}

/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .home {
    justify-content: center;
    align-items: center;
  }
  .take {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
    color: black;
  }
  .list {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image {
    margin: auto;
    width: 60%;
  }
  .text {
    text-transform: capitalize;
    font-size: 20px;
  }
  .font {
    font-family: Georama;
    font-style: normal;
    font-weight: 350;
    font-size: 25px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #4d4d4d;
    font-stretch: 96;
  }
  .mini {
    text-transform: lowercase;
  }
}
</style>
