<template>
  <div>
    <v-container>
      <V-container class="piedss">
        <h3 align="center">
          {{ $t("Desktop.ReturnToDesktop.return_to_desktop", language) }}
        </h3>
      </V-container>
    </v-container>
    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <!-- <v-btn
      style="
        background-color: black;
        color: white;
        font-weight: bold;
      "
      class="px-10 rounded-pill bottom fontStyle15"
      @click="closeApp"
    >
      {{ $t("Desktop.ReturnToDesktop.exit", language) }}
    </v-btn> -->
    </div>
  </div>
</template>
<script>
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "dataLinked",
  data: () => ({
    language: "",
  }),
  methods: {},
  mounted() {
    this.language = localStorageService.getLanguage();
  },
};
</script>

<style scoped>
.piedss {
  margin-top: 22%;
  margin-bottom: 10px;
}

.google {
  margin-left: 5%;
}
.fin {
  margin-left: 70%;
}
</style>
