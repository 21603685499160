<template>
  <v-container class="bg-surface-variant">
    <v-row>
      <v-col cols="6">
        <v-row class="align-center textarea1" style="margin-bottom: 2%">
          <v-col cols="3">
            <p>{{ pays }}</p>
          </v-col>
          <div class="vertical-hr"></div>
          <v-col cols="3">
            <p>{{ document }}</p>
          </v-col>
        </v-row>

        <v-sheet class="pa-2 ma-2">
          <v-card
            style="background-color: #efefef; border-radius: 10px"
            class="pa-5"
          >
            <v-row>
              <v-col cols="2">
                <v-img
                  v-bind:src="photoUrl"
                  alt=""
                  width="200"
                  class="planche1"
                />
              </v-col>
              <v-col cols="1"><span class="plus">+</span></v-col>
              <v-col cols="3">
                <v-img
                  :src="`data:image/png;base64,${boardDetoured.base64}`"
                  width="500"
                  class="planche"
                />
              </v-col>
            </v-row>
          </v-card>
          <v-alert
            type="info"
            icon="mdi-alert-circle"
            class="text-body-2"
            color="#646464"
            width="50%"
            style="margin-top: 2%; border-radius: 10px"
          >
            <span style="font-family: Georama">dzkdjd</span>
          </v-alert>
        </v-sheet>
      </v-col>
      <v-col cols="6">
        <v-sheet>
          <v-card
            style="background-color: #efefef; border-radius: 10px"
            class="pa-5"
          >
            <h4>Vous recevez</h4>
            <v-list
              style="background-color: #efefef; padding: 0px"
              class="list"
            >
              <v-list-item
                ><v-icon style="font-size: 12px" class="icon">mdi-check</v-icon
                ><span>Une photo numérique(email)</span>
              </v-list-item>
              <v-list-item
                ><v-icon style="font-size: 12px" class="icon">mdi-check</v-icon
                ><span>Une plaquette numérique de vos photos(email)</span>
              </v-list-item>
            </v-list>
            <div class="email">
              <v-row>
                <v-col>
                  <div>
                    <p>{{ $t("EmailApp.email_address", language) }}</p>
                  </div>
                  <div class="search-input-container1">
                    <v-text-field
                      v-model="email"
                      class="search-input1"
                      type="email"
                      placeholder="dominique.rheaume@gmail.com"
                      outlined
                      :color="isClicked ? '#2fc977' : ''"
                      @focus="handleFocus"
                      @blur="handleBlur"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col>
                  <div class="email">
                    <div class="text1">
                      <p>{{ $t("EmailApp.email_address", language) }}</p>
                    </div>
                    <div class="search-input-container1">
                      <v-text-field
                        v-model="email"
                        class="search-input1"
                        type="email"
                        placeholder="dominique.rheaume@gmail.com"
                        outlined
                        :color="isClicked ? '#2fc977' : ''"
                        @focus="handleFocus"
                        @blur="handleBlur"
                      ></v-text-field>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div class="plan">
                <div class="list">
                  <v-list style="background-color: #efefef">
                    <v-list-item style="min-height: 30px"
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font">{{
                        $t("printedCopy.photoQualityPrint", language)
                      }}</span>
                    </v-list-item>
                    <v-list-item style="min-height: 30px" v-if="showParagraph"
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font">{{
                        $t("printedCopy.guarantee", language)
                      }}</span>
                    </v-list-item>
                    <v-list-item style="min-height: 30px"
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font">{{
                        $t("printedCopy.delivered", language)
                      }}</span>
                    </v-list-item>
                    <v-list-item style="min-height: 30px"
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font">{{
                        $t("printedCopy.delivered", language)
                      }}</span>
                    </v-list-item>
                    <v-list-item style="min-height: 30px"
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font">{{
                        $t("printedCopy.delivered", language)
                      }}</span>
                    </v-list-item>
                    <v-list-item style="min-height: 30px"
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font">{{
                        $t("printedCopy.delivered", language)
                      }}</span>
                    </v-list-item>
                    <v-list-item style="min-height: 30px"
                      ><v-icon
                        size="large"
                        style="margin-right: 5px; color: #2fc977"
                        >mdi-check-circle-outline</v-icon
                      ><span class="font">{{
                        $t("printedCopy.delivered", language)
                      }}</span>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </div>

            <div class="container">
              <hr class="separator" />
              <v-btn
                class="custom-button"
                rounded
                style="background-color: #efefef"
                >Voir moins</v-btn
              >
            </div>

            <section>
              <h4 style="margin-top: 5%; margin-bottom: 2%">
                Voulez-vous recevoir des photos imprimées ?
              </h4>
              <p class="textarea">
                <v-icon size="large" style="margin-right: 5px; color: #2fc977"
                  >mdi-check-circle
                </v-icon>
                Photo imprimée sur papier professionnel
              </p>
            </section>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row no-gutters> </v-row>
  </v-container>
</template>

<script>
//import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
//import { isMobile } from "mobile-device-detect";
export default {
  name: "ChoixPhotoApp",
  data: () => ({
    selectedOffers: [],
    shipmentPanel: [],
    verificationPanel: [],
    processingPanel: {},
    totalAmount: 0.0,
    currency: "EUR",
    qrCode: null,
    signatureUid: null,
    priceId: null,
    board: null,
    photoUrl: null,
    price: null,
    errors: [],
    email: "",
    confEmail: "",
    check: "",
    purpose: null,
    country_code: null,
    allowNavigation: false,
    isClicked: false,
    pays: localStorage.getItem("selectedCountry"),
    document: localStorage.getItem("selectedProduct"),
    images: [],
    boardDetoured: {},
    boardEphotoDetoured: {},
    boardResized: {},
    boardEphotoResized: {},
    photoUid: null,
    photoUidResized: null,
  }),
  methods: {
    loadImg(img) {
      return this.images("./" + img + ".jpg");
    },
    handleFocus() {
      this.isClicked = true;
    },
    handleBlur() {
      this.isClicked = false;
    },
    checkForm(e) {
      this.errors = [];
      if (!this.email || !this.confEmail) {
        this.errors.push("Saississez votre email");
        return;
      } else if (this.email !== this.confEmail) {
        this.errors.push("Les adresses e-mail ne correspondent pas");
        return;
      } else {
        localStorageService.setAmount(this.totalAmount);
        if (this.selectedOffers.find((x) => x.needShipment) !== undefined) {
          this.$router.push({
            name: "ChoixPhoto",
          });
        } else {
          this.$router.push({
            name: "ValidationCommande",
          });
        }
        localStorageService.setEmailOwner(this.email);
        localStorageService.setSelectedOffers(this.selectedOffers);
      }
      e.preventDefault();
    },
    load() {
      this.boardDetoured = localStorageService.getPhotoBoardDetoured();
      this.boardEphotoDetoured =
        localStorageService.getPhotoBoardDetouredEphoto();
      this.boardResized = localStorageService.getPhotoBoardResized();
      this.boardEphotoResized =
        localStorageService.getPhotoBoardResizedEphoto();
      this.photoUid = null;
      this.photoUidResized = null;
      this.signatureUid = null;
      this.totalAmount = 0.0;
      this.selectedOffers = [];
      this.boardCount = localStorageService.getBoardPrintCount();
      this.ephotoBoardCount = localStorageService.getBoardEphotoPrintCount();
      this.boardCount =
        this.boardCount === null || this.boardCount === undefined
          ? 0
          : Number(this.boardCount);
      this.ephotoBoardCount =
        this.ephotoBoardCount === null || this.ephotoBoardCount === undefined
          ? 0
          : Number(this.ephotoBoardCount);
      this.pricePhoto = localStorageService.getPrice();
      this.priceOPVerification =
        localStorageService.getPriceOPOfferVerification();
      this.refreshAmount();

      this.pricePrint = localStorageService
        .getPrice()
        .optional_offers.find((x) => x.product.send_by_post_needed);

      this.language = localStorageService.getLanguage();
    },
    /* async showPlanche() {
      try {
        this.photoUrl = localStorageService.getPhotoUrl();
        this.board = localStorageService.getPhotoBoard();
        if (this.board == null) {
          this.photoUid = localStorageService.getPhotoUid();
          this.signatureUid = localStorageService.getSignatureUid();
          requestService
            .post("purpose/show-purpose-spec", {
              priceId: this.price.id,
              photoUid: this.photoUid == undefined ? "" : this.photoUid,
              signatureUid:
                this.signatureUid == null ? undefined : this.signatureUid,
            })
            .then((data) => {
              const img = data;
              if (img.data.boardEphoto) {
                this.board = img.data.boardEphoto;
                localStorageService.setPhotoBoard(this.board);
              } else {
                this.board = img.data.board;
                localStorageService.setPhotoBoard(this.board);
              }
            });
        }
      } catch (error) {
        console.error(error);
      }
    },*/
    async refreshAmount() {
      this.totalAmount = Number(
        this.price !== null && this.price.amount !== null
          ? this.price.amount
          : 0
      );
      this.selectedOffers.forEach(
        (x) => (this.totalAmount += Number(x.amount))
      );
    },
  },
  mounted() {
    this.load();
    //this.showPlanche();
    localStorage.getItem("photoUid", this.photoUid);
    localStorage.getItem("signatureUid", this.signatureUid);
    this.email = localStorageService.getEmailOwner();
    this.confEmail = this.email;
    /* selectedOffersStored = localStorageService.getSelectedOffers();
    this.selectedOffers = selectedOffersStored !== null ?  : []; */
    this.refreshAmount();
  },
};
</script>
<style scoped>
.andicap > .noir {
  margin-left: 10%;
  margin-top: -60px;
  margin-bottom: 10px;
}
.andicap {
  margin-left: 70%;
}
.vertical-hr {
  border: none;
  border-left: 1px solid #efefef;
  height: 50px; /* Définissez la hauteur souhaitée */
  margin: 0 70px; /* Ajoutez des marges si nécessaire */
}
.icon {
  margin-right: 10px;
  color: #000000;
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
  font-size: 15px;
  font-family: Georama;
  font-weight: bold;
}
.search-input-container1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.textarea {
  border: 1px solid black;
  border-radius: 5px;
  width: 70%;
  padding: 2%;
}
.textarea1 {
  border: 1px solid black;
  border-radius: 5px;
  width: 70%;
  max-height: 10%;
  height: 10%;
  margin-left: 3%;
}
.container {
  position: relative;
  font-family: Georama;
}
.separator {
  border: none;
  height: 1px;
  background-color: black;
}
.custom-button {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  background-color: grey;
  text-transform: none;
}
.plan {
  margin-left: 1%;
}
.list {
  margin-left: -20px;
}
.left3 {
  margin-left: 3%;
}
.left {
  margin-left: 2%;
}
.planche {
  margin-left: 40%;
  margin-top: -30%;
}
.planche1 {
  margin-top: 30%;
}
.emp2 {
  margin-left: 8%;
  color: green;
}
.emp {
  margin-left: 40%;
}
.pieds > .hk {
  margin-left: 30px;
  margin-top: -20px;
  margin-bottom: 15px;
}
.info {
  margin-left: 15%;
}
.info1 {
  margin-left: 15%;
}
.info2 {
  margin-left: 15%;
}
.plus {
  margin-left: 38%;
  margin-top: -10%;
}
.emp1 {
  margin-left: 12%;
}
.box {
  font-size: 10px;
}
</style>
