<template>
  <v-container>
    <br />
    <br />
    <div class="search-input-container">
      <input
        class="search-input"
        type="text"
        color="black;"
        placeholder="Find other country ..."
      />

      <v-img
        class="search-icon"
        width="20"
        height="20"
        src="../../assets/images/search-Bold.png"
      ></v-img>
    </div>
    <br />
    <template>
      <v-container>
        <v-row justify="center">
          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/us.jpg"
                cover
              ></v-img>
              <span class="paysText">United States</span>
            </button>
          </v-col>

          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/uk.jpg"
                cover
              ></v-img>
              <span class="paysText">United Kingdom</span>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template>
      <v-container>
        <v-row justify="center">
          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/fr.jpg"
              ></v-img>
              <span class="paysText">France</span>
            </button>
          </v-col>

          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/lv.jpg"
              ></v-img>
              <span class="paysText">Latvia</span>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template>
      <v-container>
        <v-row justify="center">
          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/fr.jpg"
              ></v-img>
              <span class="paysText">France</span>
            </button>
          </v-col>

          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/lv.jpg"
              ></v-img>
              <span class="paysText">Latvia</span>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template>
      <v-container>
        <v-row justify="center">
          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/fr.jpg"
              ></v-img>
              <span class="paysText">France</span>
            </button>
          </v-col>

          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/lv.jpg"
              ></v-img>
              <span class="paysText">Latvia</span>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template>
      <v-container>
        <v-row justify="center">
          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/us.jpg"
                cover
              ></v-img>
              <span class="paysText">United States</span>
            </button>
          </v-col>

          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/uk.jpg"
                cover
              ></v-img>
              <span class="paysText">United Kingdom</span>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template>
      <v-container>
        <v-row justify="center">
          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/us.jpg"
                cover
              ></v-img>
              <span class="paysText">United States</span>
            </button>
          </v-col>

          <v-col>
            <button height="60" width="165" class="btn">
              <v-img
                width="40"
                height="35"
                class="ronde"
                src="../../assets/images/Drapeaux/uk.jpg"
                cover
              ></v-img>
              <span class="paysText">United Kingdom</span>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-container>
</template>
<script>
export default {
  name: "SearchApp",
  data: () => ({}),
};
</script>

<style scoped>
@media only screen and (max-width: 700px) {
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 30%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text1 {
    width: 350px;
    height: 20px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 360;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #4d4d4d;
    font-stretch: 98;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .paysText {
    text-transform: capitalize;
    font-family: "Georama";
    margin-right: 25%;
  }
  .btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;
    width: 140px;
    height: 68px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .search-input-container {
    display: flex;
    justify-content: center;
    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }

  .search-input-container .search-input {
    border: 2px solid #2fc977;
    width: 100%;
    padding: 15px;
  }

  .search-input-container .search-input::placeholder {
    color: black;
  }

  .search-icon {
    align-self: flex-end;
    margin-top: -38px;
    margin-right: 15px;
    color: black;
  }
  .ronde {
    border-radius: 20%;
  }
  .search-input {
    color: black;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 30%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text1 {
    width: 350px;
    height: 20px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 360;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #4d4d4d;
    font-stretch: 98;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .paysText {
    text-transform: capitalize;
    font-family: "Georama";
    margin-right: 25%;
  }
  .btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;
    width: 169px;
    height: 68px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .search-input-container {
    display: flex;
    justify-content: center;
    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }

  .search-input-container .search-input {
    border: 2px solid #2fc977;
    width: 100%;
    padding: 15px;
  }

  .search-input-container .search-input::placeholder {
    color: black;
  }

  .search-icon {
    align-self: flex-end;
    margin-top: -38px;
    margin-right: 15px;
    color: black;
  }
  .ronde {
    border-radius: 20%;
  }
  .search-input {
    color: black;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 30%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text1 {
    width: 350px;
    height: 20px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 360;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #4d4d4d;
    font-stretch: 98;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .paysText {
    text-transform: capitalize;
    font-family: "Georama";
    margin-right: 25%;
  }
  .btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;
    width: 169px;
    height: 68px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .search-input-container {
    display: flex;
    justify-content: center;
    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }

  .search-input-container .search-input {
    border: 2px solid #2fc977;
    width: 100%;
    padding: 15px;
  }

  .search-input-container .search-input::placeholder {
    color: black;
  }

  .search-icon {
    align-self: flex-end;
    margin-top: -38px;
    margin-right: 15px;
    color: black;
  }
  .ronde {
    border-radius: 20%;
  }
  .search-input {
    color: black;
  }
}
</style>
