import Vue from "vue";
import Vuex from "vuex";
import { alert } from "./alert.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    photoUrl: null,
    priceId: null,
    signatureUrl: null,
    showParagraph: true,
  },
  mutations: {
    savePhotoUrl(state, photoUrl) {
      state.photoUrl = photoUrl;
    },

    savePriceId(state, priceId) {
      state.priceId = priceId;
    },

    saveSignatureUrl(state, signatureUrl) {
      state.signatureUrl = signatureUrl;
    },

    toggleParagraph(state) {
      state.showParagraph = !state.showParagraph;
    },
  },
  actions: {},
  modules: {
    alert,
  },
});
