<template>
  <v-container>
    <div class="country">
      <p>Order Cart</p>
    </div>
    <div>
      <button class="btn">
        <div class="image">
          <v-img
            class="noir"
            src="../../assets/images/portrait-expressive-young-woman 1.png"
            width="63"
            height="68"
            cover
          ></v-img>
        </div>
        <div>
          <v-img
            class="vector"
            src="../../assets/images/Vector 6 (Stroke).png"
          ></v-img>
        </div>
        <div class="doc">
          <h6 class="document">Photo for UK Passport</h6>
          <h6 class="document1">€ 9.50</h6>
        </div>
      </button>

      <div class="conf">
        <div class="search-input-container">
          <input
            class="search-input"
            type="text"
            placeholder="Have a discount code?"
          />
        </div>
      </div>
      <div
        class="d-flex justify-space-around align-center flex-column flex-sm-row"
      >
        <button class="take1" color="white" rounded @click="loading = !loading">
          <span class="text5">Apply</span>
        </button>
      </div>

      <button class="cadre">
        <div class="cadre1">
          <div>
            <p class="number">Digital Photo</p>
          </div>
          <div>
            <p class="number1">€ 8.00</p>
          </div>
          <div>
            <p class="number2">Printing</p>
          </div>
          <div>
            <p class="number4">€ 12.00</p>
          </div>
          <hr class="line" />
          <div>
            <p class="total">Total</p>
          </div>
          <div>
            <p class="number3">€ 9.50</p>
          </div>
        </div>
      </button>

      <div
        class="d-flex justify-space-around align-center flex-column flex-sm-row"
      >
        <v-btn class="take3" color="black" rounded @click="loading = !loading">
          <span class="textpl">Pay </span>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "YourOrder",
  data: () => ({}),
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
@media only screen and (max-width: 700px) {
  .total {
    height: 24px;
    margin-right: 80%;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .textpl {
    width: 115px;
    height: 20px;
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;

    width: 250px;
    height: 52px;
    margin-left: 2%;
    margin-top: 20%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .cadre1 {
    margin-top: 30%;
  }
  .line {
    width: 300px;
    height: 1px;

    /* Tetriary Ligjht */

    color: #efefef;
    margin-left: 6%;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .number3 {
    height: 24px;
    margin-right: 8%;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number4 {
    height: 24px;
    margin-right: 8%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    color: #4d4d4d;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number2 {
    width: 93.28px;
    height: 24px;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Primary dark */
  }

  .number1 {
    height: 24px;
    margin-right: 10%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;
  }
  .cadre {
    box-sizing: border-box;
    margin-top: 12%;

    width: 330px;
    height: 150px;
    margin-left: 7%;

    /* Accent */

    border: 2px solid #efefef;
    border-radius: 4px;
  }
  .take1 {
    box-sizing: border-box;
    margin-left: 68%;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;
    margin-top: -14%;

    width: 100px;
    height: 52px;

    /* White */

    background: #000000;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .text5 {
    width: 143px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Primary dark */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .conf {
    margin-top: 3%;
    margin-left: 5%;
  }
  .search-input-container .search-input {
    width: 195px;
    height: 52px;
    margin-top: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;

    /* Tetriary Ligjht */

    background: #efefef;
    /* Tetriary Ligjht */

    border: 2px solid #2fc977;
    border-radius: 4px;
  }
  .search-input-container .search-input::placeholder {
    width: 178px;
    height: 20px;
    left: 16px;
    top: 16px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 1px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 100;
  }
  .search-input-container {
    display: flex;
    justify-content: center;

    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }
  .document1 {
    width: 51px;
    height: 24px;
    margin-left: 27%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .image {
    margin-top: -8%;
  }
  .document {
    width: 165px;
    height: 24px;
    margin-left: 25%;
    margin-top: -5%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .vector {
    margin-left: 100%;
    margin-top: -18%;
    width: 10px;
    height: 6.45px;
  }
  .noir {
    margin-right: 20%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 5%;
    margin-left: 6%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn {
    box-sizing: border-box;
    border: 1px solid #efefef;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 16px;

    width: 330px;
    height: 100px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 8%;
  }
  .number {
    width: 93.28px;
    height: 24px;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-left: 4%;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
    margin-top: -30%;

    /* Primary dark */
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .total {
    height: 24px;
    margin-right: 87%;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .textpl {
    width: 115px;
    height: 20px;
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;

    width: 250px;
    height: 52px;
    margin-left: 2%;
    margin-top: 20%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .cadre1 {
    margin-top: 20%;
  }
  .line {
    width: 600px;
    height: 1px;

    /* Tetriary Ligjht */

    color: #efefef;
    margin-left: 6%;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .number3 {
    height: 24px;
    margin-right: 8%;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-top: -6%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number4 {
    height: 24px;
    margin-right: 8%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    color: #4d4d4d;

    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number2 {
    width: 93.28px;
    height: 24px;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-top: -5%;
    margin-left: 1.5%;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Primary dark */
  }
  .number {
    width: 93.28px;
    height: 24px;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-left: 4%;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
    margin-top: -20%;

    /* Primary dark */
  }
  .number1 {
    height: 24px;
    margin-right: 9%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;
    margin-top: -1%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;
  }
  .cadre {
    box-sizing: border-box;
    margin-top: 12%;

    width: 700px;
    height: 150px;
    margin-left: 7%;

    /* Accent */

    border: 2px solid #efefef;
    border-radius: 4px;
  }
  .take1 {
    box-sizing: border-box;
    margin-left: 58%;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;
    margin-top: -7%;

    width: 150px;
    height: 58px;

    /* White */

    background: #000000;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .text5 {
    width: 143px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Primary dark */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .conf {
    margin-top: 3%;
    margin-left: 5%;
  }
  .search-input-container .search-input {
    width: 400px;
    height: 52px;
    margin-top: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;

    /* Tetriary Ligjht */

    background: #efefef;
    /* Tetriary Ligjht */

    border: 2px solid #2fc977;
    border-radius: 4px;
  }
  .search-input-container .search-input::placeholder {
    width: 178px;
    height: 20px;
    left: 16px;
    top: 16px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 1px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 100;
  }
  .search-input-container {
    display: flex;
    justify-content: center;

    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }
  .document1 {
    width: 51px;
    height: 24px;
    margin-left: 27%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .image {
    margin-top: 0%;
  }
  .doc {
    margin-top: 5%;
  }
  .document {
    width: 165px;
    height: 24px;
    margin-left: 25%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .vector {
    margin-left: 100%;
    margin-top: -15%;
    width: 10px;
    height: 6.45px;
  }
  .noir {
    margin-right: 20%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 5%;
    margin-left: 6%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn {
    box-sizing: border-box;
    border: 1px solid #efefef;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 16px;

    width: 700px;
    height: 100px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 8%;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .total {
    height: 24px;
    margin-right: 80%;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .textpl {
    width: 115px;
    height: 20px;
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;

    width: 250px;
    height: 52px;
    margin-left: 30%;
    margin-top: 20%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .cadre1 {
    margin-top: 30%;
  }
  .line {
    width: 300px;
    height: 1px;

    /* Tetriary Ligjht */

    color: #efefef;
    margin-left: 6%;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .number3 {
    height: 24px;
    margin-right: 8%;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number4 {
    height: 24px;
    margin-right: 8%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    color: #4d4d4d;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number2 {
    width: 93.28px;
    height: 24px;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Primary dark */
  }

  .number1 {
    height: 24px;
    margin-right: 10%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;
  }
  .cadre {
    box-sizing: border-box;
    margin-top: 12%;

    width: 330px;
    height: 150px;
    margin-left: 7%;

    /* Accent */

    border: 2px solid #efefef;
    border-radius: 4px;
  }
  .take1 {
    box-sizing: border-box;
    margin-left: 120%;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;
    margin-top: -20%;

    width: 100px;
    height: 52px;

    /* White */

    background: #000000;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .text5 {
    width: 143px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Primary dark */

    color: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .conf {
    margin-top: 3%;
    margin-left: 5%;
  }
  .search-input-container .search-input {
    width: 197px;
    height: 52px;
    margin-top: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;

    /* Tetriary Ligjht */

    background: #efefef;
    /* Tetriary Ligjht */

    border: 2px solid #2fc977;
    border-radius: 4px;
  }
  .search-input-container .search-input::placeholder {
    width: 178px;
    height: 20px;
    left: 16px;
    top: 16px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 1px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 100;
  }
  .search-input-container {
    display: flex;
    justify-content: center;

    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }
  .document1 {
    width: 51px;
    height: 24px;
    margin-left: 27%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .image {
    margin-top: -8%;
  }
  .document {
    width: 165px;
    height: 24px;
    margin-left: 25%;
    margin-top: -5%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .vector {
    margin-left: 100%;
    margin-top: -18%;
    width: 10px;
    height: 6.45px;
  }
  .noir {
    margin-right: 20%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 5%;
    margin-left: 6%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn {
    box-sizing: border-box;
    border: 1px solid #efefef;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 16px;

    width: 330px;
    height: 100px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 8%;
  }
  .number {
    width: 93.28px;
    height: 24px;

    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-left: 4%;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
    margin-top: -30%;

    /* Primary dark */
  }
}
</style>
