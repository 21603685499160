<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <div>
            <h4>
              Scannnez le QR code avec votre smartphone<br /><span
                style="padding-left: 75px"
                >pour prendre une photo.</span
              >
            </h4>
          </div>
          <div style="margin-left: 100px" class="qrcode">
            <v-img :src="'data:image/jpeg;base64,' + qrcode.qrCode"></v-img>
            <p>{{ qrcode.url }}</p>
          </div>

          <div style="padding-top: 80px">
            <h4>
              <strong
                >Pour les documents officiels, le gouvernement exige<br /><span
                  style="padding-left: 55px"
                  >que la photo soit prise en temps réel.</span
                ></strong
              >
            </h4>
          </div>

          <div style="padding-top: 30px">
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <a
                  style="text-decoration: underline"
                  v-bind="attrs"
                  v-on="on"
                  flat
                  class="font-weight-black"
                >
                  <p class="lien">Comment scanner un QRcode ?</p>
                </a>
              </template>
              <v-card>
                <div class="play-video">
                  <embed
                    style="width: 500px; height: 350px"
                    src="https://www.youtube.com/embed/bk7McNUjWgw"
                  />
                </div>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    Fermer
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <section style="padding-top: 90px">
            <iframe
              width="450"
              height="250"
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </section>
          <br />
          <br />
          <div>
            <div class="font-weight-black">
              <v-list>
                <v-list-item
                  ><v-icon style="margin-right: 10px; color: green"
                    >mdi-checkbox-marked-outline</v-icon
                  >Enlevez les lunettes</v-list-item
                >
                <v-list-item
                  ><v-icon style="margin-right: 10px; color: green"
                    >mdi-checkbox-marked-outline</v-icon
                  >Le visage uniformément éclairé</v-list-item
                >
                <v-list-item
                  ><v-icon style="margin-right: 10px; color: green"
                    >mdi-checkbox-marked-outline</v-icon
                  >Cheveux derrière les épaules</v-list-item
                >
                <v-list-item
                  ><v-icon style="margin-right: 10px; color: green"
                    >mdi-checkbox-marked-outline</v-icon
                  >Bouche fermé, pas de sourire</v-list-item
                >
              </v-list>
            </div>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
import { isMobile } from "mobile-device-detect";
export default {
  name: "QrCodeApp",
  data: () => ({
    qrcode: null,
    refreshTimeout: null,
    dialog: false,
    price: {},
    countryCode: {},
    amount: {},
    currency: {},
  }),
  methods: {
    /**
     * create the qrCode Request for taking the photo/signature
     * This qrcode will be the link between the current order and the photo/signature done by phone
     */
    load() {
      this.price = localStorageService.getPrice();
      var qrCode = localStorageService.getQrCode();

      if (qrCode !== null) {
        this.qrcode = qrCode;
      } else {
        //Need the take the signature and the photo
        if (
          (this.price.purpose !== null &&
            this.price.purpose.signature_needed) ||
          (this.price.product !== null && this.price.product.signature_needed)
        ) {
          requestService
            .get(`/service/photo-signature-qrcode-request`)
            .then((data) => {
              this.qrcode = data.data;
              localStorageService.setQrCode(data.data);
            });
        } else {
          //Need to take only the photo
          requestService.get(`/service/photo-qrcode-request`).then((data) => {
            this.qrcode = data.data;
            localStorageService.setQrCode(data.data);
          });
        }
      }

      //Each 5 secondes, check if the photo/signature was taken
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(() => {
        this.uploadPhotoSignature();
      }, 5000);
    },

    /** Navigate to the next screen ( TraitePhoto ) */
    next() {
      //var self = this;
      this.$router.push({
        name: "TraitePhotoApp",
        params: {
          priceId: this.price.id,
          photoUid: this.photoUid,
          signatureUid: this.signatureUid,
          description:
            "suppression de l'arriére-plan traitement supplementaires éventuels garantie que le photo soit acceptée satisfait ou remboursé",
          needEphoto:
            (this.price.purpose !== null && this.price.purpose.needEphoto) ||
            (this.price.product !== null && this.price.product.needEphoto),
          verificationNeeded:
            (this.price.purpose !== null &&
              this.price.purpose.verification_needed) ||
            (this.price.product !== null &&
              this.price.product.verification_needed),
          amount:
            (this.price.purpose !== null && this.price.purpose.amount) ||
            (this.price !== null && this.price.amount),
          currency:
            (this.price.purpose !== null && this.price.purpose.currency) ||
            (this.price !== null && this.price.currency),
          addOffers: this.price.optional_offers.map(function (x) {
            return {
              id: x.id,
              name: x.product.label,
              description: x.product.description,
              needShipment: x.product.send_by_post_needed,
              amount: x.amount,
              currency: x.currency,
            };
          }),
        },
      });
    },

    /**
     * Check if the photo /signature is done.
     * if it is, save the uid in the localstorage and move to the next screen
     */
    uploadPhotoSignature() {
      requestService
        .get(`/service/qrcode-check/` + this.qrcode.uid, null, null, {
          language: "fr",
        })
        .then((data) => {
          if (data.data.needPhoto) {
            if (data.data.photoUrl !== null) {
              localStorageService.setPhotoUid(data.data.photoUid);
              localStorageService.setPhotoUrl(data.data.photoUrl);
              if (!data.data.needSignature) this.next(); //Go to next screen if signature not needed
            } else {
              //Photo not available, try 5 secondes later
              clearTimeout(this.refreshTimeout);
              this.refreshTimeout = setTimeout(() => {
                this.uploadPhotoSignature();
              }, 5000);
            }
          }
          if (data.data.needSignature) {
            if (data.data.signatureUrl !== null) {
              localStorageService.setSignatureUid(data.data.signatureUid);
              localStorageService.setSignatureUrl(data.data.signatureUrl);
              this.next();
            } else {
              //Signature not available, try 5 secondes later
              clearTimeout(this.refreshTimeout);
              this.refreshTimeout = setTimeout(() => {
                this.uploadPhotoSignature();
              }, 5000);
            }
          }
        });
    },
  },
  mounted() {
    if (isMobile) {
      this.$router.push({
        name: "TakePhoto",
      });
    } else {
      this.load();
    }
  },
};
</script>

<style scoped>
.pictureCheck img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.qrcode {
  margin-top: 50px;
  width: 200px;
  height: 200px;
}
.services li {
  margin: 30px;
  margin-left: 65%;
  margin-top: -15px;
}
.vidapp {
  margin-left: 60%;
  margin-top: -370px;
}
vidapp2 {
  color: black;
}
.text {
  color: black;
}
.lien {
  margin-top: 10%;
  color: black;
  margin-left: 10%;
}
.services {
  margin-top: -20%;
}
</style>
