<template>
  <V-container>
    <div class="bra">
      <h1 class="felicitation">{{ $t("BravoApp.felicitation", language) }}</h1>
      <div class="hk">
        <v-img src="../../assets/images/bravo.jpg" alt="..."></v-img>
      </div>
    </div>
    <div class="text">
      <p>
        {{ $t("BravoApp.text", language) }}
        <span class="email">{{ email }}</span>
        {{ $t("BravoApp.edit_time", language) }} 30
        {{ $t("BravoApp.minutes", language) }}
      </p>
    </div>
    <div>
      <p class="down">
        {{ $t("BravoApp.download_app", language) }}
      </p>
    </div>
    <div class="download">
      <div class="ap">
        <a
          href="https://app.adjust.com/6qr5cc1?engagement_type=fallback_click&redirect=https%3A%2F%2Fapps.apple.com%2Ffr%2Fapp%2Fphoto-identit%25C3%25A9-officielle%2Fid1527550865"
          target="_blank"
          id="smid-download-app-ios"
          ><img
            height="40%"
            width="70%"
            src="https://www.smartphone-id.com/wp-content/uploads/2020/09/appstore-download-1.png"
        /></a>
      </div>
      <div class="gp">
        <a
          href="https://app.adjust.com/mb0xgg0?engagement_type=fallback_click&redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.smartphoneid%26hl%3Dfr%26gl%3DFR"
          ><img
            height="40%"
            width="70%"
            src="https://www.smartphone-id.com/wp-content/uploads/2020/09/playstore-download.png"
            class="google"
        /></a>
      </div>
    </div>
    <br />
    <h6>*{{ $t("BravoApp.check_spam", language) }}</h6>
  </V-container>
</template>

<script>
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "BravoApp",
  data: () => ({
    language: "",
    email: "",
  }),
  mounted() {
    this.email = localStorageService.getEmailOwner();
    this.language = localStorageService.getLanguage();
    alert(this.language);
    if (
      this.language == null ||
      this.language == undefined ||
      this.language == "null"
    ) {
      window.open(process.env.VUE_WEBSITE);
    }
    localStorageService.clearAll();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
@media only screen and (max-width: 700px) {
  .felicitation {
    margin-left: 20%;
  }
  .bra {
    margin-top: 15%;
    margin-left: 10%;
  }
  .hk {
    margin-left: 60%;
    margin-top: -13%;
    height: 10%;
    width: 15%;
  }
  .text {
    text-align: center;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #4d4d4d;
    font-stretch: 100;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .down {
    text-align: center;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #4d4d4d;
    font-stretch: 100;
    margin-top: 30%;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .download {
    margin-left: 20%;
    margin-top: 8%;
  }
  .gp {
    margin-top: 5%;
  }
  .email {
    font-weight: bold;
    color: black;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .felicitation {
    margin-left: 25%;
  }
  .bra {
    margin-top: 15%;
    margin-left: 10%;
  }
  .hk {
    height: 10%;
    width: 15%;
    margin-left: 42%;
    margin-top: -9.5%;
  }
  .text {
    text-align: center;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #4d4d4d;
    font-stretch: 100;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .down {
    text-align: center;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #4d4d4d;
    font-stretch: 100;
    margin-top: 10%;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .download {
    margin-left: 20%;
    margin-top: 8%;
  }
  .gp {
    margin-top: 5%;
  }
  .email {
    font-weight: bold;
    color: black;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .felicitation {
    margin-left: 20%;
  }
  .bra {
    margin-top: 15%;
    margin-left: 10%;
  }
  .hk {
    height: 30%;
    width: 35%;
    margin-left: 75%;
    margin-top: -22%;
  }
  .text {
    text-align: center;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #4d4d4d;
    font-stretch: 100;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .down {
    text-align: center;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #4d4d4d;
    font-stretch: 100;
    margin-top: 30%;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .download {
    margin-left: 20%;
    margin-top: 8%;
  }
  .gp {
    margin-top: 5%;
  }
  .email {
    font-weight: bold;
    color: black;
  }
}
</style>
