<template>
  <v-container class="bg-surface-variant">
    <v-row no-gutters>
      <v-col>
        <v-card class="" elevation="0">
          <stripe-element-payment
            ref="paymentRef"
            :pk="pk"
            :elements-options="elementsOptions"
            :confirm-params="confirmParams"
            locale="fr"
          />
          <v-divider inset color="#212121" style="margin: 20px"></v-divider>
          <v-container class="bg-surface-variant" style="margin-top: 20px">
            <v-row>
              <v-col class="v-col-3 offset-2">
                <v-sheet>
                  <h4>Total à payer : {{ totalAmount }} €</h4>
                </v-sheet>
              </v-col>
              <v-col class="v-col-6">
                <v-sheet>
                  <v-btn
                    @click="pay"
                    style="border: solid"
                    variant="flat"
                    color="#b2b1bb"
                    rounded="pill"
                    size="large"
                  >
                    Valider
                  </v-btn>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <div class="d-flex align-center flex-column">
            <v-card width="500" style="border: solid">
              <v-card-text>
                <div class="text-center" style="font-size: medium">
                  Photo numérique + planche numérique envoyées à <br /><span
                    style="font-weight: bold"
                    ><input v-model="email" id="email" name="email" /></span
                  ><br />reçues sur votre mail dans x minutes
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div
            class="d-flex align-center flex-column"
            v-if="(purpose === 'true') & (country_code === 'FR')"
          >
            <div class="d-flex align-center flex-column">+</div>
            <v-card width="500" style="font-size: medium; border: solid">
              <v-card-text class="text-center" style="font-size: medium">
                <div>code ephoto pour photo ANTS</div>
                <div>contrôle d'experts</div>
                <div>suppression de l'arrière-plan</div>
                <div>traitements supplémentaires éventuels</div>
                <div>garantie que la photo soit acceptée</div>
                <div>satisfait ou remboursé</div>
              </v-card-text>
            </v-card>
          </div>
          <div
            class="d-flex align-center flex-column"
            v-else-if="price.purpose.verification_needed"
          >
            <div class="d-flex align-center flex-column">+</div>
            <v-card width="500" style="font-size: medium; border: solid">
              <v-card-text class="text-center" style="font-size: medium">
                <div>contrôle d'experts</div>
                <div>suppression de l'arrière-plan</div>
                <div>traitements supplémentaires éventuels</div>
                <div>garantie que la photo soit acceptée</div>
                <div>satisfait ou remboursé</div>
              </v-card-text>
            </v-card>
          </div>

          <div
            class="d-flex align-center flex-column"
            v-if="shipmentChoice === 'true'"
          >
            <div class="d-flex align-center flex-column">+</div>
            <v-card width="500" style="border: solid">
              <v-card-text>
                <div class="text-center" style="font-size: medium">
                  Photos imprimées et envoyées à <br /><span
                    style="font-weight: bold"
                    >Mariem Smith chez Dorian <br />House 18, Rue médecin,
                    bâtiment A<br />
                    64300 Montpellier<br />
                    France</span
                  >
                </div>
                <div class="text-center" style="font-size: medium">
                  Livraison estimée 1-2 jours.
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="d-flex align-center flex-column" v-else></div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import { localStorageService } from "@/services/localStorage.service";
export default {
  components: {
    StripeElementPayment,
  },
  data() {
    return {
      email: "",
      board: "",
      totalAmount: "",
      price: {},
      anonymous: [],
      shipmentChoice: false,
      purpose: localStorage.getItem("purpose"),
      country_code: localStorage.getItem("countryCode"),

      pk: process.env.VUE_APP_ENV_STRIPE_PUB_KEY,
      stripeClientSecret: null,
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: "http://localhost:8080/success", // success url
      },
    };
  },
  mounted() {
    this.load();
    this.generatePaymentIntent();
  },
  methods: {
    async generatePaymentIntent() {
      this.stripeClientSecret = localStorageService.getStripeClientSecret();
      this.elementsOptions.clientSecret = this.stripeClientSecret; //paymentIntent.client_secret;
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
    load() {
      this.email = localStorageService.getEmailOwner();
      this.board = localStorageService.getPhotoBoard();
      this.price = localStorageService.getPrice();
      this.totalAmount = localStorageService.getAmount();
      this.shipmentChoice = localStorage.getItem("checkbox");
    },
  },
};
</script>
