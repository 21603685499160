<template>
  <v-container>
    <br />
    <br />
    <br />
    <br />
    <V-container class="piedss">
      <h1 align="center">BRAVO !</h1>
      <div class="hk">
        <v-col cols="3"
          ><v-img
            src="../assets/images/bravo.jpg"
            alt="..."
            height="50%"
            width="85%"
          ></v-img
        ></v-col>
      </div>
    </V-container>
    <V-container>
      <div>
        <h4 align="center">Vous avez terminé la commande avec succès !</h4>
      </div>
      <br />
      <div>
        <h4 align="center">
          Nous avons envoyé un e-mail de confirmation avec les détails de la
          commande et du paiement à
        </h4>
      </div>
      <br />
      <div>
        <h1 align="center">{{ email }}</h1>
      </div>
      <br />
      <br />
    </V-container>
    <v-container>
      <div>
        <h4 align="center">
          Vous pouvez téléchargé l'application SMARTPHONE iD pour suivre votre
          commande.
        </h4>
      </div>
      <br />
      <br />
      <div align="center">
        <a
          href="https://app.adjust.com/6qr5cc1?engagement_type=fallback_click&redirect=https%3A%2F%2Fapps.apple.com%2Ffr%2Fapp%2Fphoto-identit%25C3%25A9-officielle%2Fid1527550865"
          target="_blank"
          id="smid-download-app-ios"
          ><img
            src="https://www.smartphone-id.com/wp-content/uploads/2020/09/appstore-download-1.png"
        /></a>

        <a
          href="https://app.adjust.com/mb0xgg0?engagement_type=fallback_click&redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.smartphoneid%26hl%3Dfr%26gl%3DFR"
          ><img
            src="https://www.smartphone-id.com/wp-content/uploads/2020/09/playstore-download.png"
            class="google"
        /></a>
      </div>
    </v-container>
    <br />
    <br />
    <v-container>
      <div class="fin">
        <h6>*Merci de consulter vos spams si vous ne recevez pas de photos.</h6>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import { localStorageService } from "@/services/localStorage.service";
//import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      name: "MessageApp",
      email: "",
    };
  },
  methods: {
    load() {
      this.email = localStorageService.getEmailOwner();
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.piedss > .hk {
  margin-left: 55%;
  margin-top: -65px;
  margin-bottom: 10px;
}

.google {
  margin-left: 5%;
}
.fin {
  margin-left: 70%;
}
</style>
