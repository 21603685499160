<template>
  <v-container>
    <v-span class="top" v-if="photoReady">{{
      $t("photoShootMobile.take_photo_mobile", language)
    }}</v-span>
    <v-span class="top" v-else>{{
      $t("beforePhoto.title_before_photo", language)
    }}</v-span>
    <v-row no-gutters>
      <v-col>
        <v-sheet>
          <div class="prise">
            <photo-shooter-mob
              ref="shooter"
              @photoReady="photoReady = $event"
              @cacherImage="cacherImage"
              :price="price"
            ></photo-shooter-mob>
          </div>
          <div class="prise1 justify-center d-flex">
            <v-btn
              style="
                background-color: black;
                color: white;
                font-family: Georama;
                text-transform: none;
              "
              block
              rounded
              v-if="photoReady"
              @click="saveAndContinue"
              :disabled="uploading"
              :loading="uploading"
            >
              {{ $t("photoShootMobile.yes_continue", language) }}
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <div>
      <!--<div v-show="imageVisible">
        <v-img src="../../assets/images/reduced_size_gif.gif" alt="" />
      </div>-->

      <div>
        <v-sheet v-if="imageVisible" class="d-flex justify-center">
          <img
            v-for="(image, index) in images"
            :key="index"
            :src="image.url"
            v-show="index === currentIndex2"
            max-height="500"
            max-width="500"
            width="500"
            height="500"
            cover
          />
        </v-sheet>
        <v-sheet v-if="imageVisible" class="d-flex justify-center">
          <p style="font-family: Georama" class="font-weight-bold">
            {{ slideItem }}
          </p>
        </v-sheet>
      </div>

      <!-- <v-sheet v-if="imageVisible" class="d-flex justify-center">
      <p style="font-family: Georama" class="font-weight-bold">
        {{ images[currentIndex2].title }}
      </p>
    </v-sheet> -->

      <!--<v-img
        class="pictureCss"
        v-show="imageVisible"
        src="../../assets/images/home-removebg-preview.png"
      ></v-img> -->
    </div>
  </v-container>
</template>

<script>
import PhotoShooterMob from "@/components/PhotoShooterMob";
import { localStorageService } from "@/services/localStorage.service";
import { requestService } from "@/services/request.service";
//import myVideo from "vue-video";
export default {
  name: "TakePhoto",
  data() {
    return {
      photoReady: false,
      uploading: false,
      photoUrl: null,
      price: null,
      dialog: false,
      imageVisible: true,
      imageV: false,
      language: localStorageService.getLanguage(),
      slides: [],
      images: [],
      currentIndex1: 0,
      currentIndex2: 0,
      slideItem: "",
      imageItem: "",
      video: {
        sources: [
          {
            src: "https://storage.googleapis.com/smid-static/video.mp4",
            type: "video/mp4",
          },
        ],
        options: {
          autoplay: "",
          volume: 0.6,
          playsinline: "",
        },
      },
    };
  },
  components: {
    PhotoShooterMob,
    //myVideo,
  },
  methods: {
    startCarousel() {
      // Sets an interval to automatically change slides every 4 seconds
      setInterval(() => {
        this.currentIndex1 = (this.currentIndex1 + 1) % this.slides.length;
        this.slideItem = this.slides[this.currentIndex1];
      }, 4000);
      // Hides the image by making the 'imageVisible' attribute false and 'imageV' true
      setInterval(() => {
        this.currentIndex2 = (this.currentIndex2 + 1) % this.images.length;
        this.imageItem = this.images[this.currentIndex2];
      }, 2000);
    },
    // Cache l'image en rendant l'attribut 'imageVisible' false et 'imageV' true
    cacherImage() {
      this.imageVisible = false;
      this.imageV = true;
    },
    // Displays the next slide if one is available
    afficherSuivant() {
      if (this.currentIndex < this.slides.length - 1) {
        this.currentIndex++;
      }
    },
    // Save the data and go to the next step
    async saveAndContinue() {
      this.uploading = true;
      this.$refs.shooter.stopCameraStream();
      this.$refs.shooter.save();

      const photoFormData = new FormData();
      const photoBlob = await (await fetch(this.$store.state.photoUrl)).blob();
      photoFormData.append("file", photoBlob);
      photoFormData.append("type", "photo");
      requestService.post(`/file/upload`, photoFormData).then((data) => {
        localStorageService.setPhotoUid(data.data.uid);
        if (this.price.purpose.signature_needed) {
          this.$router.push({
            name: "SignatureMob",
            params: { country: localStorageService.getCountryCode() },
          });
        } else {
          //TODO ABY : file/resize for PhotoUidDigitalDetoured price->purpose->child_purposes !== [], tu prends le premier purpose

          this.$router.push({
            name: "EmailApp",
            params: { country: localStorageService.getCountryCode() },
          });
        }
      });
    },
    load() {
      localStorageService.setStripeClientSecret(null);
      localStorageService.setPhotoBoardDetoured(null);
      localStorageService.setPhotoBoardDetouredAddOffert(null);
      localStorageService.setPhotoBoardDetouredEphoto(null);
      localStorageService.setPhotoBoardResized(null);
      localStorageService.setPhotoBoardResizedAddOffert(null);
      localStorageService.setPhotoBoardResizedEphoto(null);
      // Defines default information for the application
      localStorageService.setInfo({
        page: "alert-message-1",
        fullscreen: false,
      });
    },

    /**
     * Load price
     */
    loadPrice() {
      var userCountry = localStorageService.getCountryCode();
      var language = localStorageService.getLanguage();
      requestService
        .get(
          `/price/from-country/` +
            userCountry +
            `/to/` +
            this.$route.params.countryCode +
            `/for/` +
            this.$route.params.priceId,
          null,
          null,
          { language: language }
        )
        .then((data) => {
          this.price = data.data.result;
          localStorageService.setPrice(this.price);
          if (this.price === null) {
            this.$store.dispatch(
              "alert/error",
              "La page demandée n'est pas accessible."
            );
            this.$router.push({ name: "NotAuthorized" });
          }
        });
    },
  },

  mounted() {
    //To clear data : localStorageService.clearAll();
    if (
      this.$route.params.priceId === undefined ||
      this.$route.params.priceId === null ||
      this.$route.params.countryCode === undefined ||
      this.$route.params.countryCode === null
    ) {
      this.$store.dispatch(
        "alert/error",
        "La page demandée n'est pas accessible."
      );
      this.$router.push({ name: "NotAuthorized" });
      return;
    }

    this.loadPrice();
    this.load();
    const title1 = this.$t("beforePhoto.neutral_face", this.language);
    const title2 = this.$t("beforePhoto.hair_behind", this.language);
    const title3 = this.$t("beforePhoto.off_glasses", this.language);
    const title4 = this.$t("beforePhoto.even_light", this.language);
    this.slides = [title1, title2, title3, title4];
    this.slideItem = title1;
    (this.images = [
      { url: "https://storage.googleapis.com/smid-static/smile.png" },
      { url: "https://storage.googleapis.com/smid-static/normal.png" },
      { url: "https://storage.googleapis.com/smid-static/hair.png" },
      { url: "https://storage.googleapis.com/smid-static/normal.png" },
      { url: "https://storage.googleapis.com/smid-static/glasses.png" },
      { url: "https://storage.googleapis.com/smid-static/normal.png" },
      { url: "https://storage.googleapis.com/smid-static/shadow.png" },
      { url: "https://storage.googleapis.com/smid-static/normal.png" },
      // Ajoutez autant d'images que vous le souhaitez
    ]),
      (this.imageItem = {
        url: "https://storage.googleapis.com/smid-static/smile.png",
      });
    this.startCarousel();
  },
};
</script>

<style scoped>
body {
  overflow: hidden;
}
.pictureCss {
  width: 100%;
  margin-right: auto;
  margin-left: 4%;
  height: 100%;
}

.qrcode {
  margin-top: 50px;
}
.top {
  margin-top: 2%;
  height: 40px;

  font-family: "Georama";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Primary dark */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.services li {
  margin: 30px;
  margin-left: 70%;
  margin-top: -15px;
}

.prise1 {
  margin-left: 26%;
  margin-right: 24%;
  margin-top: 2%;
  font-family: "Georama";
}

.vidapp {
  margin-left: 70%;
  margin-top: -300px;
}

.vidapp2 {
  color: black;
}

.text {
  color: black;
}

.lien {
  margin-top: 10%;
  color: black;
  margin-left: 10%;
}

.services {
  margin-top: -20%;
}

.rigth {
  margin-left: 10%;
}

.rigth1 {
  margin-left: 4%;
}

.rigth2 {
  margin-left: 10%;
}

.photo {
  margin-right: 50%;
  margin-top: 5%;
}

.decaler {
  margin-left: 10%;
  margin-top: 10%;
}
.pic {
  width: 70%;
  height: 20%;
  margin-left: 18%;
}
.prise {
  margin-right: 2%;
  font-family: "Georama";
}
</style>
