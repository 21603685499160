<template>
  <v-container>
    <div class="country">
      <p>Select plan</p>
    </div>
    <div class="text">
      <p>Chosen Plan</p>
    </div>

    <v-col cols="6">
      <button class="btn">
        <div class="search-icon">
          <v-img
            width="37"
            height="24"
            src="../../assets/images/labelpro.png"
          ></v-img>
          <v-img
            class="pen"
            :aspect-ratio="1"
            src="../../assets/images/pen-Bold.png"
            cover
          ></v-img>
        </div>
        <v-img
          class="noir"
          src="../../assets/images/Pro.png"
          width="48"
          height="48"
          cover
        ></v-img>
        <h6 class="document1">€ 4.00</h6>
      </button>
    </v-col>
    <hr class="line" />

    <div class="plan">
      <span class="features">Printed copy added</span>
      <div
        class="d-flex justify-space-around align-center flex-column flex-sm-row"
      >
        <button class="take1" color="white" rounded @click="loading = !loading">
          <span class="text5">See Preview</span>
        </button>
      </div>

      <div class="list">
        <v-list>
          <v-list-item
            ><v-icon style="margin-right: 10px; color: springgreen"
              >mdi-check-circle-outline</v-icon
            ><span class="font">Photo-quality print</span>
          </v-list-item>
          <v-list-item
            ><v-icon style="margin-right: 10px; color: springgreen"
              >mdi-check-circle-outline</v-icon
            ><span class="font">Print acceptance guaranteed</span>
          </v-list-item>
          <v-list-item
            ><v-icon style="margin-right: 10px; color: springgreen"
              >mdi-check-circle-outline</v-icon
            ><span class="font">Delivered to your doorstep</span>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <v-col cols="6">
      <button class="btn1">
        <div>
          <v-img
            class="search-icon1"
            src="../../assets/images/minus-square-Bold-Green.png"
          ></v-img>
        </div>
        <h6 class="imp1">6 photos | € 4.00</h6>
        <v-img
          class="pen1"
          :aspect-ratio="1"
          src="../../assets/images/pen-Bold.png"
          cover
        ></v-img>
      </button>
    </v-col>

    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <v-btn class="take3" color="black" rounded @click="loading = !loading">
        <span class="textpl">Proceed € 8.00 </span>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "EditPrintedCopy",
  data: () => ({}),
};
</script>

<style scoped>
.take3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 350px;
  height: 52px;
  margin-right: 8%;
  margin-top: 20%;

  /* Primary dark */

  background: #000000;
  box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
  border-radius: 28px;
}
.textpl {
  width: 115px;
  height: 20px;
  text-transform: capitalize;

  font-family: "Georama";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: center;

  /* White */

  color: #ffffff;

  text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.text5 {
  width: 143px;
  height: 20px;

  font-family: "Georama";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;

  /* identical to box height, or 125% */

  text-align: center;

  /* Primary dark */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.take1 {
  box-sizing: border-box;
  margin-left: 35%;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 10px;
  margin-top: -7%;

  position: absolute;
  width: 169px;
  height: 38px;

  /* White */

  background: #ffffff;
  /* Tetriary Ligjht */

  border: 2px solid #efefef;
  border-radius: 28px;
}
.features {
  width: 350px;
  height: 28px;
  margin-top: 7%;
  margin-left: 3%;

  font-family: "Georama";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  /* Primary dark */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.line {
  width: 350px;
  height: 1px;
  left: 20px;
  top: 398px;
  margin-top: 7%;
  margin-left: 6%;

  /* Tetriary Ligjht */

  background: #efefef;
}
.noir {
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  z-index: 5;
}
.document1 {
  left: 4.73%;
  right: 4.73%;
  top: 58.82%;
  bottom: 14.71%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: -0.05em;

  /* Accent */

  color: #2fc977;
}
.country {
  width: 350px;
  height: 40px;
  margin-top: 20%;
  margin-left: 3%;

  font-family: "Georama";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Primary dark */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.text {
  width: 350px;
  height: 28px;
  margin-top: 10%;
  font-family: "Georama";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-left: 3%;
  /* identical to box height, or 140% */

  /* Primary dark */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.btn {
  box-sizing: border-box;
  border: 1px solid #efefef;
  margin-top: 5%;

  /* Auto layout */

  padding: 12px 32px 16px;
  gap: 16px;

  width: 350px;
  height: 142px;

  /* White */

  background: #ffffff;
  /* Tetriary Ligjht */

  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 20%;
}
.btn1 {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 16px;
  gap: 16px;
  width: 350px;
  height: 68px;
  left: 20px;
  margin-top: 3%;

  /* White */

  background: #ffffff;
  /* Accent */

  border: 2px solid #2fc977;
  border-radius: 4px;
}
.search-icon {
  align-self: flex-end;
  margin-top: -3px;
  margin-left: -10%;
}
.search-icon1 {
  margin-top: 3px;
  margin-left: 8%;
  color: green;
  border-radius: 1px;
}
.pen {
  bottom: 8.34%;
  margin-left: 94%;
  margin-top: -6%;
}
.pen1 {
  margin-left: 38%;
}
.font {
  width: 272px;
  height: 24px;
  left: 56px;
  top: 474px;

  font-family: "Georama";
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.2px;

  /* Secondary Gray */

  color: #4d4d4d;
  font-stretch: 96;
}
.plan {
  margin-top: 7%;
  margin-left: 1%;
}
.list {
  margin-right: 25%;
}
.imp1 {
  font-family: "Georama";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-left: 7%;
}
</style>
