<template>
  <div>
    <div class="d-flex justify-center">
      <v-btn
        v-if="photoReady"
        color="success"
        @click="saveAndContinue"
        :disabled="uploading"
        :loading="uploading"
        >Continuer</v-btn
      >
    </div>
    <photo-shooter
      ref="shooter"
      @photoReady="photoReady = $event"
    ></photo-shooter>
  </div>
</template>

<script>
import PhotoShooter from "@/components/PhotoShooter";
import { requestService } from "@/services/request.service";

export default {
  name: "Photo",
  data() {
    return {
      photoReady: false,
      uploading: false,
    };
  },
  components: {
    PhotoShooter,
  },
  methods: {
    async saveAndContinue() {
      this.uploading = true;
      this.$refs.shooter.stopCameraStream();
      this.$refs.shooter.save();
      if (this.$store.state.rejectStatus === "rejected_photo") {
        //Photo save
        const photoFormData = new FormData();
        const photoBlob = await (
          await fetch(this.$store.state.photoUrl)
        ).blob();
        photoFormData.append("file", photoBlob);
        photoFormData.append("type", "photo");

        const photoPromise = requestService
          .post(`/file/upload`, photoFormData)
          .then((data) => {
            this.$store.commit("photoUid", data.data.uid);
          });

        Promise.all([photoPromise])
          .then(() => {
            //order save
            const orderFormData = JSON.stringify({
              status: "pending_verification",
              photo: {
                status: "pending_verification",
                newPhotoUid: this.$store.state.photoUid,
              },
            });
            requestService
              .post(
                "/document/" + this.$store.state.documentId + "/update",
                orderFormData,
                {},
                {
                  "content-type": "application/json",
                  shortlive_api_key: this.$store.state.shortliveApiKey,
                }
              )
              .then(() => {
                this.$router.push({ name: "Updated" });
              })
              .catch(() => {
                this.$store.dispatch(
                  "alert/error",
                  "Erreur lors de la création de la commande."
                );
                this.$router.push({ name: "NotAuthorized" });
              });
          })
          .catch(() => {
            this.$store.dispatch(
              "alert/error",
              "Erreur lors de l'envoi de vos documents."
            );
            this.$router.push({ name: "NotAuthorized" });
          })
          .finally(() => {
            this.uploading = false;
          });
      } else {
        this.$router.push({ name: "Signature" });
        this.uploading = false;
      }
    },
  },
  mounted() {},
};
</script>
