<template>
  <v-container>
    <div class="country">
      <p>One step away from taking your photo</p>
    </div>

    <div class="text">
      <p>Choose the country below</p>
    </div>
    <br />
    <v-row>
      <v-col>
        <div class="button-grid">
          <v-btn
            class="font-weight-black"
            rounded.0
            color="with"
            v-model="selectedCounty"
            v-for="countrie in countries.slice(0, 4)"
            :key="countrie.id"
            @click="next(null)"
            >{{ countrie.country_name }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <br />
    <div class="search-input-container">
      <v-select
        type="text"
        placeholder="Find other country ..."
        v-model="selectedCounty"
        :items="countries"
        item-text="country_name"
      >
        <template #append>
          <v-icon>mdi-magnify</v-icon>
        </template></v-select
      >
      <!-- <div>
  <button v-on:click="loadCountry">Afficher la liste des pays</button>
  <ul v-if="countries.length > 0">
    <li v-for="country in countries" :key="country.code">{{ country.name }}</li>
  </ul>
</div> -->
    </div>

    <div class="text1">
      <p>Validate the photo for over 200 countries!</p>
    </div>
    <v-btn
      @click="next(null)"
      class="font-weight-black"
      rounded
      color="with"
      dark
    >
      Faire une photo
    </v-btn>
  </v-container>
</template>

<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "chooseCountry",
  data: () => ({
    countries: [],
    selectedCounty: null,
    products: [],
    allCountries: [],
  }),
  methods: {
    async loadCountry(countryCode) {
      var language = localStorageService.getLanguage();
      requestService
        .get(`/country/customized?country_code=${countryCode}`, null, null, {
          language: language,
        })
        .then((data) => {
          this.countries = data.data.Countries;
          var country_name = localStorageService.getCountryName();
          this.countries.forEach((country) => {
            if (country.country_name === country_name) {
              this.selectedCounty = country;
              //console.log(country_name);
            }
          });
        });
    },
    next() {
      localStorage.setItem("selectedCounty", this.selectedCounty);
      console.log(localStorage.getItem("selectedCounty"));
      this.$router.push({
        name: "chooseDocument",
      });
    },
  },
  mounted() {
    this.loadCountry();
  },
};
</script>

<style scoped>
/* Styles pour les écrans de petite taille */
@media only screen and (max-width: 700px) {
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-top: 20%;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text1 {
    width: 350px;
    height: 20px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 360;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #4d4d4d;
    font-stretch: 98;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .paysText {
    text-transform: capitalize;
    font-family: "Georama";
    margin-right: 25%;
  }
  .btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;
    width: 135px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .search-input-container {
    display: flex;
    justify-content: center;
    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }

  .search-input-container .search-input {
    border: 1px solid #efefef;
    background: #efefef;
    width: 100%;
    padding: 15px;
  }

  .search-input-container .search-input::placeholder {
    color: #4d4d4d;
  }

  .search-icon {
    align-self: flex-end;
    margin-top: -38px;
    margin-right: 15px;
  }
  .ronde {
    border-radius: 20%;
    width: 40px;
    height: 35px;
  }
}

/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 15px;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 15px;
  }
  .text1 {
    width: 350px;
    height: 20px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 360;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #4d4d4d;
    font-stretch: 98;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .paysText {
    text-transform: capitalize;
    font-family: "Georama";
    margin-right: 25%;
  }
  .btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;
    width: 169px;
    height: 68px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .search-input-container {
    display: flex;
    justify-content: center;
    margin-left: 2.1%;
    margin-right: 5%;
    flex-direction: column;
  }

  .search-input-container .search-input {
    border: 1px solid #efefef;
    background: #efefef;
    width: 100%;
    padding: 15px;
  }

  .search-input-container .search-input::placeholder {
    color: #4d4d4d;
  }

  .search-icon {
    align-self: flex-end;
    margin-top: -38px;
    margin-right: 15px;
  }
  .ronde {
    display: flex;
    justify-content: center;
    border-radius: 20%;
    width: 40px;
    height: 35px;
  }
  .button-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 400px; /* adjust the max width to your preference */
    background-color: transparent;
  }

  .button-grid v-btn {
    flex-basis: calc(50% - 10px);
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    .button-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 20px;
    }
  }
}
</style>
