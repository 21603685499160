<template>
  <v-container>
    <br />
    <v-row justify="center">
      <v-btn color="primary" dark @click.stop="dialog = true">
        Open Dialog
      </v-btn>
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <br />
          <v-card-text color="black" class="font-weight-black">
            Sans notre contrôle d'experts,nous ne pouvons garantir que cette
            photo sera acceptée.
          </v-card-text>
          <v-card-text class="font-weight-black">
            Êtes-vous sûr de vouloir la recevoir sans contrôle ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              style="border: solid"
              variant="flat"
              color="black"
              rounded="pill"
              size="large"
              text
              @click="dialog = false"
            >
              Non
            </v-btn>

            <v-btn
              style="border: solid"
              variant="flat"
              color="black"
              rounded="pill"
              size="large"
              text
              @click="dialog = false"
            >
              Oui
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PopUpApp",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style scoped></style>
