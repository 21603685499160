/*********************************************** Price ***************************************/
/**
 * save the price in the localstorage
 * @param price
 */
function setPrice(price) {
  localStorage.setItem("price", JSON.stringify(price));
}

/**
 * return the object price
 * @returns Price
 */
function getPrice() {
  return JSON.parse(localStorage.getItem("price"));
}

/*********************************************** PriceOPOfferVerification ***************************************/
/**
 * save the priceOPOfferVerification in the localstorage
 * @param priceOPOfferVerification
 */
function setPriceOPOfferVerification(priceOPOfferVerification) {
  localStorage.setItem(
    "priceOPOfferVerification",
    JSON.stringify(priceOPOfferVerification)
  );
}

/**
 * return the object price
 * @returns PriceOPOfferVerification
 */
function getPriceOPOfferVerification() {
  const val = localStorage.getItem("priceOPOfferVerification");
  return val === "undefined"
    ? null
    : JSON.parse(localStorage.getItem("priceOPOfferVerification"));
}

/*********************************************** PriceOPShipment ***************************************/
/**
 * save the priceOPOfferVerification in the localstorage
 * @param priceOPShipment
 */
function setPriceOPShipment(priceOPShipment) {
  localStorage.setItem("priceOPShipment", JSON.stringify(priceOPShipment));
}

/**
 * return the object price
 * @returns PriceOPShipment
 */
function getPriceOPShipment() {
  return JSON.parse(localStorage.getItem("priceOPShipment"));
}

/*********************************************** Adresse ***************************************/
/**
 * save the price in the localstorage
 * @param price
 */
function setAdresse(adresse) {
  localStorage.setItem("adresse", JSON.stringify(adresse));
}

/**
 * return the object price
 * @returns Price
 */
function getAdresse() {
  return JSON.parse(localStorage.getItem("adresse"));
}

/*********************************************** CountryPrice ***************************************/

/**
 * save the countryCode in the localstorage
 * @param price
 */
function setCountryCode(countryCode) {
  localStorage.setItem("countryCode", JSON.stringify(countryCode));
}

/**
 * return the countryCode
 * @returns Price
 */
function getCountryCode() {
  return JSON.parse(localStorage.getItem("countryCode"));
}

/**
 * save the countryName in the localstorage
 * @param price
 */
function setCountryName(countryName) {
  localStorage.setItem("countryName", JSON.stringify(countryName));
}

/**
 * return the countryName
 * @returns Price
 */
function getCountryName() {
  return JSON.parse(localStorage.getItem("countryName"));
}

/*********************************************** DeviceName ***************************************/

/**
 * save the device used in the localstorage
 * @param deviceName
 */
function setDeviceName(deviceName) {
  localStorage.setItem("deviceName", deviceName);
}

/**
 * return the device name
 * @returns deviceName
 */
function getDeviceName() {
  return localStorage.getItem("deviceName");
}

/*********************************************** Language ***************************************/

/**
 * save the user language in the localstorage
 * @param language
 */
function setLanguage(language) {
  localStorage.setItem("language", language);
}

/**
 * return the device language
 * @returns language
 */
function getLanguage() {
  return localStorage.getItem("language");
}

/*********************************************** Promo ***************************************/

/**
 * save the promo in the localstorage
 * @param promo
 */
function setPromo(promo) {
  localStorage.setItem("promo", JSON.stringify(promo));
}

/**
 * return the promo
 * @returns promo
 */
function getPromo() {
  return JSON.parse(localStorage.getItem("promo"));
}

/*********************************************** PhotoUid ***************************************/

/**
 * save the photoUid in the localstorage
 * @param photoUid
 */
function setPhotoUid(photoUid) {
  localStorage.setItem("photoUid", photoUid);
}

/**
 * return the photoUid
 * @returns photoUid
 */
function getPhotoUid() {
  return localStorage.getItem("photoUid");
}

/*********************************************** SignatureUid ***************************************/

/**
 * save the signatureUid in the localstorage
 * @param signatureUid
 */
function setSignatureUid(signatureUid) {
  localStorage.setItem("signatureUid", signatureUid);
}

/**
 * return the signatureUid
 * @returns signatureUid
 */
function getSignatureUid() {
  return localStorage.getItem("signatureUid");
}

/*********************************************** PhotoUrl ***************************************/

/**
 * save the photoUrl in the localstorage
 * @param photoUrl
 */
function setPhotoUrl(photoUrl) {
  localStorage.setItem("photoUrl", photoUrl);
}

/**
 * return the photoUrl
 * @returns photoUrl
 */
function getPhotoUrl() {
  return localStorage.getItem("photoUrl");
}

/*********************************************** SignatureUrl ***************************************/

/**
 * save the signatureUrl in the localstorage
 * @param signatureUrl
 */
function setSignatureUrl(signatureUrl) {
  localStorage.setItem("signatureUrl", signatureUrl);
}

/**
 * return the signatureUrl
 * @returns signatureUrl
 */
function getSignatureUrl() {
  return localStorage.getItem("signatureUrl");
}

/*********************************************** qrCode ***************************************/

/**
 * save the qrCode in the localstorage
 * @param qrCode
 */
function setQrCode(qrCode) {
  localStorage.setItem("qrCode", JSON.stringify(qrCode));
}

/**
 * return the qrCode
 * @returns qrCode
 */
function getQrCode() {
  return JSON.parse(localStorage.getItem("qrCode"));
}

/*********************************************** PhotoBoardDetoured ***************************************/

/**
 * save the photoBoardDetoured in the localstorage
 * @param photoBoardDetoured
 */
function setPhotoBoardDetoured(photoBoardDetoured) {
  //localStorage.setItem("photoBoardDetoured", photoBoardDetoured);
  localStorage.setItem(
    "photoBoardDetoured",
    JSON.stringify(photoBoardDetoured)
  );
}

/**
 * return the photoBoardDetoured
 * @returns photoBoardDetoured
 */
function getPhotoBoardDetoured() {
  //return localStorage.getItem("photoBoardDetoured");
  return JSON.parse(localStorage.getItem("photoBoardDetoured"));
}

/*********************************************** PhotoBoardDetouredAddOffert ***************************************/

/**
 * save the photoBoardDetouredAddOffert in the localstorage
 * @param photoBoardDetouredAddOffert
 */
function setPhotoBoardDetouredAddOffert(photoBoardDetouredAddOffert) {
  //localStorage.setItem("photoBoardDetouredAddOffert", photoBoardDetouredAddOffert);
  localStorage.setItem(
    "photoBoardDetouredAddOffert",
    JSON.stringify(photoBoardDetouredAddOffert)
  );
}

/**
 * return the photoBoardDetouredAddOffert
 * @returns photoBoardDetouredAddOffert
 */
function getPhotoBoardDetouredAddOffert() {
  //return localStorage.getItem("photoBoardDetouredAddOffert");
  return JSON.parse(localStorage.getItem("photoBoardDetouredAddOffert"));
}

/*********************************************** PhotoBoardDetouredEphoto ***************************************/

/**
 * save the PhotoBoardDetouredEphoto in the localstorage
 * @param photoBoardDetouredEphoto
 */
function setPhotoBoardDetouredEphoto(photoBoardDetouredEphoto) {
  //localStorage.setItem("photoBoardDetouredEphoto", PhotoBoardDetouredEphoto);
  localStorage.setItem(
    "photoBoardDetouredEphoto",
    JSON.stringify(photoBoardDetouredEphoto)
  );
}

/**
 * return the photoBoardDetouredEphoto
 * @returns photoBoardDetouredEphoto
 */
function getPhotoBoardDetouredEphoto() {
  //return localStorage.getItem("photoBoardDetouredEphoto");
  return JSON.parse(localStorage.getItem("photoBoardDetouredEphoto"));
}

/*********************************************** PhotoBoardResized ***************************************/

/**
 * save the photoBoardResized in the localstorage
 * @param photoBoardResized
 */
function setPhotoBoardResized(photoBoardResized) {
  //localStorage.setItem("photoBoardResized", photoBoardResized);
  localStorage.setItem("photoBoardResized", JSON.stringify(photoBoardResized));
}

/**
 * return the photoBoardResized
 * @returns photoBoardResized
 */
function getPhotoBoardResized() {
  //return localStorage.getItem("photoBoardResized");
  return JSON.parse(localStorage.getItem("photoBoardResized"));
}

/*********************************************** PhotoBoardResizedAddOffert ***************************************/

/**
 * save the photoBoardResizedAddOffert in the localstorage
 * @param photoBoardResizedAddOffert
 */
function setPhotoBoardResizedAddOffert(photoBoardResizedAddOffert) {
  //localStorage.setItem("photoBoardResizedAddOffert", photoBoardResizedAddOffert);
  localStorage.setItem(
    "photoBoardResizedAddOffert",
    JSON.stringify(photoBoardResizedAddOffert)
  );
}

/**
 * return the photoBoardResizedAddOffert
 * @returns photoBoardResizedAddOffert
 */
function getPhotoBoardResizedAddOffert() {
  //return localStorage.getItem("photoBoardResizedAddOffert");
  return JSON.parse(localStorage.getItem("photoBoardResizedAddOffert"));
}

/*********************************************** PhotoBoardResizedEphoto ***************************************/

/**
 * save the PhotoBoardResizedEphoto in the localstorage
 * @param photoBoardResizedEphoto
 */
function setPhotoBoardResizedEphoto(photoBoardResizedEphoto) {
  //localStorage.setItem("photoBoardResizedEphoto", PhotoBoardResizedEphoto);
  localStorage.setItem(
    "photoBoardResizedEphoto",
    JSON.stringify(photoBoardResizedEphoto)
  );
}

/**
 * return the photoBoardResizedEphoto
 * @returns photoBoardResizedEphoto
 */
function getPhotoBoardResizedEphoto() {
  //return localStorage.getItem("photoBoardResizedEphoto");
  return JSON.parse(localStorage.getItem("photoBoardResizedEphoto"));
}

/*********************************************** PhotoBase64Detoured ***************************************/

/**
 * save the photoBase64Detoured in the localstorage
 * @param PhotoBase64Detoured
 */
function setPhotoBase64Detoured(PhotoBase64Detoured) {
  localStorage.setItem("photoBase64Detoured", PhotoBase64Detoured);
}

/**
 * return the photoBase64Detoured
 * @returns PhotoBase64Detoured
 */
function getPhotoBase64Detoured() {
  return localStorage.getItem("photoBase64Detoured");
}

/*********************************************** PhotoUidDigitalDetoured ***************************************/

/**
 * save the photoUidDigitalDetoured in the localstorage
 * @param PhotoUidDigitalDetoured
 */
function setPhotoUidDigitalDetoured(PhotoUidDigitalDetoured) {
  localStorage.setItem("photoUidDigitalDetoured", PhotoUidDigitalDetoured);
}

/**
 * return the photoUidDigitalDetoured
 * @returns PhotoUidDigitalDetoured
 */
function getPhotoUidDigitalDetoured() {
  return localStorage.getItem("photoUidDigitalDetoured");
}

/*********************************************** PhotoUidDetoured ***************************************/

/**
 * save the photoUidDetoured in the localstorage
 * @param PhotoUidDetoured
 */
function setPhotoUidDetoured(PhotoUidDetoured) {
  localStorage.setItem("photoUidDetoured", PhotoUidDetoured);
}

/**
 * return the photoUidDetoured
 * @returns PhotoUidDetoured
 */
function getPhotoUidDetoured() {
  return localStorage.getItem("photoUidDetoured");
}

/*********************************************** PhotoUidResized ***************************************/

/**
 * save the photoUidResized in the localstorage
 * @param PhotoUidResized
 */
function setPhotoUidResized(PhotoUidResized) {
  localStorage.setItem("photoUidResized", PhotoUidResized);
}

/**
 * return the photoUidResized
 * @returns PhotoUidResized
 */
function getPhotoUidResized() {
  return localStorage.getItem("photoUidResized");
}

/*********************************************** PhotoBase64Resize ***************************************/

/**
 * save the photoBase64Resize in the localstorage
 * @param PhotoBase64Resize
 */
function setPhotoBase64Resize(PhotoBase64Resize) {
  localStorage.setItem("photoBase64Resize", PhotoBase64Resize);
}

/**
 * return the photoBase64Resize
 * @returns PhotoBase64Resize
 */
function getPhotoBase64Resize() {
  return localStorage.getItem("photoBase64Resize");
}

/*********************************************** emailOwner ***************************************/

/**
 * save the emailOwner in the localstorage
 * @param emailOwner
 */
function setEmailOwner(emailOwner) {
  localStorage.setItem("emailOwner", emailOwner);
}

/**
 * return the emailOwner
 * @returns emailOwner
 */
function getEmailOwner() {
  return localStorage.getItem("emailOwner");
}

/*********************************************** stripeClientSecret ***************************************/

/**
 * save the stripeClientSecret in the localstorage
 * @param stripeClientSecret
 */
function setStripeClientSecret(stripeClientSecret) {
  localStorage.setItem("stripeClientSecret", stripeClientSecret);
}

/**
 * return the stripeClientSecret
 * @returns stripeClientSecret
 */
function getStripeClientSecret() {
  return localStorage.getItem("stripeClientSecret");
}

/*********************************************** Amount ***************************************/

/**
 * save the amount in the localstorage
 * @param amount
 */
function setAmount(amount) {
  localStorage.setItem("amount", amount);
}

/**
 * return the amount
 * @returns amount
 */
function getAmount() {
  return localStorage.getItem("amount");
}

/*********************************************** selectedOffers ***************************************/

/**
 * save the selectedOffers in the localstorage
 * @param selectedOffers
 */
function setSelectedOffers(selectedOffers) {
  localStorage.setItem("selectedOffers", JSON.stringify(selectedOffers));
}

/**
 * return the selectedOffers
 * @returns selectedOffers
 */
function getSelectedOffers() {
  return JSON.parse(localStorage.getItem("selectedOffers"));
}

/*********************************************** info ***************************************/
/**
 * save the price in the localstorage
 * @param info
 */
function setInfo(info) {
  localStorage.setItem("info", JSON.stringify(info));
}

/**
 * return the object price
 * @returns Price
 */
function getInfo() {
  return JSON.parse(localStorage.getItem("info"));
}

/*********************************************** boardPrintCount ***************************************/
/**
 * save the boardPrintCount in the localstorage
 * @param count
 */
function setBoardPrintCount(count) {
  localStorage.setItem("boardPrintCount", count);
}

/**
 * return the object price
 * @returns boardPrintCount
 */
function getBoardPrintCount() {
  return localStorage.getItem("boardPrintCount");
}

/*********************************************** boardEphotoPrintCount ***************************************/
/**
 * save the boardEphotoPrintCount in the localstorage
 * @param count
 */
function setBoardEphotoPrintCount(count) {
  localStorage.setItem("boardEphotoPrintCount", count);
}

/**
 * return the object price
 * @returns boardEphotoPrintCount
 */
function getBoardEphotoPrintCount() {
  return localStorage.getItem("boardEphotoPrintCount");
}

/**
 * Removes all key/value pairs, if there are any.
 * Dispatches a storage event on Window objects holding an equivalent Storage object.
 */
function clearAll() {
  localStorage.clear();
}

export const localStorageService = {
  setPrice,
  getPrice,
  setPriceOPOfferVerification,
  getPriceOPOfferVerification,
  setPriceOPShipment,
  getPriceOPShipment,
  getAdresse,
  setAdresse,
  setCountryCode,
  getCountryCode,
  setCountryName,
  getCountryName,
  setDeviceName,
  getDeviceName,
  setLanguage,
  getLanguage,
  setPromo,
  getPromo,
  setPhotoUid,
  getPhotoUid,
  setSignatureUid,
  getSignatureUid,
  setPhotoUrl,
  getPhotoUrl,
  setSignatureUrl,
  getSignatureUrl,
  setQrCode,
  getQrCode,
  setPhotoBoardDetoured,
  getPhotoBoardDetoured,
  setPhotoBoardDetouredAddOffert,
  getPhotoBoardDetouredAddOffert,
  setPhotoBoardDetouredEphoto,
  getPhotoBoardDetouredEphoto,
  setPhotoBoardResized,
  getPhotoBoardResized,
  setPhotoBoardResizedAddOffert,
  getPhotoBoardResizedAddOffert,
  setPhotoBoardResizedEphoto,
  getPhotoBoardResizedEphoto,
  setPhotoUidDigitalDetoured,
  getPhotoUidDigitalDetoured,
  setPhotoUidDetoured,
  getPhotoUidDetoured,
  setPhotoUidResized,
  getPhotoUidResized,
  setPhotoBase64Detoured,
  getPhotoBase64Detoured,
  setPhotoBase64Resize,
  getPhotoBase64Resize,
  setEmailOwner,
  getEmailOwner,
  setStripeClientSecret,
  getStripeClientSecret,
  setAmount,
  getAmount,
  setSelectedOffers,
  getSelectedOffers,
  getInfo,
  setInfo,
  setBoardPrintCount,
  getBoardPrintCount,
  setBoardEphotoPrintCount,
  getBoardEphotoPrintCount,
  clearAll,
};
