<template>
  <v-container class="bg-surface-variant">
    <v-row no-gutters>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <div>
            <div class="text-h6 mb-1">
              {{ price.purpose.label }}
            </div>

            <div class="planche1">
              <v-img v-bind:src="photoUrl" alt="" width="200" />
            </div>
            <div class="plus">+</div>
            <div class="planche">
              <v-img :src="`data:image/png;base64,${board}`" width="300" />
            </div>
          </div>
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <div class="d-flex align-center flex-column">
            <v-card width="500" style="border: solid">
              <v-card-text>
                <div class="text-center" style="font-size: medium">
                  Photo numérique + planche numérique envoyées à <br /><span
                    style="font-weight: bold"
                    ><input v-model="email" id="email" name="email" /></span
                  ><br />reçues sur votre mail dans x minutes
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div
            class="d-flex align-center flex-column"
            v-if="(purpose === 'true') & (country_code === 'FR')"
          >
            <div class="d-flex align-center flex-column">+</div>
            <v-card width="500" style="font-size: medium; border: solid">
              <v-card-text class="text-center" style="font-size: medium">
                <div>code ephoto pour photo ANTS</div>
                <div>contrôle d'experts</div>
                <div>suppression de l'arrière-plan</div>
                <div>traitements supplémentaires éventuels</div>
                <div>garantie que la photo soit acceptée</div>
                <div>satisfait ou remboursé</div>
              </v-card-text>
            </v-card>
          </div>
          <div
            class="d-flex align-center flex-column"
            v-else-if="price.purpose.verification_needed"
          >
            <div class="d-flex align-center flex-column">+</div>
            <v-card width="500" style="font-size: medium; border: solid">
              <v-card-text class="text-center" style="font-size: medium">
                <div>contrôle d'experts</div>
                <div>suppression de l'arrière-plan</div>
                <div>traitements supplémentaires éventuels</div>
                <div>garantie que la photo soit acceptée</div>
                <div>satisfait ou remboursé</div>
              </v-card-text>
            </v-card>
          </div>

          <div
            class="d-flex align-center flex-column"
            v-if="shipmentChoice === true"
          >
            <div class="d-flex align-center flex-column">+</div>
            <v-card width="500" style="border: solid">
              <v-card-text>
                <div class="text-center" style="font-size: medium">
                  Photos imprimées et envoyées à <br /><span
                    style="font-weight: bold"
                    >{{ adresse.lastName }} <br />{{ adresse.city }}
                    <br />
                    {{ adresse.adresse }}<br />
                    {{ adresse.country }} {{ adresse.postalCode }}<br />
                    {{ adresse.region }}</span
                  >
                </div>
                <div class="text-center" style="font-size: medium">
                  Livraison estimée 1-2 jours.
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="d-flex align-center flex-column" v-else></div>
        </v-sheet>
      </v-col>
    </v-row>
    <v-divider inset color="#212121"></v-divider>
    <div style="margin-top: 20px">
      <v-row>
        <v-col class="v-col-3 offset-2">
          <v-sheet>
            <h4>Total à payer : {{ totalAmount }} €</h4>
          </v-sheet>
        </v-col>
        <v-col class="v-col-6">
          <v-sheet>
            <v-btn
              @click="next"
              style="border: solid"
              variant="flat"
              color="#b2b1bb"
              rounded="pill"
              size="large"
            >
              Confirmer et Payer
            </v-btn>
          </v-sheet>
        </v-col>
        <v-col></v-col>
      </v-row>
    </div>

    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="auto">
          <v-card width="500">
            <div style="text-align: right">
              <v-icon right size="large" @click="dialog = false"
                >mdi-close-circle</v-icon
              >
            </div>
            <v-col>
              <v-card class="" elevation="0">
                <stripe-element-payment
                  ref="paymentRef"
                  :pk="pk"
                  :elements-options="elementsOptions"
                  :confirm-params="confirmParams"
                  locale="fr"
                />
                <v-divider
                  inset
                  color="#212121"
                  style="margin: 20px"
                ></v-divider>
                <v-container
                  class="bg-surface-variant"
                  style="margin-top: 20px"
                >
                  <v-row>
                    <v-col class="v-col-3 offset-2">
                      <v-sheet>
                        <h4>Total à payer : {{ totalAmount }} €</h4>
                      </v-sheet>
                    </v-col>
                    <v-col class="v-col-6">
                      <v-sheet>
                        <v-btn
                          @click="pay"
                          style="border: solid"
                          variant="flat"
                          color="#b2b1bb"
                          rounded="pill"
                          size="large"
                        >
                          Valider
                        </v-btn>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-container>
</template>
<script>
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
//import { isMobile } from "mobile-device-detect";
export default {
  components: {
    StripeElementPayment,
  },
  data() {
    return {
      name: "ValidationCommande",
      email: "",
      board: "",
      photoUrl: "",
      nom: "",
      region: "",
      rue: "",
      code: "",
      ville: "",
      adresse: null,
      pays: "",
      totalAmount: "",
      price: {},
      anonymous: [],
      shipmentChoice: false,
      purpose: localStorage.getItem("purpose"),
      country_code: localStorage.getItem("countryCode"),
      selectedOffers: [],
      dialog: false,
      pk: process.env.VUE_APP_ENV_STRIPE_PUB_KEY,
      stripeClientSecret: null,
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: "http://localhost:8080/message", // success url
      },
    };
  },
  methods: {
    load() {
      this.email = localStorageService.getEmailOwner();
      this.photoUrl = localStorageService.getPhotoUrl();
      this.adresse = localStorageService.getAdresse();
      this.board = localStorageService.getPhotoBoard();
      this.price = localStorageService.getPrice();
      this.totalAmount = localStorageService.getAmount();
      this.selectedOffers = localStorageService.getSelectedOffers();
      this.shipmentChoice =
        this.selectedOffers === null
          ? false
          : this.selectedOffers.find((x) => x.needShipment) !== undefined;
      //verification data
      if (this.email == null || this.email == "") {
        this.$router.push({
          name: "TraitePhotoApp",
        });
      }
      if (this.adresse.lastName == null || this.adresse.lastName == "") {
        this.$router.push({
          name: "ChoixPhoto",
        });
      }
      if (this.adresse.region == null || this.adresse.region == "") {
        this.$router.push({
          name: "ChoixPhoto",
        });
      }
      if (this.adresse.city == null || this.adresse.city == "") {
        this.$router.push({
          name: "ChoixPhoto",
        });
      }
      if (this.adresse.postalCode == null || this.adresse.postalCode == "") {
        this.$router.push({
          name: "ChoixPhoto",
        });
      }
      if (this.adresse.country == null || this.adresse.country == "") {
        this.$router.push({
          name: "ChoixPhoto",
        });
      }
      if (this.adresse.adresse == null || this.adresse.adresse == "") {
        this.$router.push({
          name: "ChoixPhoto",
        });
      }
    },

    async generatePaymentIntent() {
      this.stripeClientSecret = localStorageService.getStripeClientSecret();
      //alert(this.stripeClientSecret);
      this.elementsOptions.clientSecret = this.stripeClientSecret; //paymentIntent.client_secret;
    },
    pay() {
      this.$refs.paymentRef.submit();
    },

    next() {
      this.dialog = true;
      localStorageService.setStripeClientSecret("null");
      var stripeClientSecret = localStorageService.getStripeClientSecret();
      if (stripeClientSecret !== null && stripeClientSecret !== "null") {
        this.$router.push({
          name: "Payment",
        });
        return;
      }
      var photoUid = localStorageService.getPhotoUid();
      var signatureUid = localStorageService.getSignatureUid();
      var orderItems = [
        {
          quantity: 1,
          document: {
            photo: photoUid == null ? undefined : photoUid,
            signature: signatureUid == null ? undefined : signatureUid,
          },
          priceId: this.price.id,
        },
      ];
      if (this.selectedOffers !== null) {
        this.selectedOffers.forEach((x) =>
          orderItems.push({ quantity: 1, priceId: x.id })
        );
      }
      const orderFormData = JSON.stringify({
        emailOwner: this.email,
        orderItems: orderItems,
      });
      /* this.purpose = (price.price.purpose.ephoto_needed);
           this.country_code = (price.price.use_in_country.country_code);*/
      requestService
        .post("order/anonymous", orderFormData, null, {
          "content-type": "application/json",
        })
        .then((data) => {
          localStorageService.setStripeClientSecret(
            data.data.stripe_client_secret
          );
          /* this.$router.push({
            name: "Payment",
          });*/
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.load();
    this.generatePaymentIntent();
    // Récupération de la valeur du checkbox depuis l'URL de la page précédente
    /*const urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams);
    this.shipmentChoice = urlParams.get('shipmentChoice') === 'on'; */
  },
};
</script>
<style scoped>
.planche {
  margin-left: 40%;
  margin-top: -30%;
}
.planche1 {
  margin-top: 30%;
}
.plus {
  margin-left: 38%;
  margin-top: -10%;
}
</style>
