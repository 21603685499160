<template>
  <v-container>
    <div class="country">
      <p>{{ $t("printedCopy.title_print", language) }}</p>
    </div>
    <div class="price">
      <p class="text">
        {{ pricePrint == null ? "" : pricePrint.local_currency_symbol }}
        {{ pricePrint == null ? "" : pricePrint.amount_in_local_currency
        }}<span class="suite">{{ $t("printedCopy.copy", language) }}</span>
      </p>
    </div>
    <div class="plan">
      <div class="list">
        <v-list>
          <v-list-item style="min-height: 30px"
            ><v-icon size="large" style="margin-right: 5px; color: #2fc977"
              >mdi-check-circle-outline</v-icon
            ><span class="font">{{
              $t("printedCopy.photoQualityPrint", language)
            }}</span>
          </v-list-item>
          <v-list-item style="min-height: 30px" v-if="showParagraph"
            ><v-icon size="large" style="margin-right: 5px; color: #2fc977"
              >mdi-check-circle-outline</v-icon
            ><span class="font">{{
              $t("printedCopy.guarantee", language)
            }}</span>
          </v-list-item>
          <v-list-item style="min-height: 30px"
            ><v-icon size="large" style="margin-right: 5px; color: #2fc977"
              >mdi-check-circle-outline</v-icon
            ><span class="font">{{
              $t("printedCopy.delivered", language)
            }}</span>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <div class="image" v-if="boardDetoured.base64 != null">
      <div>
        <v-img
          width="310"
          height="200"
          :aspect-ratio="1"
          :src="
            (priceOPVerification != null && priceOPVerification != undefined) ||
            pricePhoto.purpose.verification_needed
              ? `data:image/png;base64,${boardDetoured.base64}`
              : `data:image/png;base64,${boardResized.base64}`
          "
          cover
        ></v-img>
      </div>
      <br />
      <div>
        <div class="card" v-if="boardDetoured.count === 6">
          <span
            >{{ pricePhoto.purpose.photo_board_description }}
            <!--{{ boardDetoured.width_mm }} x {{ boardDetoured.height_mm }} mm -->
          </span>
        </div>

        <div class="all">
          <v-btn-toggle
            v-model="toggle_none"
            variant="outlined"
            divided
            class="number"
          >
            <v-btn @click="decrementBoardCount" :disabled="boardCount === 0">
              <div>
                <v-img
                  width="10"
                  height="2"
                  src="../../assets/images/Rectangle 97.png"
                ></v-img>
              </div>
            </v-btn>

            <v-btn>
              <span>{{ boardCount }}</span>
            </v-btn>

            <v-btn @click="incrementBoardCount" :disabled="boardCount === 1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>
      <br />
    </div>

    <br />
    <div class="image" v-if="boardEphotoDetoured.base64 != null">
      <div>
        <v-img
          width="310"
          height="200"
          style="margin-top: 5%; margin-bottom: 5%"
          cover
          :aspect-ratio="1"
          :src="
            (priceOPVerification != null && priceOPVerification != undefined) ||
            pricePhoto.purpose.verification_needed
              ? `data:image/png;base64,${boardEphotoDetoured.base64}`
              : `data:image/png;base64,${boardEphotoResized.base64}`
          "
        ></v-img>
      </div>
      <br />
      <div>
        <!--<div class="card" v-if="boardEphotoDetoured.count === 6">
          <span
            >Card of {{ boardEphotoDetoured.count }} photos
            {{ boardEphotoDetoured.width_mm }} x
            {{ boardEphotoDetoured.height_mm }} mm each and printed
            ephotoCode</span
          >
        </div> -->

        <div class="all">
          <v-btn-toggle
            v-model="toggle_exclusive"
            variant="outlined"
            divided
            class="number"
          >
            <v-btn
              @click="decrementEphotoBoardCount"
              :disabled="ephotoBoardCount === 0"
            >
              <div>
                <v-img
                  width="10"
                  height="2"
                  src="../../assets/images/Rectangle 97.png"
                ></v-img>
              </div>
            </v-btn>

            <v-btn>
              <span>{{ ephotoBoardCount }}</span>
            </v-btn>

            <v-btn
              @click="incrementEphotoBoardCount"
              :disabled="ephotoBoardCount === 1"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <button class="take3" rounded @click="next">
        <span class="textpl"
          >{{ $t("printedCopy.proceed_price", language) }}
          {{ total }}
          {{ pricePrint == null ? "" : pricePrint.local_currency_symbol }}</span
        >
      </button>
    </div>
  </v-container>
</template>

<script>
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "PrintedCopy",
  data: () => ({
    boardDetoured: {},
    boardEphotoDetoured: {},
    boardResized: {},
    boardEphotoResized: {},
    photoUid: null,
    photoUidResized: null,
    signatureUid: null,
    totalAmount: 0.0,
    selectedOffers: [],
    boardCount: 0,
    ephotoBoardCount: 0,
    pricePrint: null,
    pricePhoto: {},
    priceOPVerification: {},
    language: "",
    alignment: {},
    incrementBoardCountExecuted: false,
    incrementEphotoBoardCountExecuted: false,
    choice: null,
  }),
  methods: {
    // Method to increment the board print count
    incrementBoardCount() {
      this.boardCount++;
      this.decrementEphotoBoardCount();
      this.incrementBoardCountExecuted = true;
    },
    // Method to decrement the board print count
    decrementBoardCount() {
      if (this.boardCount > 0) {
        this.boardCount--;
      }
    },
    // Method to increment the ephoto board print count
    incrementEphotoBoardCount() {
      this.ephotoBoardCount++;
      this.decrementBoardCount();
      this.incrementEphotoBoardCountExecuted = true;
    },
    // Method to decrement the ephoto board print count
    decrementEphotoBoardCount() {
      if (this.ephotoBoardCount > 0) {
        this.ephotoBoardCount--;
      }
    },
    // Method to navigate to the next step
    next() {
      // Store data in local storage and navigate based on board counts
      localStorageService.setBoardPrintCount(this.boardCount);
      localStorageService.setBoardEphotoPrintCount(this.ephotoBoardCount);
      localStorageService.setPriceOPShipment(this.pricePrint);
      localStorage.setItem("total", this.total);
      if (this.boardCount == 0 && this.ephotoBoardCount == 0) {
        this.$router.push({
          name: "YourOrderDetail",
        }); // Navigate to 'YourOrderDetail' if counts are 0
      } else {
        this.$router.push({
          name: "AddressLivraison",
        }); // Otherwise, navigate to 'AddressLivraison'
      }
    },
    // Method to refresh the total amount based on selected offers and prices
    async refreshAmount() {
      // Calculate total amount considering selected offers and prices
      this.totalAmount = Number(
        this.pricePrint !== null && this.pricePrint.amount !== null
          ? this.pricePrint.amount
          : 0
      );
      this.selectedOffers.forEach(
        (x) => (this.totalAmount += Number(x.amount))
      );
    },
    // Method to load initial data
    load() {
      this.boardDetoured = localStorageService.getPhotoBoardDetoured();
      this.boardEphotoDetoured =
        localStorageService.getPhotoBoardDetouredEphoto();
      this.boardResized = localStorageService.getPhotoBoardResized();
      this.boardEphotoResized =
        localStorageService.getPhotoBoardResizedEphoto();
      this.photoUid = null;
      this.photoUidResized = null;
      this.signatureUid = null;
      this.totalAmount = 0.0;
      this.selectedOffers = [];
      this.boardCount = localStorageService.getBoardPrintCount();
      this.ephotoBoardCount = localStorageService.getBoardEphotoPrintCount();
      this.boardCount =
        this.boardCount === null || this.boardCount === undefined
          ? 0
          : Number(this.boardCount);
      this.ephotoBoardCount =
        this.ephotoBoardCount === null || this.ephotoBoardCount === undefined
          ? 0
          : Number(this.ephotoBoardCount);
      this.pricePhoto = localStorageService.getPrice();
      this.priceOPVerification =
        localStorageService.getPriceOPOfferVerification();
      // Calculate initial total amount based on loaded data
      this.refreshAmount();

      this.pricePrint = localStorageService
        .getPrice()
        .optional_offers.find((x) => x.product.send_by_post_needed);

      this.language = localStorageService.getLanguage();
    },
  },
  // Computed property to calculate the total price including board print price
  computed: {
    formattedCount() {
      return `Count: ${this.boardCount}`;
    },
    formattedEphotoCount() {
      return `Count1: ${this.ephotoBoardCount}`;
    },
    priceImp: function () {
      if (this.pricePrint == null) {
        return "";
      }
      let price = this.pricePrint.amount_in_local_currency;
      return price * (this.boardCount + this.ephotoBoardCount);
    },
    total: function () {
      // Calculate the total price based on various factors
      return (
        (this.priceOPVerification != null &&
        this.priceOPVerification != undefined
          ? Number(this.priceOPVerification.amount_in_local_currency)
          : 0) +
        Number(this.priceImp) +
        Number(this.pricePhoto.amount_in_local_currency)
      ).toFixed(2);
    },
    showParagraph() {
      return this.$store.state.showParagraph;
    },
  },
  mounted() {
    localStorageService.setStripeClientSecret(null);
    this.choice = this.$route.params.choice;
    this.load();
    //alert(this.language);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
@media only screen and (max-width: 700px) {
  .price {
    margin-top: 3%;
    margin-left: 4%;
  }
  .country {
    margin-top: 2%;
    margin-left: 4%;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: "Georama";
  }
  .text {
    height: 10px;
    text-transform: capitalize;
    font-family: "Georama";
    font-weight: bold;
    font-style: normal;
    font-size: 16px;
    /* identical to box height, or 125% */

    margin-right: 3%;

    /* Accent */

    color: #2fc977;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take2 {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;

    width: 350px;
    height: 52px;
    left: 10px;
    margin-top: 20%;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .suite {
    width: 58px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    /* identical to box height, or 167% */

    text-transform: lowercase;

    /* Accent */

    color: #2fc977;
  }
  .font {
    color: #919191;
    font-size: small;
    font-family: Georama;
  }
  .plan {
    margin-left: 1%;
  }
  .list {
    margin-right: 25%;
    margin-left: -7px;
  }
  .card {
    width: 176px;
    height: 40px;
    font-weight: bold;
    text-align: center;
    margin-left: 3%;
    font-size: small;
    font-family: Georama;
    color: dark;
    font-stretch: 98;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
  .number {
    box-sizing: border-box;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 26px;
    margin-left: 60%;
    margin-top: 5%;
  }
  .all {
    margin-top: -9%;
    background: #ffffff;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    margin-top: 35%;
    color: #ffffff;
    font-family: Georama;
    width: 350px;
    height: 52px;

    /* Tetriary Ligjht */

    background: #000000;
    border-radius: 28px;
    color: white;
  }
  .textpl {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Secondary Gray */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .price {
    margin-top: 3%;
    margin-left: 4%;
  }
  .country {
    margin-top: 2%;
    margin-left: 4%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text {
    height: 28px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    /* identical to box height, or 117% */

    letter-spacing: -0.05em;

    /* Accent */

    color: #2fc977;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .suite {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    text-transform: lowercase;

    /* Accent */

    color: #2fc977;
  }
  .font {
    font-size: small;
    color: #919191;
  }
  .plan {
    margin-left: 1%;
  }
  .list {
    margin-right: 25%;
  }
  .card {
    width: 176px;
    height: 40px;
    text-align: center;
    margin-left: 3%;
    font-family: Georama;
    /* or 125% */
    /* Secondary Gray */

    color: dark;
    font-stretch: 98;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
  .number {
    box-sizing: border-box;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 26px;
    margin-left: 60%;
    margin-top: 5%;
  }
  .all {
    margin-top: -6%;
    background: #ffffff;
    margin-right: 30%;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    margin-top: 35%;
    color: #ffffff;
    font-family: Georama;
    width: 350px;
    height: 52px;
    /* Tetriary Ligjht */

    background: #000000;
    border-radius: 28px;
  }
  .textpl {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Secondary Gray */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .image {
    align-content: center;
    justify-content: center;
    margin-left: 20%;
  }
  .price {
    margin-top: 3%;
    margin-left: 4%;
  }
  .country {
    margin-top: 2%;
    margin-left: 4%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text {
    height: 28px;
    font-family: "Georama";
    font-weight: bold;
    font-size: 90px;
    line-height: 28px;
    /* identical to box height, or 117% */

    letter-spacing: -0.05em;

    /* Accent */

    color: #2fc977;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .suite {
    width: 58px;
    height: 20px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */

    text-transform: lowercase;

    /* Accent */

    color: #2fc977;
  }
  .font {
    font-size: small;
    color: #919191;
  }
  .plan {
    margin-left: 1%;
  }
  .list {
    margin-right: 25%;
  }
  .card {
    width: 176px;
    height: 40px;
    text-align: center;
    margin-left: 3%;
    font-size: small;
    font-family: Georama;
    /* Secondary Gray */

    color: dark;
    font-stretch: 98;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
  .number {
    box-sizing: border-box;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 26px;
    margin-left: 60%;
    margin-top: 5%;
  }
  .all {
    margin-left: 40%;
    margin-top: -9%;
    background: #ffffff;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    margin-top: 35%;
    margin-left: 40%;
    width: 250px;
    height: 52px;
    font-family: Georama;
    /* Tetriary Ligjht */

    background: #efefef;
    border-radius: 28px;
  }
  .textpl {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Secondary Gray */

    color: #ffffff;

    opacity: 0.35;
    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
</style>
