import { render, staticRenderFns } from "./PrintedCopy.vue?vue&type=template&id=319dc3ea&scoped=true&"
import script from "./PrintedCopy.vue?vue&type=script&lang=js&"
export * from "./PrintedCopy.vue?vue&type=script&lang=js&"
import style0 from "./PrintedCopy.vue?vue&type=style&index=0&id=319dc3ea&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "319dc3ea",
  null
  
)

export default component.exports