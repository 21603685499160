<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-sheet class="pa-2 ma-2">
          <h3 style="font-family: Verdana, Arial, Helvetica, sans-serif">
            Quelle planche imprimée souhaitez-vous commander ?
          </h3>
        </v-sheet>
        <v-row no-gutters>
          <v-col order="last">
            <v-sheet>
              <v-img :src="`data:image/png;base64,${board}`" width="400" />
              <v-select
                class=""
                :items="['1 planche de 04 photos', '2 planches de 04 photos']"
              >
              </v-select>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10">
            <v-sheet>
              <h2
                style="
                  margin-bottom: 30px;
                  font-family: Verdana, Arial, Helvetica, sans-serif;
                "
              >
                Votre adresse de livraison
              </h2>
              <v-alert type="error" v-if="errors.length">
                <ul>
                  <li v-for="error in errors" :key="error.id">{{ error }}</li>
                </ul>
              </v-alert>
              <v-row>
                <v-col md2>
                  <v-text-field
                    v-model="nom"
                    label="Nom"
                    id="nom"
                    :rules="[requiredRule]"
                    placeholder="Nom"
                  ></v-text-field>
                </v-col>
                <v-col md2>
                  <v-text-field
                    v-model="region"
                    label="Region"
                    id="region"
                    :rules="[requiredRule]"
                    placeholder="Region"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md2>
                  <v-text-field
                    v-model="rue"
                    label="Rue"
                    id="rue"
                    :rules="[requiredRule]"
                    placeholder="Rue"
                  ></v-text-field>
                </v-col>
                <v-col md2>
                  <v-text-field
                    v-model="code"
                    label="Code Postal*"
                    id="code"
                    :rules="[requiredRule, numericRule]"
                    placeholder="Code Postal"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col md2>
                  <v-text-field
                    v-model="ville"
                    label="Ville"
                    id="ville"
                    :rules="[requiredRule]"
                    placeholder="Ville"
                  ></v-text-field>
                </v-col>
                <v-col md2>
                  <v-text-field
                    v-model="pays"
                    label="Pays"
                    id="pays"
                    :rules="[requiredRule]"
                    placeholder="Pays"
                  ></v-text-field>
                </v-col>
              </v-row>
              <div style="margin-top: 10px">
                <h5>
                  <v-icon size="large">mdi-shield-lock</v-icon>Informations
                  sécurisées sur le RGPD
                </h5>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4">
        <v-sheet
          class="pa-2 ma-2"
          style="font-weight: bold; margin-bottom: 10px"
        >
          <div>
            <p style="text-align: center">Votre commande</p>
            <p style="text-align: center">
              Photo, signature et code ephoto<br />pour le permis de conduire
              français
            </p>
          </div>
          <div class="decalage">
            <v-img v-bind:src="photoUrl" alt="" width="200" />
          </div>
          <div class="plus">+</div>
          <v-img :src="`data:image/png;base64,${board}`" width="500" />
          <div>
            <h5 style="margin-top: 20px">1 * photo numérique</h5>
            <h5 style="margin-top: 20px">1 * code ePhoto</h5>
            <h5 style="margin-top: 20px">1 * garantie de conformité</h5>
            <h5 style="margin-top: 20px">1 * planche de 06 photos imprimées</h5>
            <br />
            <v-divider color="dark" class="font-weight-black"></v-divider>
            <h5 class="text-center" style="margin-top: 20px">
              A payer : {{ totalAmount }} €
            </h5>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <v-btn
        @click="submitForm"
        variant="flat"
        class="font-weight-black"
        rounded
        color="with"
        dark
        size="large"
      >
        Valider
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "ChoixPhoto",
  data: () => ({
    nom: "",
    region: "",
    rue: "",
    code: "",
    ville: "",
    pays: "",
    nomIsValid: true,
    regionIsValid: true,
    rueIsValid: true,
    codeIsValid: true,
    villeIsValid: true,
    paysIsValid: true,
    board: "",
    photoUrl: "",
    totalAmount: "",
    price: {},
    errors: [],
    anonymous: [],
    shipmentChoice: false,
  }),
  methods: {
    load() {
      this.board = localStorageService.getPhotoBoard();
      this.photoUrl = localStorageService.getPhotoUrl();
      this.price = localStorageService.getPrice();
      this.totalAmount = localStorageService.getAmount();
      const adresse = localStorageService.getAdresse();
      this.nom = adresse.lastName;
      this.ville = adresse.city;
      this.rue = adresse.adresse;
      this.region = adresse.region;
      this.code = adresse.postalCode;
      this.pays = adresse.country;
    },
    async submitForm(e) {
      this.errors = [];
      if (!this.nom) {
        this.errors.push("Saississez votre Nom.");
        return;
      }
      if (!this.ville) {
        this.errors.push("Saississez votre Ville.");
        return;
      }
      if (!this.region) {
        this.errors.push("Saississez votre Region.");
        return;
      }
      if (!this.rue) {
        this.errors.push("Saississez votre Rue.");
        return;
      }
      if (!this.pays) {
        this.errors.push("Saississez votre Pays.");
        return;
      }
      if (!this.code) {
        this.errors.push("Saississez votre Code Postal.");
        return;
      }
      //save adresse
      const adresse = {
        lastName: this.nom,
        firstName: "",
        city: this.ville,
        adresse: this.rue,
        region: this.region,
        country: this.pays,
        postalCode: this.code,
      };
      localStorageService.setAdresse(adresse);
      this.$router.push({
        name: "ValidationCommande",
      });
      e.preventDefault();
    },
  },
  mounted() {
    this.load();
  },
  // computed: {
  //  requiredRule() {
  //  return (v) => !!v || "Champ obligatoire";
  // },
  //  numericRule() {
  //   return (v) => /^\d+$/.test(v) || "Veuillez saisir un nombre valide";
  //  }
  // }
};
</script>
<style scoped>
.decalage {
  margin: 10%;
}
.plus {
  margin-left: 38%;
  margin-top: -10%;
}
</style>
