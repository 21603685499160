<template>
  <v-container>
    <div class="country">
      <p>{{ $t("EmailApp.title_email", language) }}</p>
    </div>
    <v-alert
      class="alert"
      v-if="errors.length"
      style="background-color: #d70000; color: white"
    >
      <ul style="display: flex; align-items: center">
        <li
          v-for="error in errors"
          :key="error.id"
          style="display: flex; align-items: center"
        >
          <v-img
            src="../../assets/images/exclamation-circle-Bold1.png"
            max-width="20"
            max-height="20"
            style="margin-right: 15px"
          ></v-img>
          <span>{{ error }}</span>
        </li>
      </ul>
    </v-alert>
    <div class="email">
      <div class="text1" style="margin-top: 16%">
        <p>{{ $t("EmailApp.email_address", language) }}</p>
      </div>
      <div class="search-input-container1">
        <v-text-field
          v-model="email"
          class="search-input1"
          type="email"
          placeholder="dominique.rheaume@gmail.com"
          outlined
          :color="isClicked ? '#2fc977' : ''"
          @focus="handleFocus"
          @blur="handleBlur"
        ></v-text-field>
      </div>
    </div>

    <div class="conf">
      <div class="text1">
        <p>{{ $t("EmailApp.email_confirmation", language) }}</p>
      </div>
      <div class="search-input-container1">
        <v-text-field
          v-model="confEmail"
          class="search-input1"
          type="email"
          placeholder="dominique.rheaume@gmail.com"
          outlined
          :color="isClicked ? '#2fc977' : ''"
          @focus="handleFocus"
          @blur="handleBlur"
        ></v-text-field>
      </div>
    </div>
    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <button class="take3" rounded dark @click="checkForm">
        <span class="text">{{ $t("EmailApp.continue", language) }}</span>
      </button>
    </div>
  </v-container>
</template>

<script>
import { localStorageService } from "@/services/localStorage.service";
import { requestService } from "@/services/request.service";
export default {
  name: "EmailApp",
  data: () => ({
    errors: [],
    email: "",
    confEmail: "",
    check: "",
    selectedOffers: [],
    price: null,
    language: "",
    isClicked: false,
  }),
  methods: {
    // Method to handle focus event
    handleFocus() {
      this.isClicked = true;
    },
    // Method to handle blur event
    handleBlur() {
      this.isClicked = false;
    },
    // Method to validate and process the form data
    checkForm(e) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.errors = [];
      const isValidEmail = emailRegex.test(this.email);
      const isValidConfirmEmail = emailRegex.test(this.confEmail);
      if (!this.email || !this.confEmail) {
        this.errors.push(this.$t("EmailApp.email_error", this.language));
        return;
      } else if (this.email !== this.confEmail) {
        this.errors.push(this.$t("EmailApp.email_error1", this.language));
        return;
      } else {
        if (isValidEmail && isValidConfirmEmail) {
          // Save data to localStorage and navigate to the next step
          localStorageService.setAmount(this.totalAmount);
          localStorageService.setEmailOwner(this.email);
          localStorageService.setSelectedOffers(this.selectedOffers);
          this.next();
        } else {
          this.errors.push(this.$t("EmailApp.email_error1", this.language));
        }
      }
      // Prevent the default form submission behavior
      e.preventDefault();
    },
    // Method to navigate to the next step
    next() {
      if (this.price.purpose.verification_needed) {
        this.$router.push({
          name: "YourPlan",
          params: { country: localStorageService.getCountryCode() },
        }); // Navigate to 'YourPlan' route if verification is needed
      } else {
        this.$router.push({
          name: "ChoosePlan",
          params: { country: localStorageService.getCountryCode() },
        }); // Navigate to 'ChoosePlan' route if verification is not needed
      }
    },
    // Method to load initial data
    load() {
      localStorageService.setStripeClientSecret(null);
      this.price = localStorageService.getPrice();
      this.language = localStorageService.getLanguage();
      var countryCode = localStorageService.getCountryCode();
      if (
        this.price === null ||
        this.price === undefined ||
        countryCode === null ||
        countryCode === undefined
      ) {
        this.$router.push({ name: "welcomeMobile" });
        return;
      }

      var photoUidDetoured = localStorageService.getPhotoUidDetoured();
      var photoUidResized = localStorageService.getPhotoUidResized();
      if (
        (photoUidDetoured === null || photoUidDetoured === undefined) &&
        (photoUidResized === null || photoUidResized === undefined)
      ) {
        this.$router.push({
          name: "TakePhotoMobile",
          params: { countryCode: countryCode, priceId: this.price.id },
        });
        return;
      }

      this.email = localStorageService.getEmailOwner();
      this.confEmail = localStorageService.getEmailOwner();

      var signatureUid = localStorageService.getSignatureUid();
      var photoboardDetoured = localStorageService.getPhotoBoardDetoured();
      var priceOPVerification = localStorageService
        .getPrice()
        .optional_offers.find((x) => x.product.verification_needed);
      if (photoboardDetoured === null || photoboardDetoured === undefined) {
        //Generate photoboard with detoured
        const PhotoUidDigitalDetoured =
          localStorageService.getPhotoUidDigitalDetoured();
        requestService
          .post("purpose/show-purpose-spec", {
            priceId:
              !this.price.purpose.verification_needed &&
              priceOPVerification !== null &&
              priceOPVerification !== undefined
                ? priceOPVerification.id
                : this.price.id,
            photoUid:
              PhotoUidDigitalDetoured !== null &&
              PhotoUidDigitalDetoured !== "null" &&
              PhotoUidDigitalDetoured !== undefined
                ? PhotoUidDigitalDetoured
                : photoUidDetoured == undefined
                ? ""
                : photoUidDetoured,
            signatureUid: signatureUid == null ? undefined : signatureUid,
          })
          .then((data) => {
            const img = data;
            localStorageService.setPhotoBoardDetoured(img.data.board);
            if (img.data.boardEphoto) {
              localStorageService.setPhotoBoardDetouredEphoto(
                img.data.boardEphoto
              );
            }
          });
      }

      //Generate photoboard without detoured
      var photoboardresized = localStorageService.getPhotoBoardResized();
      if (photoboardresized === null || photoboardresized === undefined) {
        requestService
          .post("purpose/show-purpose-spec", {
            priceId: this.price.id,
            photoUid: photoUidResized == undefined ? "" : photoUidResized,
            signatureUid: signatureUid == null ? undefined : signatureUid,
          })
          .then((data) => {
            const img = data;
            localStorageService.setPhotoBoardResized(img.data.board);
            if (img.data.boardEphoto) {
              localStorageService.setPhotoBoardResizedEphoto(
                img.data.boardEphoto
              );
            }
          });
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
@media only screen and (max-width: 700px) {
  .limit {
    margin-top: -5%;
    width: 400px;
    height: 1px;
    display: flex;
    align-content: center;
    background: #1e1e1e;

    /* Tetriary Ligjht */
  }
  .country {
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-left: 3%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take1 {
    align-content: center;
    margin-top: 10%;
  }

  .paysText {
    text-transform: capitalize;
    font-family: "Georama";
    margin-right: 25%;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    width: 350px;
    height: 52px;
    margin-top: 30%;

    /* Primary Dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }

  .search-input-container {
    display: flex;
    justify-content: center;

    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }
  .search-input-container1 {
    display: flex;
    justify-content: center;
    margin-left: 3%;
    margin-right: 5%;
    align-content: center;
    /*border: 2px solid #2fc977; */
    flex-direction: column;
  }

  .search-input-container .search-input {
    /*border: 1px solid #efefef;*/
    background: #efefef;
    width: 100%;
  }
  .search-input-container1 .search-input1 {
    /*border: 1px solid #efefef;*/

    color: black;
    width: 100%;
  }
  .v-text-field--outlined.v-input--is-focused fieldset,
  .v-text-field--outlined.v-input--has-state fieldset {
    border: 2px solid green;
  }
  .search-input-container1 .search-input1::placeholder {
    color: black;
  }

  .ronde {
    border-radius: 20%;
  }

  .conf {
    margin-top: 3%;
    font-family: "Georama";
  }
  .email {
    margin-top: 3%;
    font-family: "Georama";
  }
  .text1 {
    margin-left: 3%;
    margin-top: 2%;
    font-family: "Georama";
    font-style: normal;
    color: #4d4d4d;
    font-stretch: 96;
  }
  .text {
    /*text-transform: capitalize;*/
    color: white;
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  .mini {
    text-transform: lowercase;
  }
  .mini2 {
    text-transform: lowercase;
  }
  .take2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    width: 350px;
    height: 52px;
    margin-top: 60%;

    /* Primary Dark */

    background: white;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .text2 {
    text-transform: capitalize;
    color: #000000;
    margin-right: 250%;
  }
  .btn {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    margin-top: 30%;
    gap: 16px;
    box-shadow: 2%;

    position: absolute;
    width: 350px;
    height: 60px;
    left: 20px;
    top: 522px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .alert {
    font-family: Georama;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    width: 350px;
    height: 52px;
    margin-top: 100%;
    font-family: "Georama";

    /* Tetriary Ligjht */

    background: black;
    border-radius: 28px;
  }
  .text5 {
    text-transform: capitalize;
    color: white;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .limit {
    margin-top: -2%;
    width: 850px;
    height: 1px;
    display: flex;
    align-content: center;
    background: #1e1e1e;
  }
  .country {
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-left: 3%;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take1 {
    margin-top: 3%;
  }

  .paysText {
    text-transform: capitalize;
    font-family: "Georama";
    margin-right: 25%;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    height: 52px;
    margin-top: 20%;
    width: 500px;
    /* Primary Dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }

  .search-input-container {
    display: flex;
    justify-content: center;

    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }
  .search-input-container1 {
    display: flex;
    justify-content: center;

    margin-left: 3%;
    margin-right: 5%;
    border: 2px solid #2fc977;
    flex-direction: column;
  }

  .search-input-container .search-input {
    /* border: 1px solid #efefef;*/
    background: #efefef;
    width: 100%;
    font-size: 20px;
  }
  .search-input-container1 .search-input1 {
    /*border: 1px solid #efefef;*/

    color: black;
    width: 100%;
    font-size: 20px;
  }

  .search-input-container .search-input::placeholder {
    color: #4d4d4d;
  }
  .search-input-container1 .search-input1::placeholder {
    color: #4d4d4d;
  }

  .ronde {
    border-radius: 20%;
  }

  .conf {
    margin-top: 3%;
  }
  .email {
    margin-top: 3%;
  }
  .text1 {
    margin-left: 3%;
    font-family: "Georama";
    font-style: normal;
    color: #4d4d4d;
    font-stretch: 96;
    font-size: 25px;
  }
  .text {
    text-transform: capitalize;
    display: flex;
    color: white;
    align-content: center;
    font-size: 20px;
    font-family: "Georama";
  }
  .mini {
    text-transform: lowercase;
  }
  .mini2 {
    text-transform: lowercase;
  }
  .take2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    align-content: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    height: 52px;
    margin-top: 35%;
    width: 500px;

    /* Primary Dark */

    background: white;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .text2 {
    text-transform: capitalize;
    color: #000000;
    margin-right: 250%;
  }
  .btn {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    margin-top: 30%;
    gap: 16px;
    box-shadow: 2%;

    position: absolute;
    width: 350px;
    height: 60px;
    left: 20px;
    top: 522px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take3 {
    margin-top: 50%;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    height: 52px;
    width: 500px;
    background: black;
    border-radius: 28px;
    font-family: "Georama";
  }
  .text5 {
    text-transform: capitalize;
    color: white;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .limit {
    margin-top: -5%;
    width: 400px;
    height: 1px;
    display: flex;
    align-content: center;
    background: #1e1e1e;
  }
  .country {
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-top: 2%;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take1 {
    align-content: center;
    margin-top: 10%;
    margin-left: 50%;
  }

  .paysText {
    text-transform: capitalize;
    font-family: "Georama";
    margin-right: 25%;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    width: 350px;
    height: 52px;
    margin-top: 30%;
    margin-left: 50%;
    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }

  .search-input-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .search-input-container1 {
    display: flex;
    justify-content: center;
    align-content: center;
    border: 2px solid #2fc977;
    flex-direction: column;
    width: 140%;
  }

  .search-input-container .search-input {
    /* border: 1px solid #efefef; */
    background: #efefef;
    width: 150%;
  }
  .search-input-container1 .search-input1 {
    /*border: 1px solid #efefef; */
    color: #efefef;
  }

  .search-input-container .search-input::placeholder {
    color: #4d4d4d;
  }
  .search-input-container1 .search-input1::placeholder {
    color: #4d4d4d;
  }
  .ronde {
    border-radius: 20%;
  }

  .conf {
    margin-top: 3%;
  }
  .email {
    margin-top: 3%;
  }
  .text1 {
    margin-top: 2%;
    font-family: "Georama";
    font-style: normal;
    color: #4d4d4d;
    font-stretch: 96;
  }
  .text {
    text-transform: capitalize;
    color: white;
    font-family: "Georama";
  }
  .mini {
    text-transform: lowercase;
  }
  .mini2 {
    text-transform: lowercase;
  }
  .take2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    width: 350px;
    height: 52px;
    margin-top: 60%;
    margin-left: 50%;
    background: white;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .text2 {
    text-transform: capitalize;
    color: #000000;
    margin-right: 250%;
  }
  .btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    margin-top: 30%;
    gap: 16px;
    box-shadow: 2%;
    position: absolute;
    width: 350px;
    height: 60px;
    left: 20px;
    top: 522px;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    width: 350px;
    height: 52px;
    margin-top: 100%;
    margin-left: 50%;
    font-family: "Georama";

    /* Tetriary Ligjht */

    margin-left: 3%;
    margin-right: 5%;
    border: 2px solid #2fc977;
    flex-direction: column;
  }

  .search-input-container .search-input {
    /*border: 1px solid #efefef;*/
    background: #efefef;
    width: 100%;
  }
  .search-input-container1 .search-input1 {
    /* border: 1px solid #efefef;*/

    color: #efefef;
    width: 100%;
  }

  .search-input-container .search-input::placeholder {
    color: #4d4d4d;
  }
  .search-input-container1 .search-input1::placeholder {
    color: #4d4d4d;
  }

  .ronde {
    border-radius: 20%;
  }

  .conf {
    margin-top: 3%;
  }
  .email {
    margin-top: 3%;
  }
  .text1 {
    margin-top: 2%;
    font-family: "Georama";
    font-style: normal;
    color: #4d4d4d;
    font-stretch: 96;
    margin-left: 3%;
  }
  .text {
    text-transform: capitalize;
    color: white;
    font-family: "Georama";
  }
  .mini {
    text-transform: lowercase;
  }
  .mini2 {
    text-transform: lowercase;
  }
  .take2 {
    margin-top: 22%;
    margin-left: 4%;
    padding: 16px 125px;
    gap: 10px;

    position: absolute;
    width: 350px;
    height: 100%;
    left: 20px;
    top: 522px;

    /* Primary Dark */

    background: white;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .text2 {
    text-transform: capitalize;
    color: #000000;
    margin-right: 250%;
  }
  .btn {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    margin-top: 30%;
    gap: 16px;
    box-shadow: 2%;

    position: absolute;
    width: 350px;
    height: 60px;
    left: 20px;
    top: 522px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take3 {
    margin-top: 50%;
    background-color: black;
    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;

    position: absolute;
    width: 350px;
    height: 52px;

    /* Tetriary Ligjht */

    font-family: "Georama";
    background: black;
    border-radius: 28px;
  }
  .text5 {
    text-transform: capitalize;
    color: white;
  }
}
</style>
