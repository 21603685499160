import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fr from "vuetify/lib/locale/fr";

Vue.use(Vuetify);

const themes = {
  none: {},
  light: {
    primary: "#9f1949",
    secondary: "#002031",
    // accent: '#8c9eff',
    error: "#b71c1c",
    success: "#009688",
    background: "white",
  },
  dark: {
    primary: "#9f1949",
    secondary: "#002031",
    // accent: '#8c9eff',
    error: "#b71c1c",
    success: "#009688",
    background: "black",
  },
};

export default new Vuetify({
  theme: {
    themes: themes,
    options: { customProperties: true },
  },
  lang: {
    locales: { fr },
    current: "fr",
  },
});
