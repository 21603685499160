<template>
  <v-sheet class="d-flex justify-center pt-20" style="background: transparent">
    <v-card
      class="rounded-card pa-15"
      width="600"
      style="border-radius: 15px; text-align: center; margin-top: 100px"
    >
      <v-img
        width="73.29"
        height="72.11"
        class="mx-auto"
        src="../../assets/images/icon-success.png"
      />
      <div style="font-weight: 600; font-size: 32px">
        {{ $t("Desktop.BravoDesktop.felicitation", language) }}
      </div>
      <br />
      <div style="font-weight: 600; font-size: 17px">
        {{ $t("Desktop.BravoDesktop.text", language).replace("#email", email) }}
      </div>
      <br />
      <div style="font-style: italic; font-size: 15px; line-height: 22px">
        {{ $t("Desktop.BravoDesktop.check_spam", language) }}
      </div>
      <br />
      <hr class="line" />
      <div style="font-weight: 400; font-size: 17px; line-height: 22px">
        {{ $t("Desktop.BravoDesktop.download_app", language) }}
      </div>
      <br />
      <v-sheet class="d-flex justify-center">
        <a
          class="mr-5"
          href="https://app.adjust.com/6qr5cc1?engagement_type=fallback_click&redirect=https%3A%2F%2Fapps.apple.com%2Ffr%2Fapp%2Fphoto-identit%25C3%25A9-officielle%2Fid1527550865"
        >
          <v-img
            height="38"
            width="123"
            src="../../assets/images/AppStore@2x.png"
          />
        </a>
        <a
          class="ml-5"
          href="https://app.adjust.com/mb0xgg0?engagement_type=fallback_click&redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.smartphoneid%26hl%3Dfr%26gl%3DFR"
        >
          <v-img
            height="38"
            width="130"
            src="../../assets/images/Gstore@2x.png"
          />
        </a>
      </v-sheet>
    </v-card>
  </v-sheet>
</template>

<script>
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "BravoApp",
  data: () => ({
    language: "",
    email: "",
  }),
  mounted() {
    this.email = localStorageService.getEmailOwner();
    this.language = localStorageService.getLanguage();
    //alert(this.language);
    if (
      this.language == null ||
      this.language == undefined ||
      this.language == "null"
    ) {
      window.open(process.env.VUE_WEBSITE);
    }
    localStorageService.clearAll();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
.image-container {
  display: flex;
  justify-content: space-around; /* Pour espacement égal entre les images */
}
.line {
  border: 1px solid #2fc977;
  width: 40%;
  margin-left: 30%;
  margin-bottom: 4%;
}
.container {
  font-family: Georama;
}
</style>
