<template>
  <v-container>
    <div class="country">
      <p>{{ $t("headerMobile.title_tips", language) }}</p>
    </div>
    <v-expansion-panels class="allbtn">
      <v-expansion-panel class="suite" v-for="item in tips" :key="item.title">
        <v-expansion-panel-header>
          <div class="image">
            <v-img
              class="noir"
              :src="loadImg(item.icon)"
              width="18"
              height="18"
              cover
            ></v-img>
          </div>
          <h6 class="title1">{{ $t(item.title, language) }}</h6>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textodrer">{{ $t(item.text, language) }}</p>
          <div class="image">
            <v-img
              class="tipsimg"
              :src="loadImg(item.image)"
              width="280"
              height="274"
              cover
            ></v-img>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <button class="take2" color="white" rounded @click="loading = !loading">
        <span class="text">Retake Photo</span>
      </button>
    </div> -->

    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <button class="take" color="black" rounded @click="close">
        <span class="text5">{{ $t("headerMobile.close") }}</span>
      </button>
    </div>
  </v-container>
</template>

<script>
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "TipsApp",
  props: {
    close: {
      type: Function,
      required: true,
    },
  },
  computed: {},
  methods: {
    loadImg(img) {
      return this.images("./" + img + ".png");
    },
  },
  data: () => ({
    language: null,
    images: null,
    tips: [
      {
        title: "headerMobile.title_1",
        text: "headerMobile.text_1",
        image: "tips1",
        icon: "light-falls-evenly-on-your-face",
      },
      {
        title: "headerMobile.title_2",
        text: "headerMobile.text_2",
        image: "tips2",
        icon: "hair-behind-shoulders",
      },
      {
        title: "headerMobile.title_3",
        text: "headerMobile.text_3",
        image: "tips3",
        icon: "no-glasses",
      },
      {
        title: "headerMobile.title_4",
        text: "headerMobile.text_4",
        image: "tips4",
        icon: "netral-face-expression",
      },
      {
        title: "headerMobile.title_5",
        text: "headerMobile.text_5",
        image: "tips5",
        icon: "mouth-closed",
      },
      {
        title: "headerMobile.title_6",
        text: "headerMobile.text_6",
        image: "tips6",
        icon: "eyes-looking-into-camera",
      },
      {
        title: "headerMobile.title_7",
        text: "headerMobile.text_7",
        image: "tips7",
        icon: "head-is-straight",
      },
      {
        title: "headerMobile.title_8",
        text: "headerMobile.text_8",
        image: "tips8",
        icon: "arm-fully-extended-in-case-of selfie",
      },
    ],
  }),
  mounted() {
    this.language = localStorageService.getLanguage();
    this.images = require.context("../assets/images/", false, /\.png$/);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
@media only screen and (max-width: 700px) {
  .take2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    width: 300px;
    height: 52px;
    margin-top: 25%;
    margin-left: 2%;
    background: #ffffff;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .title1 {
    font-size: 14px;
  }
  .text5 {
    text-transform: capitalize;
    color: white;
    font-weight: bold;
  }
  .text {
    text-transform: capitalize;
    font-weight: bold;
    color: black;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    width: 300px;
    height: 52px;
    margin-top: 25px;
    margin-bottom: 25px;
    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 5%;
    margin-left: 3%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn {
    box-sizing: border-box;
    border: 1px solid #efefef;
    padding: 12px 32px 16px;
    gap: 16px;
    width: 320px;
    height: 48px;
    margin-left: 5%;
    background: #ffffff;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .image {
    margin-right: 3%;
  }
  .document {
    font-family: "Georama";
    font-style: normal;
    font-size: 16px;
    font-stretch: 96;
    font-weight: 500;
  }

  .noir {
    margin-right: 15%;
  }
  .suite {
    margin-top: 4%;
    font-family: "Georama";
  }
  .allbtn {
    margin-top: 10%;
  }
  .textodrer {
    font-family: "Georama";
    font-style: normal;
    font-weight: 360;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 98;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  }
  .tipsimg {
    margin-left: 4%;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .take2 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    width: 500px;
    height: 52px;
    margin-top: 25%;
    margin-left: 2%;
    background: #ffffff;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .title1 {
    font-size: 14px;
  }
  .text5 {
    text-transform: capitalize;
    color: white;
    font-weight: bold;
  }
  .text {
    text-transform: capitalize;
    font-weight: bold;
    color: black;
  }
  .take {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    width: 500px;
    height: 52px;
    margin-top: 25px;
    margin-bottom: 25px;
    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .country {
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 3%;
    margin-left: 3%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn {
    box-sizing: border-box;
    border: 1px solid #efefef;
    padding: 12px 32px 16px;
    gap: 16px;
    width: 700px;
    height: 48px;
    margin-left: 5%;
    background: #ffffff;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .image {
    margin-right: 3%;
  }
  .document {
    font-family: "Georama";
    font-style: normal;
    font-size: 20px;
    font-stretch: 96;
    font-weight: 500;
  }

  .noir {
    margin-right: 15%;
  }
  .suite {
    margin-top: 4%;
    font-family: "Georama";
  }
  .allbtn {
    justify-content: center;
    align-content: center;
    margin-top: 5%;
  }
  .textodrer {
    font-family: "Georama";
    font-style: normal;
    font-weight: 360;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 98;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  }
  .tipsimg {
    margin-left: 30%;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .take2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    position: absolute;
    width: 150px;
    height: 50px;
    margin-top: 25%;
    margin-left: 40%;
    background: #ffffff;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .text5 {
    text-transform: capitalize;
    color: white;
    font-weight: bold;
    font-size: 15px;
  }
  .title1 {
    font-size: 14px;
  }
  .text {
    text-transform: capitalize;
    font-weight: bold;
    color: black;
    font-size: 15px;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    width: 150px;
    height: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 10%;
    margin-left: 3%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn {
    box-sizing: border-box;
    border: 1px solid #efefef;
    padding: 12px 32px 16px;
    gap: 16px;
    width: 340px;
    height: 48px;
    background: #ffffff;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .image {
    margin-right: 3%;
  }
  .document {
    font-family: "Georama";
    font-style: normal;
    font-size: 16px;
    font-stretch: 96;
    font-weight: 500;
  }
  .noir {
    margin-right: 15%;
  }
  .suite {
    margin-top: 4%;
    font-family: "Georama";
  }
  .allbtn {
    margin-left: 20%;
    margin-top: 5%;
  }
  .textodrer {
    font-family: "Georama";
    font-style: normal;
    font-weight: 360;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 98;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  }
  .tipsimg {
    margin-left: 8%;
  }
}
</style>
