import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import browserDetect from "vue-browser-detect-plugin";

import "vuetify/dist/vuetify.css";

import MobileLayout from "./layouts/Mobile.vue";
import DesktopLayout from "./layouts/Desktop.vue";
import ContentOnlyLayout from "./layouts/ContentOnly.vue";
import i18n from "./i18n";

Vue.component("mobile-layout", MobileLayout);
Vue.component("desktop-layout", DesktopLayout);
Vue.component("content-only-layout", ContentOnlyLayout);

Vue.use(browserDetect);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
