<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <!-- product and country -->
        <v-row class="productPanel mx-0" style="margin-bottom: 2%">
          <v-col cols="2" class="d-flex align-center">
            <v-img
              width="24"
              max-width="24"
              height="24"
              style="border-radius: 5px"
              class="vector align-self-auto"
              :src="loadImg(countryCode)"
            ></v-img>
            <span class="ml-2 fontStyle15">{{ pays }}</span>
          </v-col>
          <div class="vertical-hr"></div>
          <v-col cols="4" class="d-flex align-center single-line">
            <v-img
              width="24"
              max-width="24"
              height="24"
              style="border-radius: 5px"
              class="vector align-self-auto"
              :src="loadImg(countryCode)"
            ></v-img>
            <span class="ml-2 fontStyle15">{{ document }}</span>
          </v-col>
        </v-row>
        <!-- end product and country -->

        <v-sheet>
          <v-card
            style="
              background-color: #efefef;
              border-radius: 10px;
              display: flex; /* Ajout de la propriété display: flex */
            "
            class="pa-5"
          >
            <v-row>
              <v-col cols="4">
                <!-- photo -->
                <div style="display: flex; align-items: center">
                  <img
                    class="noir"
                    :src="
                      pricePhoto.purpose.verification_needed == false &&
                      priceOPVerification == null &&
                      priceOPVerification == undefined
                        ? `data:image/png;base64,${photoResized}`
                        : `data:image/png;base64,${photo}`
                    "
                    height="200"
                    object-fit="contain"
                  />
                </div>
                <!-- end photo display -->
              </v-col>
              <v-col cols="1" style="display: flex; align-items: center">
              </v-col>
              <v-col cols="auto" style="display: flex; align-items: left">
                <!-- photoboard -->
                <v-img
                  v-if="
                    boardEphotoDetoured !== null ||
                    boardResized !== null ||
                    boardDetoured !== null
                  "
                  width="310"
                  height="200"
                  :aspect-ratio="1"
                  :src="
                    boardEphotoDetoured !== null &&
                    boardEphotoDetoured.base64 != null
                      ? `data:image/png;base64,${boardEphotoDetoured.base64}`
                      : pricePhoto.purpose.verification_needed == false &&
                        priceOPVerification == null &&
                        priceOPVerification == undefined
                      ? `data:image/png;base64,${
                          boardResized !== null && boardResized.base64 !== null
                            ? boardResized.base64
                            : ''
                        }`
                      : `data:image/png;base64,${
                          boardDetoured !== null &&
                          boardDetoured.base64 !== null
                            ? boardDetoured.base64
                            : ''
                        }`
                  "
                  cover
                ></v-img>
                <!-- end board display -->
              </v-col>
            </v-row>
          </v-card>

          <!-- for information  -->
          <v-alert
            type="info"
            icon="mdi-alert-circle"
            class="text-body-2"
            color="#646464"
            width="60%"
            style="margin-top: 2%; border-radius: 10px"
          >
            <span class="fontStyle15">{{
              $t("Desktop.yourPlanDesktop.info_preview", language)
            }}</span>
          </v-alert>
          <!-- end information alert  -->
        </v-sheet>
      </v-col>
      <v-col cols="6">
        <!-- Display error if email missing -->
        <v-alert
          class="alert"
          v-if="errors.length"
          style="background-color: #d70000; color: white"
        >
          <ul style="display: flex; align-items: center">
            <li
              v-for="error in errors"
              :key="error.id"
              style="display: flex; align-items: center"
            >
              <v-img
                src="../../assets/images/exclamation-circle-Bold1.png"
                max-width="20"
                max-height="20"
                style="margin-right: 15px"
              ></v-img>
              <span>{{ error }}</span>
            </li>
          </ul>
        </v-alert>
        <!-- End display error if email missing -->

        <v-sheet>
          <v-card
            style="background-color: #efefef; border-radius: 10px"
            class="pa-5"
            ><span class="fontStyle22">{{
              $t("Desktop.yourPlanDesktop.you_receive", language)
            }}</span>
            <v-sheet style="background-color: #efefef" class="mt-5 mb-3"
              ><v-icon
                style="width: 20px; height: 20px; font-size: 15px"
                color="black"
                class="icon"
                >mdi-check</v-icon
              ><span class="fontStyle15">{{
                $t(
                  "Desktop.yourPlanDesktop.Receive_immediately_in_e-mail",
                  language
                )
              }}</span>
            </v-sheet>
            <v-sheet style="background-color: #efefef" class="mb-5"
              ><v-icon
                style="width: 20px; height: 20px; font-size: 15px"
                color="black"
                class="icon"
                >mdi-check</v-icon
              ><span class="fontStyle15">{{
                $t("Desktop.yourPlanDesktop.Printable_template", language)
              }}</span>
            </v-sheet>

            <div class="email">
              <!-- email form -->
              <v-row>
                <v-col>
                  <span class="fontStyle13">
                    {{ $t("Desktop.yourPlanDesktop.email_address", language) }}*
                  </span>
                  <div class="search-input-container1">
                    <v-text-field
                      v-model="email"
                      class="search-input1 fontStyle15"
                      type="email"
                      placeholder="dominique.rheaume@gmail.com"
                      outlined
                      :color="isClicked ? '#2fc977' : ''"
                      @focus="handleFocus"
                      @blur="handleBlur"
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col>
                  <span class="fontStyle13">
                    {{
                      $t(
                        "Desktop.yourPlanDesktop.email_confirmation",
                        language
                      )
                    }}*
                  </span>
                  <div class="search-input-container1">
                    <v-text-field
                      v-model="confEmail"
                      class="search-input1 fontStyle15"
                      type="email"
                      placeholder="dominique.rheaume@gmail.com"
                      outlined
                      :color="isClicked ? '#2fc977' : ''"
                      @focus="handleFocus"
                      @blur="handleBlur"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <!-- end email form -->

              <div class="plan">
                <v-sheet
                  style="background-color: #efefef"
                  class=""
                  v-if="
                    pricePhoto.purpose.ephoto_needed && countryCode === 'fr'
                  "
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="fontStyle15">{{
                    $t("Desktop.yourPlanDesktop.code_ants", language)
                  }}</span>
                </v-sheet>
                <v-sheet
                  style="background-color: #efefef"
                  class=""
                  v-if="
                    pricePhoto.purpose.ephoto_needed && countryCode === 'gb'
                  "
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="fontStyle15">{{
                    $t("Desktop.yourPlanDesktop.uk_code_long", language)
                  }}</span>
                </v-sheet>
                <v-sheet style="background-color: #efefef" class=""
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="fontStyle15">{{
                    $t(
                      "Desktop.yourPlanDesktop.Compliance_guarantee_desktop",
                      language
                    )
                  }}</span>
                </v-sheet>
                <v-sheet style="background-color: #efefef" class=""
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="fontStyle15">{{
                    $t("Desktop.yourPlanDesktop.Background_removal", language)
                  }}</span>
                </v-sheet>
                <v-sheet style="background-color: #efefef" class=""
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="fontStyle15">{{
                    $t(
                      "Desktop.yourPlanDesktop.Photo_check_by_trained_expert",
                      language
                    )
                  }}</span>
                </v-sheet>
                <v-sheet style="background-color: #efefef" class="mb-5"
                  ><v-icon
                    style="width: 20px; height: 20px; font-size: 20px"
                    color="#2fc977"
                    class="icon-green"
                    >mdi-check-circle-outline</v-icon
                  ><span class="fontStyle15">{{
                    $t("Desktop.yourPlanDesktop.Adjustments", language)
                  }}</span>
                </v-sheet>
              </div>
            </div>

            <hr class="separator" />
            <section>
              <h4
                style="margin-top: 3%; margin-bottom: 5%; margin-left: 1%; fontStyle15"
              >
                {{ $t("Desktop.yourPlanDesktop.title_print", language) }}
              </h4>

              <v-row class="textarea">
                <v-col class="d-flex flex-row">
                  <!-- Add a class to target the checkbox and apply custom styles -->
                  <v-checkbox
                    v-model="ex4"
                    hide-details
                    class="mb-4"
                    color="#2fc977"
                  ></v-checkbox>
                  <span class="mt-5 fontStyle15">
                    {{
                      $t("Desktop.yourPlanDesktop.photoQualityPrint", language)
                    }}
                  </span>
                </v-col>
              </v-row>
            </section>

            <div class="d-flex justify-space-around">
              <v-btn
                v-if="goBack"
                @click="goBackAction"
                style="
                  margin-left: 40%;
                  border: none;
                  background-color: transparent;
                  color: black;
                "
                text
              >
                <v-icon>mdi-chevron-left</v-icon>
                <span
                  class="px-3 rounded-pill bottom fontStyle15"
                  style="text-decoration: underline"
                  >{{ $t("Desktop.yourPlanDesktop.back", language) }}</span
                >
              </v-btn>
              <v-btn
                style="background-color: black; color: white; font-weight: bold"
                class="px-10 rounded-pill bottom fontStyle15"
                @click="checkForm"
                >{{ $t("Desktop.yourPlanDesktop.continue", language) }}</v-btn
              >
            </div>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row no-gutters> </v-row>
  </v-container>
</template>

<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
//import { isMobile } from "mobile-device-detect";
export default {
  name: "TraitePhoto",
  data: () => ({
    selectedOffers: [],
    shipmentPanel: [],
    verificationPanel: [],
    processingPanel: {},
    totalAmount: 0.0,
    currency: "EUR",
    signatureUid: null,
    priceId: null,
    board: null,
    photoUrl: null,
    pricePhoto: null,
    errors: [],
    email: "",
    confEmail: "",
    check: "",
    purpose: null,
    countryCode: localStorageService
      .getPrice()
      .use_in_country.country_code.toLowerCase(),
    allowNavigation: false,
    isClicked: false,
    pays: localStorage.getItem("selectedCountry"),
    document: localStorage.getItem("selectedProduct"),
    images: [],
    countryFlag: "",
    boardDetoured: {},
    priceOPVerification: null,
    boardEphotoDetoured: null,
    boardResized: null,
    boardEphotoResized: null,
    photoUid: null,
    photoUidResized: null,
    photoResized: null,
    photo: null,
    ex4: true,
    goBack: true,
  }),
  methods: {
    goBackAction() {
      this.$router.back();
      document.activeElement.blur();
    },
    next() {
      if (this.ex4) {
        this.$router.push({ name: "AddressLivraisonDesk" });
      } else {
        this.$router.push({ name: "RecapOrder" });
      }
    },
    loadImg(img) {
      return require(`../../assets/images/Drapeaux/${img}.jpg`);
    },
    handleFocus() {
      this.isClicked = true;
    },
    handleBlur() {
      this.isClicked = false;
    },
    checkForm(e) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.errors = [];
      const isValidEmail = emailRegex.test(this.email);
      const isValidConfirmEmail = emailRegex.test(this.confEmail);
      if (!this.email || !this.confEmail) {
        this.errors.push(this.$t("EmailApp.email_error", this.language));
        return;
      } else if (this.email !== this.confEmail) {
        this.errors.push(this.$t("EmailApp.email_error1", this.language));
        return;
      } else {
        if (isValidEmail && isValidConfirmEmail) {
          localStorageService.setAmount(this.totalAmount);
          localStorageService.setEmailOwner(this.email);
          localStorageService.setSelectedOffers(this.selectedOffers);
          this.next();
        } else {
          this.errors.push(this.$t("EmailApp.email_error1", this.language));
        }
      }
      e.preventDefault();
    },
    load() {
      //alert(JSON.stringify(localStorageService.getPrice()));
      this.countryFlag = "";
      //this.countryCode = localStorageService.getCountryCode().toLowerCase();
      this.photoUid = null;
      this.signatureUid = null;
      this.totalAmount = 0.0;
      this.selectedOffers = [];
      this.priceOPVerification =
        localStorageService.getPriceOPOfferVerification();
      this.pricePhoto = localStorageService.getPrice();
      this.refreshAmount();

      this.email = localStorageService.getEmailOwner();
      this.confEmail = localStorageService.getEmailOwner();
      var signatureUid = localStorageService.getSignatureUid();
      var photoboardDetoured = localStorageService.getPhotoBoardDetoured();
      var photoUidDetoured = localStorageService.getPhotoUidDetoured();
      this.language = localStorageService.getLanguage();
      //this.loadImg(this.countryCode.toLowerCase());
      this.countryFlag =
        "../../assets/images/Drapeaux/" + this.countryCode + ".jpg";
      //this.language = localStorageService.getLanguage();
      this.images = require.context(
        "../../assets/images/Drapeaux/",
        false,
        /\.jpg$/
      );
      var priceOPVerification = localStorageService
        .getPrice()
        .optional_offers.find((x) => x.product.verification_needed);
      if (photoboardDetoured === null || photoboardDetoured === undefined) {
        //Generate photoboard with detoured
        const PhotoUidDigitalDetoured =
          localStorageService.getPhotoUidDigitalDetoured();
        requestService
          .post("purpose/show-purpose-spec", {
            priceId:
              !this.pricePhoto.purpose.verification_needed &&
              priceOPVerification !== null &&
              priceOPVerification !== undefined
                ? priceOPVerification.id
                : this.pricePhoto.id,
            photoUid:
              PhotoUidDigitalDetoured !== null &&
              PhotoUidDigitalDetoured !== "null" &&
              PhotoUidDigitalDetoured !== undefined
                ? PhotoUidDigitalDetoured
                : photoUidDetoured == undefined
                ? ""
                : photoUidDetoured,
            signatureUid: signatureUid == null ? undefined : signatureUid,
          })
          .then((data) => {
            const img = data;
            this.boardDetoured = img.data.board;
            localStorageService.setPhotoBoardDetoured(img.data.board);
            if (img.data.boardEphoto && img.data.boardEphoto.base64 !== null) {
              localStorageService.setPhotoBoardDetouredEphoto(
                img.data.boardEphoto
              );
              this.boardEphotoDetoured = img.data.boardEphoto;
            }
          });
      } else {
        this.boardDetoured = localStorageService.getPhotoBoardDetoured();
        this.boardEphotoDetoured =
          localStorageService.getPhotoBoardDetouredEphoto();
      }

      //Generate photoboard without detoured
      var photoUidResized = localStorageService.getPhotoUidResized();
      var photoboardresized = localStorageService.getPhotoBoardResized();
      if (photoboardresized === null || photoboardresized === undefined) {
        requestService
          .post("purpose/show-purpose-spec", {
            priceId: this.pricePhoto.id,
            photoUid: photoUidResized == undefined ? "" : photoUidResized,
            signatureUid: signatureUid == null ? undefined : signatureUid,
          })
          .then((data) => {
            const img = data;
            localStorageService.setPhotoBoardResized(img.data.board);
            this.boardResized = img.data.board;
            if (img.data.boardEphoto) {
              localStorageService.setPhotoBoardResizedEphoto(
                img.data.boardEphoto
              );
              this.boardEphotoResized = img.data.boardEphoto;
            }
          });
      } else {
        this.boardResized = localStorageService.getPhotoBoardResized();
        this.boardEphotoResized =
          localStorageService.getPhotoBoardResizedEphoto();
      }

      this.photo = localStorageService.getPhotoBase64Detoured();
      this.photoResized = localStorageService.getPhotoBase64Resize();
    },
    /* async showPlanche() {
      try {
        this.photoUrl = localStorageService.getPhotoUrl();
        this.board = localStorageService.getPhotoBoard();
        if (this.board == null) {
          this.photoUid = localStorageService.getPhotoUid();
          this.signatureUid = localStorageService.getSignatureUid();
          requestService
            .post("purpose/show-purpose-spec", {
              priceId: this.pricePhoto.id,
              photoUid: this.photoUid == undefined ? "" : this.photoUid,
              signatureUid:
                this.signatureUid == null ? undefined : this.signatureUid,
            })
            .then((data) => {
              const img = data;
              if (img.data.boardEphoto) {
                this.board = img.data.boardEphoto;
                localStorageService.setPhotoBoard(this.board);
              } else {
                this.board = img.data.board;
                localStorageService.setPhotoBoard(this.board);
              }
            });
        }
      } catch (error) {
        console.error(error);
      }
    },*/
    async refreshAmount() {
      this.totalAmount = Number(
        this.price !== null && this.pricePhoto.amount !== null
          ? this.pricePhoto.amount
          : 0
      );
      this.selectedOffers.forEach(
        (x) => (this.totalAmount += Number(x.amount))
      );
    },
  },
  mounted() {
    this.load();
    //alert(this.boardDetoured.base64);
    //this.showPlanche();
    localStorage.getItem("photoUid", this.photoUid);
    localStorage.getItem("signatureUid", this.signatureUid);
    this.email = localStorageService.getEmailOwner();
    this.confEmail = this.email;
    /* selectedOffersStored = localStorageService.getSelectedOffers();
    this.selectedOffers = selectedOffersStored !== null ?  : []; */
    this.refreshAmount();
  },
};
</script>
<style scoped>
.andicap > .noir {
  margin-left: 10%;
  margin-top: -60px;
  margin-bottom: 10px;
}
.andicap {
  margin-left: 70%;
}
.vertical-hr {
  border: none;
  border-left: 1px solid #efefef;
  height: 50px; /* Définissez la hauteur souhaitée */
  margin: 0 70px; /* Ajoutez des marges si nécessaire */
}
.icon {
  margin-right: 10px;
  margin-left: 2px;
  color: #000000;
  border: 2px solid white;
  border-radius: 50%;
  background-color: white;
}
.icon-green {
  margin-right: 10px;
  margin-left: 2px;
  border-radius: 50%;
}

.bottom {
  text-transform: none;
}
.search-input-container1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.textarea {
  border: 1px solid black;
  border-radius: 5px;
  width: 75%;
  margin-bottom: 5%;
  margin-left: 1%;
}
.textarea1 {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  margin-top: 2%;
  width: 80%;
}
.productPanel {
  border: 1px solid #efefef;
  background: white;
  border-radius: 5px;
  padding: 5px;
  margin-top: 2%;
  width: 97%;
}
.single-line {
  white-space: nowrap;
}
.container {
  position: relative;
  font-family: Georama;
}
.separator {
  margin-top: 5%;
  border: none;
  height: 1px;
  background-color: #c6c6c6;
}
.plan {
  margin-left: 1%;
}
.list {
  margin-left: -20px;
}
.left3 {
  margin-left: 3%;
}
.left {
  margin-left: 2%;
}
.planche {
  margin-left: 40%;
  margin-top: -30%;
}
.planche1 {
  margin-top: 30%;
}
.emp2 {
  margin-left: 8%;
  color: green;
}
.emp {
  margin-left: 40%;
}
.pieds > .hk {
  margin-left: 30px;
  margin-top: -20px;
  margin-bottom: 15px;
}
.info {
  margin-left: 15%;
}
.info1 {
  margin-left: 15%;
}
.info2 {
  margin-left: 15%;
}
.plus {
  font-weight: bold;
  font-size: 24px;
}
.emp1 {
  margin-left: 12%;
}
.box {
  font-size: 10px;
}
.fontStyle15 {
  font-size: 17px;
  line-height: 19.91px;
  font-weight: 400;
  font-family: Georama;
}
.fontStyle22 {
  font-size: 22px;
  line-height: 26.27px;
  font-weight: 600;
  font-family: Georama;
}
.fontStyle13 {
  font-size: 15px;
  line-height: 15.52px;
  font-weight: 400;
  font-family: Georama;
  color: #4c4c4c;
  margin-bottom: 20px;
}
</style>
