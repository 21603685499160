<template>
  <v-sheet class="pa-4">
    <v-sheet class="text-css text-start"> {{ text }}</v-sheet>
    <v-sheet class="d-flex justify-center mt-4">
      <button class="btn" @click="close">{{ buttonClose }}</button>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "AlertMessage1",
  props: {
    text: {
      type: String,
      required: true,
    },
    buttonClose: {
      type: String,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
.text-css {
  /*text-transform: lowercase;*/
  font-family: "Georama";
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;

  /* White */

  color: black;

  /* Inside auto layout */
}
.btn {
  align-content: center;
  box-sizing: border-box;
  border: 1px solid #efefef;
  align-self: center;
  /* Auto layout */

  padding: 12px 32px 16px;
  gap: 16px;

  /* White */
  color: #efefef;
  /* Tetriary Ligjht */

  border-radius: 10%;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  width: 300px;
  height: 52px;

  /* Tetriary Ligjht */

  background: black;
  border-radius: 28px;
  font-family: "Georama";
}
</style>
