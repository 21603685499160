<template>
  <v-container>
    <div class="country">
      <p>{{ $t("yourOrderDetailMobile.title_summary", language) }}</p>
      <v-card outlined class="btn d-flex flex-row pt-3">
        <div class="">
          <div>
            <v-img
              class="noir"
              :src="
                pricePhoto.purpose.verification_needed == false &&
                priceOPVerification == null &&
                priceOPVerification == undefined
                  ? `data:image/png;base64,${photoResized}`
                  : `data:image/png;base64,${photo}`
              "
              :width="imageWidth"
              :height="imageHeight"
              cover
            ></v-img>
          </div>
          <v-img
            width="113"
            class="planche"
            height="76.2"
            :aspect-ratio="1"
            :src="
              !(
                pricePrint != null &&
                pricePrint != undefined &&
                boardPrintCount != null &&
                boardPrintCount != undefined &&
                boardPrintCount != '0'
              ) && boardEphotoDetoured.base64 != null
                ? `data:image/png;base64,${boardEphotoDetoured.base64}`
                : pricePhoto.purpose.verification_needed == false &&
                  priceOPVerification == null &&
                  priceOPVerification == undefined
                ? `data:image/png;base64,${boardResized.base64}`
                : `data:image/png;base64,${boardDetoured.base64}`
            "
            cover
          ></v-img>
        </div>
        <div class="">
          <v-container class="pa-0 ma-0">
            <v-row no-gutters class="flex-nowrap bg-surface-variant mb-4">
              <v-col class="flex-grow-0 flex-shrink-0 mt-1"
                ><v-img
                  class="vector align-self-auto"
                  :src="loadImg(countryCode)"
                ></v-img
              ></v-col>
              <v-col
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
                ><h6 class="document">{{ selectedCounty }}</h6></v-col
              >
            </v-row>
            <v-row no-gutters class="flex-nowrap bg-surface-variant mt-4 mb-4">
              <v-col class="flex-grow-0 flex-shrink-0 mt-1"
                ><v-img
                  class="pass"
                  src="../../assets/images/printer.png"
                ></v-img
              ></v-col>
              <v-col
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
                ><h6 class="document">{{ selectedProduct }}</h6></v-col
              >
            </v-row>
            <v-row
              no-gutters
              class="flex-nowrap bg-surface-variant mt-4 mb-4"
              v-if="
                priceOPVerification != null && priceOPVerification != undefined
              "
            >
              <v-col class="flex-grow-0 flex-shrink-0 mt-1"
                ><v-img
                  class="pass"
                  src="../../assets/images/meilleur_choix.png"
                ></v-img
              ></v-col>
              <v-col
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
                ><h6 class="document">
                  {{ $t("yourOrderDetailMobile.plan_summary", language) }}
                </h6></v-col
              >
            </v-row>
            <v-row
              no-gutters
              class="flex-nowrap bg-surface-variant mt-4 mb-4"
              v-if="
                pricePhoto.purpose.verification_needed == true ||
                (priceOPVerification != null &&
                  priceOPVerification != undefined)
              "
            >
              <v-col class="flex-grow-0 flex-shrink-0 mt-1"
                ><v-img
                  class="pass"
                  src="../../assets/images/camera.png"
                ></v-img
              ></v-col>
              <v-col
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
                ><h6 class="document">
                  {{ $t("yourOrderDetailMobile.verified_photo", language) }}
                </h6></v-col
              >
            </v-row>
            <v-row
              no-gutters
              class="flex-nowrap bg-surface-variant mt-4 mb-4"
              v-if="
                !(
                  pricePhoto.purpose.verification_needed == true ||
                  (priceOPVerification != null &&
                    priceOPVerification != undefined)
                )
              "
            >
              <v-col class="flex-grow-0 flex-shrink-0 mt-1"
                ><v-img
                  class="pass"
                  src="../../assets/images/circle.png"
                ></v-img
              ></v-col>
              <v-col
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
                ><h6 class="document">
                  {{ $t("yourOrderDetailMobile.free_plan_summary", language) }}
                </h6></v-col
              >
            </v-row>
            <v-row
              no-gutters
              class="flex-nowrap bg-surface-variant mt-4 mb-4"
              v-if="
                !(
                  pricePhoto.purpose.verification_needed == true ||
                  (priceOPVerification != null &&
                    priceOPVerification != undefined)
                )
              "
            >
              <v-col class="flex-grow-0 flex-shrink-0 mt-1"
                ><v-img
                  class="pass"
                  src="../../assets/images/camera.png"
                ></v-img
              ></v-col>
              <v-col
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
                ><h6 class="document">
                  1x
                  {{ $t("yourOrderDetailMobile.unverified_photo1", language) }}
                </h6></v-col
              >
            </v-row>

            <v-row
              no-gutters
              class="flex-nowrap bg-surface-variant mt-4 mb-4"
              v-if="
                boardEphotoDetoured != null &&
                boardEphotoDetoured != undefined &&
                boardEphotoDetoured.base64 != null &&
                boardEphotoDetoured.base64 != undefined
              "
            >
              <v-col class="flex-grow-0 flex-shrink-0 mt-1"
                ><v-img
                  class="pass"
                  src="../../assets/images/ePhoto_Code.png"
                ></v-img
              ></v-col>
              <v-col
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
              >
                <h6 class="document">
                  {{
                    pricePhoto.use_in_country.country_code == "FR"
                      ? $t("yourOrderDetailMobile.ephoto_code", language)
                      : $t("yourOrderDetailMobile.uk_code", language)
                  }}
                </h6>
              </v-col>
            </v-row>

            <v-row
              no-gutters
              class="flex-nowrap bg-surface-variant mt-4 mb-4"
              v-if="
                pricePrint != null &&
                pricePrint != undefined &&
                boardPrintCount != null &&
                boardPrintCount != undefined &&
                boardPrintCount != '0'
              "
            >
              <v-col class="flex-grow-0 flex-shrink-0 mt-1"
                ><v-img
                  class="pass"
                  src="../../assets/images/printer.png"
                ></v-img
              ></v-col>
              <v-col
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
                ><h6 class="document">
                  <!--{{
                    Number(
                      boardPrintCount != null && boardPrintCount != undefined
                        ? boardPrintCount
                        : 0
                    )
                  }}x-->
                  {{ $t("yourOrderDetailMobile.print_summary", language) }}
                </h6>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="flex-nowrap bg-surface-variant mt-4 mb-4"
              v-if="
                pricePrint != null &&
                pricePrint != undefined &&
                boardEphotoPrintCount != null &&
                boardEphotoPrintCount != undefined &&
                boardEphotoPrintCount != '0'
              "
            >
              <v-col class="flex-grow-0 flex-shrink-0 mt-1"
                ><v-img
                  class="pass"
                  src="../../assets/images/printer.png"
                ></v-img
              ></v-col>
              <v-col
                style="min-width: 100px; max-width: 100%"
                class="flex-grow-1 flex-shrink-0"
              >
                <h6 class="document">
                  <!--{{
                    Number(
                      boardEphotoPrintCount != null &&
                        boardEphotoPrintCount != undefined
                        ? boardEphotoPrintCount
                        : 0
                    )
                  }}x -->{{
                    $t("yourOrderDetailMobile.print_summary", language)
                  }}
                </h6>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </div>
    <!-- <div class="text-center" v-if="progress" style="margin-top: 5%">
      <v-progress-circular
        :size="89"
        :width="10"
        indeterminate
        color="#2fc977"
      ></v-progress-circular>
    </div> -->
    <div>
      <div
        class="d-flex justify-space-around"
        v-if="
          pricePhoto.purpose.verification_needed == false &&
          priceOPVerification == null &&
          priceOPVerification == undefined &&
          pricePrint != null &&
          (boardPrintCount == null ||
            boardPrintCount == undefined ||
            boardPrintCount == '0')
        "
      >
        <div class="text-center" style="font-family: Georama">
          <v-dialog v-model="dialogBasic" width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="
                  margin-top: 10%;
                  background-color: #000000;
                  color: white;
                  text-transform: none;
                "
                class="pa-4 text-center rounded-pill"
                v-bind="attrs"
                v-on="on"
                >{{ $t("yourOrderDetailMobile.receive_email", language) }}
              </v-btn>
            </template>
            <v-card>
              <v-card-text style="text-align: center">
                <p style="padding-top: 5%; font-family: Georama">
                  {{ $t("headerMobile.pop_up", language) }}<br />
                  <span
                    ><p>
                      {{ $t("headerMobile.pop_up_sure", language) }}
                    </p></span
                  >
                </p>
              </v-card-text>
              <v-card-actions style="margin-right: 20%">
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  style="
                    background-color: #000000;
                    color: white;
                    font-family: Georama;
                    text-transform: none;
                  "
                  variant="text"
                  @click="redirect"
                >
                  {{ $t("headerMobile.pop_up_no", language) }}
                </v-btn>
                <v-btn
                  rounded
                  style="
                    background-color: #000000;
                    color: white;
                    margin-left: 20%;
                    font-family: Georama;
                    text-transform: none;
                  "
                  variant="text"
                  @click="generatePaymentIntent"
                >
                  {{ $t("headerMobile.pop_up_yes", language) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div v-else>
        <div class="conf" v-if="promo == null || promo == undefined">
          <div class="search-input-container">
            <v-text-field
              class="search-input"
              type="text"
              :placeholder="$t('yourOrderDetailMobile.codePromo', language)"
              v-model="codePromo"
              outlined
              :color="isClicked ? '#2fc977' : ''"
              @focus="handleFocus"
              @blur="handleBlur"
            ></v-text-field>
          </div>
        </div>
        <div
          class="d-flex justify-space-around align-center flex-column flex-sm-row"
          v-if="promo == null || promo == undefined"
        >
          <button
            class="take1"
            color="white"
            rounded
            @click="appliquerCodePromo"
          >
            <span class="text5">{{
              $t("yourOrderDetailMobile.apply", language)
            }}</span>
          </button>
        </div>
        <v-card variant="outlined" class="cadre">
          <div class="cadre1">
            <div v-if="pricePhoto.purpose.verification_needed">
              <div>
                <p class="number2">
                  {{ $t("yourOrderDetailMobile.verified_photo1", language) }}
                </p>
              </div>
              <div>
                <p class="number4">
                  {{ pricePhoto.amount_in_local_currency }}
                  {{ pricePhoto.local_currency_symbol }}
                </p>
              </div>
            </div>
            <div
              v-else-if="
                priceOPVerification != null && priceOPVerification != undefined
              "
            >
              <div>
                <p class="number2">
                  {{ $t("yourOrderDetailMobile.package", language) }}
                </p>
              </div>
              <div>
                <p class="number4">
                  {{ priceOPVerification.amount_in_local_currency }}
                  {{ priceOPVerification.local_currency_symbol }}
                </p>
              </div>
            </div>
            <div v-else>
              <div>
                <p class="number">
                  {{ $t("yourOrderDetailMobile.basic_package", language) }}
                </p>
              </div>
              <div>
                <p class="number4">
                  {{ pricePhoto.amount_in_local_currency }}
                  {{ pricePhoto.local_currency_symbol }}
                </p>
              </div>
            </div>
            <div
              v-if="
                pricePrint != null &&
                pricePrint != undefined &&
                ((boardPrintCount != null &&
                  boardPrintCount != undefined &&
                  boardPrintCount != '0') ||
                  (boardEphotoPrintCount != null &&
                    boardEphotoPrintCount != undefined &&
                    boardEphotoPrintCount != '0'))
              "
            >
              <div>
                <p class="numberQ">
                  {{ $t("yourOrderDetailMobile.print_summary1", language) }}
                </p>
              </div>
              <div>
                <p class="number4">
                  {{
                    Number(
                      pricePrint !== null && pricePrint !== undefined
                        ? pricePrint.amount_in_local_currency
                        : 0
                    ) *
                    (Number(
                      boardPrintCount != null && boardPrintCount != undefined
                        ? boardPrintCount
                        : 0
                    ) +
                      Number(
                        boardEphotoPrintCount != null &&
                          boardEphotoPrintCount != undefined
                          ? boardEphotoPrintCount
                          : 0
                      ))
                  }}
                  {{ pricePrint.local_currency_symbol }}
                </p>
              </div>
            </div>
            <div v-if="promo !== null && promo !== undefined">
              <p class="number2">
                {{ $t("yourOrderDetailMobile.discount_code", language) }}
              </p>
            </div>
            <div v-if="promo !== null && promo !== undefined">
              <p class="number4">
                {{
                  promo.amount_in_local_currency > total
                    ? total
                    : promo.amount_in_local_currency
                }}
                {{ promo.local_currency_symbol }}
              </p>
            </div>
            <hr class="line" />
            <div>
              <p class="total">
                {{ $t("yourOrderDetailMobile.total", language) }}
              </p>
            </div>
            <div>
              <p class="number3">
                {{
                  promo != null && promo != undefined
                    ? promo.amount_in_local_currency >= total
                      ? 0
                      : total - promo.amount_in_local_currency
                    : total
                }}
                {{ pricePhoto.local_currency_symbol }}
              </p>
            </div>
          </div>
        </v-card>
        <v-alert
          v-if="erreurPromo"
          type="error"
          style="background-color: #d70000; color: white"
        >
          {{ $t("yourOrderDetailMobile.codePromo_error", language) }}
        </v-alert>

        <div
          class="d-flex justify-space-around align-center flex-column flex-sm-row"
          style="font-family: Georama"
        >
          <div
            v-if="
              pricePhoto.purpose.verification_needed == false &&
              priceOPVerification == null &&
              priceOPVerification == undefined
            "
          >
            <v-dialog v-model="dialogBasic" width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="
                    margin-top: 10%;
                    background-color: #000000;
                    color: white;
                    font-family: Georama;
                    text-transform: none;
                  "
                  class="pa-4 text-center rounded-pill"
                  v-bind="attrs"
                  v-on="on"
                  >{{ $t("yourOrderDetailMobile.receive_email", language) }}
                </v-btn>
              </template>
              <v-card>
                <v-card-text style="text-align: center">
                  <p style="padding-top: 5%; font-family: Georama">
                    {{ $t("headerMobile.pop_up", language) }}<br />
                    <span
                      ><p>
                        {{ $t("headerMobile.pop_up_sure", language) }}
                      </p></span
                    >
                  </p>
                </v-card-text>
                <v-card-actions style="margin-right: 20%">
                  <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    style="
                      background-color: #000000;
                      color: white;
                      font-family: Georama;
                      text-transform: none;
                    "
                    variant="text"
                    @click="redirect"
                  >
                    {{ $t("headerMobile.pop_up_no", language) }}
                  </v-btn>
                  <v-btn
                    rounded
                    style="
                      background-color: #000000;
                      color: white;
                      margin-left: 20%;
                      font-family: Georama;
                      text-transform: none;
                    "
                    variant="text"
                    @click="generatePaymentIntent"
                  >
                    {{ $t("headerMobile.pop_up_yes", language) }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div v-else>
            <button
              class="take3"
              color="black"
              rounded
              @click="generatePaymentIntent"
            >
              <span class="textpl">{{
                $t("yourOrderDetailMobile.pay", language)
              }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <template>
      <div class="text-center">
        <v-dialog v-model="dialog" width="auto">
          <v-card width="500">
            <div style="text-align: right">
              <v-icon right size="large" @click="dialog = false"
                >mdi-close-circle</v-icon
              >
            </div>
            <v-col>
              <v-card class="" elevation="0">
                <stripe-element-payment
                  ref="paymentRef"
                  :pk="pk"
                  :elements-options="elementsOptions"
                  :confirm-params="confirmParams"
                  :locale="language"
                />
                <v-divider
                  inset
                  color="#212121"
                  style="margin: 20px"
                ></v-divider>
                <v-container
                  class="bg-surface-variant"
                  style="margin-top: 20px"
                >
                  <v-row>
                    <v-col class="">
                      <v-sheet class="d-flex flex-row align-center justify-end">
                        <h4>
                          {{ $t("yourOrderDetailMobile.total", language) }} :
                          {{
                            promo != null && promo != undefined
                              ? promo.amount_in_local_currency >= total
                                ? 0
                                : total - promo.amount_in_local_currency
                              : total
                          }}
                          {{ pricePhoto.local_currency_symbol }}
                        </h4>
                        <v-btn
                          @click="pay"
                          rounded
                          class="ml-10"
                          style="
                            background-color: #000000;
                            color: white;
                            font-family: Georama;
                            text-transform: none;
                          "
                          variant="text"
                        >
                          {{
                            $t("yourOrderDetailMobile.finalise_order", language)
                          }}
                        </v-btn>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-container>
</template>

<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
export default {
  name: "YourOrderDetail",
  components: {
    StripeElementPayment,
  },
  data: () => ({
    email: "",
    adresse: {},
    codePromo: "",
    promo: null,
    selectedProduct: {},
    selectedCounty: {},
    countryCode: "",
    countryFlag: "",
    photo: null,
    photoResized: null,
    boardDetoured: {},
    boardEphotoDetoured: {},
    boardResized: {},
    boardEphotoResized: {},
    priceOPVerification: {},
    pricePhoto: {},
    boardPrintCount: {},
    boardEphotoPrintCount: {},
    dialog: false,
    dialogBasic: false,
    pk: process.env.VUE_APP_ENV_STRIPE_PUB_KEY,
    stripeClientSecret: null,
    elementsOptions: {
      appearance: {}, // appearance options
    },
    confirmParams: {
      return_url: process.env.VUE_APP_URL + "/mobile/winner", // success url
    },
    /* pricePrint: localStorageService
      .getPrice()
      .optional_offers.find((x) => x.product.send_by_post_needed),*/
    pricePrint: {},
    count: 0,
    language: "",
    images: [],
    isClicked: false,
    imageWidth: "35mm",
    imageHeight: "45mm",
    erreurPromo: false,
    progress: false,
  }),

  methods: {
    // Method to redirect to the "ChoosePlan" route
    redirect() {
      this.$router.push({
        name: "ChoosePlan",
      });
    },
    // Method to handle focus event
    handleFocus() {
      this.isClicked = true;
    },
    // Method to handle blur event
    handleBlur() {
      this.isClicked = false;
    },
    // Method to apply a promo code
    appliquerCodePromo() {
      const countryCode = localStorageService.getCountryCode();
      requestService
        .get(
          `/promotion/check/${this.codePromo}/for/${countryCode}`,
          null,
          null
        )
        .then((data) => {
          if (this.codePromo === data.data.code) {
            this.promo = {
              amount_in_local_currency: data.data.amount_in_local_currency,
              local_currency_symbol: data.data.local_currency_symbol,
              data: data.data,
            };
            localStorageService.setPromo(this.promo);
          }
        })
        .catch(() => {
          this.erreurPromo = true;
          console.error(`Le code promo est invalide`);
        });
    },
    // Method to load an image using dynamic import
    loadImg(img) {
      return this.images("./" + img + ".jpg");
    },
    // Method to generate a payment intent for the order
    async generatePaymentIntent() {
      // Generate payment intent based on order items and promo code if available
      this.dialogBasic = false;
      this.progress = true;
      var _this = this;
      this.stripeClientSecret = localStorageService.getStripeClientSecret();
      if (
        this.stripeClientSecret == null ||
        this.stripeClientSecret == undefined ||
        this.stripeClientSecret == "null"
      ) {
        var photoUid = localStorageService.getPhotoUid();
        var signatureUid = localStorageService.getSignatureUid();
        var address = localStorageService.getAdresse();
        var orderItems = [
          {
            quantity: 1,
            document: {
              photo: photoUid == null ? undefined : photoUid,
              signature: signatureUid == null ? undefined : signatureUid,

              firstName: address == null ? null : address.firstName,
              lastName: address == null ? null : address.lastName,
              address: address == null ? null : address.address,
              postalCode: address == null ? null : address.postalCode,
              city: address == null ? null : address.city,
              country: address == null ? null : address.country,
            },
            priceId: this.pricePhoto.id,
          },
        ];
        if (
          this.priceOPVerification !== null &&
          this.priceOPVerification !== undefined
        ) {
          orderItems.push({
            quantity: 1,
            priceId: this.priceOPVerification.id,
          });
        }
        if (
          this.pricePrint !== null &&
          this.pricePrint !== undefined &&
          this.boardPrintCount !== 0 &&
          this.boardPrintCount > 0
        ) {
          orderItems.push({
            quantity: this.boardPrintCount,
            priceId: this.pricePrint.id,
          });
        }
        if (
          this.pricePrint !== null &&
          this.pricePrint !== undefined &&
          this.boardEphotoPrintCount !== 0 &&
          this.boardEphotoPrintCount > 0
        ) {
          orderItems.push({
            quantity: this.boardEphotoPrintCount,
            priceId: this.pricePrint.id,
          });
        }

        const orderFormData = JSON.stringify({
          emailOwner: this.email,
          orderItems: orderItems,
          promotion:
            this.promo !== null && this.promo !== "null"
              ? this.promo.data.code
              : null,
        });
        requestService
          .post("order/anonymous", orderFormData, null, {
            "content-type": "application/json",
          })
          .then((data) => {
            localStorageService.setStripeClientSecret(
              data.data.stripe_client_secret
            );

            _this.stripeClientSecret = data.data.stripe_client_secret;
            _this.elementsOptions.clientSecret = _this.stripeClientSecret;

            if (data.data.stripe_client_secret === null) {
              _this.dialog = false;
              this.$router.push({
                name: "BravoApp",
              });
            } else {
              _this.dialog = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.elementsOptions.clientSecret = this.stripeClientSecret;
        this.dialog = true;
      } //paymentIntent.client_secret;
    },
    // Method to initiate payment
    pay() {
      this.$refs.paymentRef.submit(); // Submit the payment form for payment processing
    },
    // Method to navigate to the next step
    next() {
      if (
        this.elementsOptions.clientSecret !== null &&
        this.elementsOptions.clientSecret !== undefined
      ) {
        this.dialog = true;
      }
    },
    // Method to load initial data
    load() {
      this.email = localStorageService.getEmailOwner();
      this.adresse = localStorageService.getAdresse();
      this.codePromo = "";
      //this.countryCode = localStorageService.getCountryCode().toLowerCase();
      this.countryFlag = "";
      this.boardDetoured = localStorageService.getPhotoBoardDetoured();
      this.boardEphotoDetoured =
        localStorageService.getPhotoBoardDetouredEphoto();
      this.boardResized = localStorageService.getPhotoBoardResized();
      this.boardEphotoResized =
        localStorageService.getPhotoBoardResizedEphoto();
      this.priceOPVerification =
        localStorageService.getPriceOPOfferVerification();
      this.pricePhoto = localStorageService.getPrice();
      this.countryCode =
        this.pricePhoto.use_in_country.country_code.toLowerCase();
      this.selectedProduct = this.pricePhoto.purpose.label;
      this.selectedCounty = this.pricePhoto.use_in_country.country_name;

      this.boardPrintCount = localStorageService.getBoardPrintCount();
      this.boardEphotoPrintCount =
        localStorageService.getBoardEphotoPrintCount();

      this.dialog = false;
      this.pk = process.env.VUE_APP_ENV_STRIPE_PUB_KEY;
      this.stripeClientSecret = null;
      this.elementsOptions = {
        appearance: {}, // appearance options
      };
      this.confirmParams = {
        return_url: process.env.VUE_APP_RETURN_URL_STRIPE, // success url
      };
      this.pricePrint = localStorageService.getPriceOPShipment();
      this.count = localStorage.getItem("count");

      this.photo = localStorageService.getPhotoBase64Detoured();
      this.photoResized = localStorageService.getPhotoBase64Resize();
      this.promo = localStorageService.getPromo();

      //this.generatePaymentIntent();
      this.countryFlag =
        "../../assets/images/Drapeaux/" + this.countryCode + ".jpg";
      this.language = localStorageService.getLanguage();
      this.images = require.context(
        "../../assets/images/Drapeaux/",
        false,
        /\.jpg$/
      );
    },
  },

  computed: {
    // Computed property to calculate the total amount
    total() {
      // Calculate the total amount based on various factors
      return (
        Number(this.pricePhoto.amount_in_local_currency) +
        Number(
          this.pricePrint != null && this.pricePrint != undefined
            ? this.pricePrint.amount_in_local_currency
            : 0
        ) *
          (Number(
            this.boardPrintCount != null && this.boardPrintCount != undefined
              ? this.boardPrintCount
              : 0
          ) +
            Number(
              this.boardEphotoPrintCount != null &&
                this.boardEphotoPrintCount != undefined
                ? this.boardEphotoPrintCount
                : 0
            )) +
        Number(
          this.priceOPVerification != null &&
            this.priceOPVerification != undefined
            ? this.priceOPVerification.amount_in_local_currency
            : 0
        )
      ).toFixed(2); // Format the total amount
    },
  },
  mounted() {
    this.load();
    localStorageService.setStripeClientSecret(null);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");

.document {
  margin-left: 25px;

  font-family: "Georama";
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  color: #4d4d4d;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: 0.2px;
}

.btn {
  box-sizing: border-box;
  border: 1px solid #efefef;

  /* Auto layout */

  padding-left: 10px;
  padding-right: 10px;
  gap: 16px;

  width: 550px;
  height: 300px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
@media only screen and (max-width: 700px) {
  .text {
    width: 115px;
    height: 20px;
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    margin-right: 3%;

    /* White */

    color: black;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    width: 300px;
    height: 52px;
    left: 10px;
    margin-top: 10%;
    background: #ffffff;
    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .total {
    height: 24px;
    margin-right: 70%;
    font-family: "Georama";
    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .textpl {
    width: 115px;
    height: 20px;
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    margin-right: 3%;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;

    width: 300px;
    height: 52px;

    margin-top: 15%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .cadre1 {
    /*margin-top: 30%;
    border: none;*/
  }
  .line {
    width: 300px;
    height: 1px;

    /* Tetriary Ligjht */

    color: #efefef;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .number3 {
    height: 24px;
    margin-right: 8%;
    font-family: "Georama";
    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number4 {
    height: 24px;
    margin-right: 8%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    color: #4d4d4d;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number2 {
    width: 93.28px;
    height: 24px;
    padding-top: 1.5%;
    /*font-weight: bold;*/
    color: black;

    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
    font-family: "Georama";
    /* Primary dark */
  }
  .numberQ {
    width: 93.28px;
    height: 24px;
    padding-top: 1.5%;
    /*font-weight: bold;*/
    color: black;
    font-family: Georama;
    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
  }
  .number1 {
    height: 24px;
    margin-right: 10%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;
  }
  .cadre {
    margin-top: 8%;

    width: 350px;
    height: 220px;
    margin-left: 1.5%;
    padding: 15px;
    border: 1px solid #efefef;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }

  .take1 {
    box-sizing: border-box;
    margin-left: 63%;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;
    margin-top: -22%;

    width: 95px;
    height: 52px;

    /* White */

    background: white;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .text5 {
    width: 143px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Primary dark */

    color: black;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .conf {
    margin-top: 13%;
  }
  .search-input-container .search-input {
    width: 210px;
    height: 52px;
    margin-top: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;
    /* Tetriary Ligjht */

    color: #000000;
    border-radius: 4px;
  }
  .search-input-container .search-input::placeholder {
    width: 178px;
    height: 20px;
    left: 16px;
    top: 16px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 1px;

    /* Secondary Gray */

    color: #000000;
    font-stretch: 100;
  }
  .search-input-container {
    display: flex;
    justify-content: center;

    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }
  .document1 {
    height: 24px;
    margin-left: 27%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }

  .vector {
    align-self: flex-start;
    width: 16px;
    height: 16px;
    border-radius: 10%;
  }
  .noir {
    margin-right: 20%;
  }
  .country {
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 2%;
    margin-left: 1.5%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .number {
    width: 93.28px;
    height: 24px;
    font-family: "Georama";
    font-weight: bold;
    color: black;
    padding-top: 1.5%;
    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
    /*margin-top: -30%;*/
  }
  .planche {
    margin-top: 3%;
  }
  .documentpass {
    margin-left: 5px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .pass {
    width: 16px;

    /* Secondary Gray */
  }
  .documentpass1 {
    margin-left: 5px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .documentpass2 {
    margin-left: 5px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .text {
    width: 115px;
    height: 20px;
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    margin-right: 3%;

    /* White */

    color: black;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take2 {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;

    width: 350px;
    height: 52px;
    left: 10px;
    margin-top: 10%;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    color: #000000;
  }
  .vector {
    align-self: flex-start;
    width: 16px;
    height: 16px;
    border-radius: 10%;
  }
  .noir {
    margin-right: 20%;
  }
  .country {
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-left: 9%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .total {
    height: 24px;
    margin-right: 80%;
    font-family: "Georama";
    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .textpl {
    width: 115px;
    height: 20px;
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    margin-right: 3%;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;

    width: 350px;
    height: 52px;
    margin-left: 1%;
    margin-top: 20%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .cadre1 {
    margin-top: 30%;
  }
  .line {
    width: 300px;
    height: 1px;

    /* Tetriary Ligjht */

    color: #efefef;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .number3 {
    height: 24px;
    margin-right: 8%;
    font-family: "Georama";
    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number4 {
    height: 24px;
    margin-right: 8%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    color: #4d4d4d;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number2 {
    width: 93.28px;
    height: 24px;
    padding-top: 1.5%;
    /*font-weight: bold;*/
    color: black;
    font-family: "Georama";
    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Primary dark */
  }

  .numberQ {
    width: 93.28px;
    height: 24px;
    padding-top: 1.5%;
    /*font-weight: bold;*/
    color: black;
    font-family: Georama;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
  }
  .number1 {
    height: 24px;
    margin-right: 10%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;
  }
  .cadre {
    box-sizing: border-box;
    margin-top: 8%;

    width: 350px;
    height: 220px;
    margin-left: 7%;
  }
  .take1 {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;
    margin-top: -10%;

    width: 95px;
    height: 52px;

    /* White */

    background: white;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .text5 {
    width: 143px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Primary dark */

    color: black;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .conf {
    margin-top: 13%;
    margin-left: 5%;
  }
  .search-input-container .search-input {
    width: 210px;
    height: 52px;
    margin-top: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;

    color: #000000;
    border-radius: 4px;
  }
  .search-input-container .search-input::placeholder {
    width: 178px;
    height: 20px;
    left: 16px;
    top: 16px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 1px;

    /* Secondary Gray */

    color: #000000;
    font-stretch: 100;
  }
  .search-input-container {
    display: flex;
    justify-content: center;
    margin-right: 5%;
    flex-direction: column;
  }
  .document1 {
    width: 51px;
    height: 24px;
    margin-left: 27%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .image {
    margin-top: -2%;
  }
  .noir {
    margin-right: 20%;
  }
  .country {
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 2%;
    margin-left: 7.5%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .number {
    width: 93.28px;
    height: 24px;
    font-family: "Georama";
    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-left: 4%;
    padding-top: 1.5%;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
    /*margin-top: -28%;*/

    /* Primary dark */
  }
  .planche {
    margin-top: 3%;
  }
  .documentpass {
    margin-left: 5px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .pass {
    width: 16px;
    height: 16px;

    /* Secondary Gray */
  }
  .documentpass1 {
    margin-left: 5px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .documentpass2 {
    margin-left: 5px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .text {
    width: 115px;
    height: 20px;
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    margin-right: 3%;

    /* White */

    color: black;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take2 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    width: 300px;
    height: 52px;
    margin-left: 40%;
    margin-top: 10%;
    background: #ffffff;
    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .total {
    height: 24px;
    margin-right: 80%;
    font-family: "Georama";
    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .textpl {
    width: 115px;
    height: 20px;
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    margin-right: 3%;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    margin-left: 40%;
    width: 300px;
    height: 52px;
    margin-top: 20%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .cadre1 {
    margin-top: 30%;
  }
  .line {
    width: 300px;
    height: 1px;

    /* Tetriary Ligjht */

    color: #efefef;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
  .number3 {
    height: 24px;
    margin-right: 8%;
    font-family: "Georama";
    font-weight: bold;
    color: black;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number4 {
    height: 24px;
    margin-right: 8%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    color: #4d4d4d;

    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    font-stretch: 96;
  }
  .number2 {
    width: 93.28px;
    height: 24px;
    padding-top: 1.5%;
    /*font-weight: bold;*/
    color: black;
    font-family: "Georama";
    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;

    /* Primary dark */
  }
  .numberQ {
    width: 93.28px;
    height: 24px;
    padding-top: 1.5%;
    /*font-weight: bold;*/
    color: black;
    font-family: Georama;
    font-size: 14px;
    line-height: 24px;

    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
  }
  .number1 {
    height: 24px;
    margin-right: 10%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 24px;
    margin-top: -11%;
    /* identical to box height, or 171% */

    text-align: right;
    letter-spacing: 0.2px;

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;
  }
  .cadre {
    box-sizing: border-box;
    margin-top: 8%;

    width: 320px;
    height: 180px;
    margin-left: 7%;
  }
  .take1 {
    box-sizing: border-box;
    margin-left: 110%;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;
    margin-top: -26%;

    width: 95px;
    height: 52px;

    /* White */

    background: white;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .text5 {
    width: 143px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;

    /* identical to box height, or 125% */

    text-align: center;

    /* Primary dark */

    color: black;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .conf {
    margin-top: 13%;
    margin-left: 5%;
  }
  .search-input-container .search-input {
    width: 210px;
    height: 52px;
    margin-top: 6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px;
    gap: 16px;

    color: #000000;
    border-radius: 4px;
  }
  .search-input-container .search-input::placeholder {
    width: 178px;
    height: 20px;
    left: 16px;
    top: 16px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 1px;

    /* Secondary Gray */

    color: #000000;
    font-stretch: 100;
  }
  .search-input-container {
    display: flex;
    justify-content: center;
    margin-right: 5%;
    flex-direction: column;
  }
  .document1 {
    width: 51px;
    height: 24px;
    margin-left: 27%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .image {
    margin-top: -2%;
  }

  .vector {
    align-self: flex-start;
    width: 18px;
    height: 18px;
    border-radius: 10%;
  }
  .noir {
    margin-right: 20%;
  }
  .country {
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 2%;
    margin-left: 6%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .number {
    width: 93.28px;
    height: 24px;
    font-family: "Georama";
    font-weight: bold;
    color: black;
    padding-top: 1.5%;
    font-size: 14px;
    line-height: 24px;
    margin-left: 4%;
    /* identical to box height, or 171% */

    letter-spacing: 0.2px;
    /* margin-top: -30%;*/

    /* Primary dark */
  }
  .planche {
    margin-top: 3%;
  }
  .documentpass {
    margin-left: 5px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .pass {
    width: 18px;
    height: 18px;

    /* Secondary Gray */
  }
  .documentpass1 {
    margin-left: 5px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
  .documentpass2 {
    margin-left: 5px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: 0.2px;

    /* Primary dark */

    color: #000000;
  }
}
</style>
