<template>
  <v-container>
    <div class="country">
      <p>One step away from taking your photo</p>
    </div>
    <div class="text">
      <p>Country</p>
    </div>
    <template>
      <v-container>
        <v-row>
          <v-col cols="auto">
            <button class="recherche">
              <v-img
                class="ronde"
                :aspect-ratio="1"
                src="../../assets/images/Drapeaux/uk.jpg"
                cover
              ></v-img>
              <span class="position">United Kingdom</span>
              <v-img
                class="pen"
                :aspect-ratio="1"
                src="../../assets/images/pen-Bold.png"
                cover
              ></v-img>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <br />
    <br />
    <template>
      <div class="text">
        <span>Select document type you require</span>
      </div>
      <br />
      <v-container>
        <v-row>
          <v-col cols="6">
            <button class="btn">
              <div class="search-icon">
                <v-img
                  width="20"
                  height="20"
                  src="../../assets/images/pen-Bold.png"
                ></v-img>
              </div>
              <v-img
                class="ellipse"
                src="../../assets/images/Ellipse.png"
                height="52"
                width="52"
                cover
              ></v-img>
              <v-img
                class="noir"
                src="../../assets/images/passport.png"
                width="42"
                height="60"
                cover
              ></v-img>
              <h6 class="document">UK Passport Photo</h6>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <div class="take">
      <div
        class="d-flex justify-space-around align-center flex-column flex-sm-row"
      >
        <v-btn @click="next" rounded color="black" dark>
          <span class="text1">Take <span class="mini">A</span> Photo</span>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "EditCountryDocument",
  data: () => ({}),
};
</script>

<style scoped>
/* Styles pour les écrans de petite taille */
@media only screen and (max-width: 700px) {
  .text1 {
    text-transform: capitalize;
  }
  .mini {
    text-transform: lowercase;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    margin-top: 30%;
    width: 350px;
    height: 52px;
    margin: auto;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 5%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    width: 104px;
    height: 40px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: 30%;
    /* or 125% */

    text-align: center;
    letter-spacing: 1px;

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    z-index: 5;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
  }
  .btn {
    box-sizing: border-box;
    justify-content: center;
    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 16px;

    width: 330px;
    height: 150px;
    /* White */

    align-items: center;
    background: #ffffff;
    /* Tetriary Ligjht */

    border: 2px solid #2fc977;
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 100%;
  }
  .recherche {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
    gap: 18px;
    position: absolute;
    width: 330px;
    height: 68px;
    margin-top: 280px;
    margin: auto;
    /* White */
    background: #ffffff;
    /* Accent */
    border: 2px solid #2fc977;
    border-radius: 4px;
  }
  .ronde {
    border-radius: 20%;
    width: 35px;
    height: 35px;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 20%;

    /* Tetriary Ligjht */

    color: #efefef;
  }
  .search-icon {
    align-self: flex-end;
    margin-top: -3px;
    margin-left: 92%;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .text1 {
    text-transform: capitalize;
  }
  .mini {
    text-transform: lowercase;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;
    margin-left: 10%;
    width: 550px;
    height: 62px;
    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 7%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    /* or 125% */

    text-align: center;
    letter-spacing: 1px;

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-size: 20px;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 30%;
    margin-left: 7%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    z-index: 5;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
  }
  .btn {
    box-sizing: border-box;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 20px;

    width: 700px;
    height: 200px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 2px solid #2fc977;
    border-radius: 4px;
    margin-bottom: 30%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 8%;
  }
  .recherche {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
    gap: 120px;
    position: absolute;
    width: 650px;
    height: 130px;

    margin-left: 5.2%;

    /* White */
    background: #ffffff;
    /* Accent */
    border: 2px solid #2fc977;
    border-radius: 4px;
  }
  .ronde {
    border-radius: 20%;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 20%;

    /* Tetriary Ligjht */

    color: #efefef;
  }
  .search-icon {
    align-self: flex-end;
    margin-top: -3px;
    margin-left: 92%;
  }
}
</style>
