<template>
  <v-container>
    <div class="country">
      <p>{{ $t("AddressLivraison.title_shipping", language) }}</p>
    </div>
    <div class="hup">
      <div class="conf">
        <div class="text1">
          <p>{{ $t("AddressLivraison.firstname", language) }}*</p>
        </div>
        <div class="search-input-container" no-gutters>
          <v-text-field
            class="search-input"
            type="text"
            placeholder="Smith"
            v-model="nom"
            outlined
            :color="isClicked ? '#2fc977' : ''"
            @focus="handleFocus"
            @blur="handleBlur"
          ></v-text-field>
        </div>
      </div>
      <div class="conf">
        <div class="text1">
          <p>{{ $t("AddressLivraison.lastname", language) }}*</p>
        </div>
        <div class="search-input-container">
          <v-text-field
            class="search-input"
            type="text"
            placeholder="Mariem"
            v-model="prenom"
            outlined
            :color="isClicked ? '#2fc977' : ''"
            @focus="handleFocus"
            @blur="handleBlur"
          ></v-text-field>
        </div>
      </div>
      <div class="conf">
        <div class="text1">
          <p>{{ $t("AddressLivraison.boite", language) }}</p>
        </div>
        <div class="search-input-container">
          <v-text-field
            class="search-input"
            type="text"
            placeholder="Dorian Jones"
            v-model="nomBL"
            outlined
            :color="isClicked ? '#2fc977' : ''"
            @focus="handleFocus"
            @blur="handleBlur"
          ></v-text-field>
        </div>
      </div>
      <div class="conf">
        <div class="text1">
          <p>{{ $t("AddressLivraison.address", language) }}*</p>
        </div>
        <div class="search-input-container">
          <v-text-field
            class="search-input"
            type="text"
            placeholder="13 Willow street"
            v-model="adresse"
            outlined
            :color="isClicked ? '#2fc977' : ''"
            @focus="handleFocus"
            @blur="handleBlur"
          ></v-text-field>
        </div>
      </div>
      <div class="conf">
        <div class="text1">
          <p>{{ $t("AddressLivraison.addressCompl", language) }}</p>
        </div>
        <div class="search-input-container">
          <v-text-field
            class="search-input"
            type="text"
            placeholder="building A, apartment 26"
            v-model="complementAdresse"
            outlined
            :color="isClicked ? '#2fc977' : ''"
            @focus="handleFocus"
            @blur="handleBlur"
          ></v-text-field>
        </div>
      </div>
      <div class="conf">
        <div class="text1">
          <p>{{ $t("AddressLivraison.code_postal", language) }}*</p>
        </div>
        <div class="search-input-container">
          <v-text-field
            class="search-input"
            type="text"
            placeholder="VA 20147"
            v-model="codePostal"
            outlined
            :color="isClicked ? '#2fc977' : ''"
            @focus="handleFocus"
            @blur="handleBlur"
          ></v-text-field>
        </div>
      </div>
      <div class="conf">
        <div class="text1">
          <p>{{ $t("AddressLivraison.ville", language) }}*</p>
        </div>
        <div class="search-input-container">
          <v-text-field
            class="search-input"
            type="text"
            placeholder="Ashburn"
            v-model="ville"
            outlined
            :color="isClicked ? '#2fc977' : ''"
            @focus="handleFocus"
            @blur="handleBlur"
          ></v-text-field>
        </div>
      </div>
      <div class="conf" v-if="pays != 'France'">
        <div class="text1">
          <p>{{ $t("AddressLivraison.region", language) }}</p>
        </div>
        <div class="search-input-container">
          <v-text-field
            class="search-input"
            type="text"
            placeholder="Virginia"
            v-model="region"
            outlined
            :color="isClicked ? '#2fc977' : ''"
            @focus="handleFocus"
            @blur="handleBlur"
          ></v-text-field>
        </div>
      </div>
      <div class="conf">
        <div class="text1">
          <p>{{ $t("AddressLivraison.country", language) }}*</p>
        </div>
        <div class="search-input-container">
          <v-select
            :items="countries"
            item-text="country_name"
            item-value="country_merci_facteur"
            class="search-input"
            type="text"
            :placeholder="$t('Desktop.homeDesktop.choose_country', language)"
            v-model="pays"
            outlined
            :color="isClicked ? '#2fc977' : ''"
            @focus="handleFocus"
            @blur="handleBlur"
          ></v-select>
        </div>
      </div>
      <br />
      <h6 style="margin-left: 3%">
        {{ $t("AddressLivraison.info", language) }}
      </h6>
    </div>
    <v-alert
      v-if="errors.length"
      style="background-color: #d70000; color: white; margin-top: 5%"
    >
      <ul style="display: flex; align-items: center">
        <li
          v-for="error in errors"
          :key="error.id"
          style="display: flex; align-items: center"
        >
          <v-img
            src="../../assets/images/exclamation-circle-Bold1.png"
            max-width="20"
            max-height="20"
            style="margin-right: 15px"
          ></v-img>
          <span>{{ error }}</span>
        </li>
      </ul>
    </v-alert>

    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <button class="take3" color="black" rounded @click="checkForm">
        <span class="textpl"
          >{{ $t("AddressLivraison.proceed_price", language) }} {{ this.total }}
          {{ price.local_currency_symbol }}
        </span>
      </button>
    </div>
  </v-container>
</template>

<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "AddressLivraison",
  data: () => ({
    errors: [],
    countries: [],
    nom: "",
    prenom: "",
    nomBL: "",
    adresse: "",
    complementAdresse: "",
    ville: "",
    pays: null,
    region: "",
    codePostal: "",
    language: "",
    total: localStorage.getItem("total"),
    price: null,
    isClicked: false,
  }),
  methods: {
    // Method to load data for a specific country
    async load() {
      var countryCode = localStorageService.getCountryCode();
      // Fetch customized data for the country using the specified language
      requestService
        .get(`/country/customized?country_code=${countryCode}`, null, null, {
          language: this.language,
        })
        .then((data) => {
          // Filter and store the countries that are send-authorized and have valid merci facteur data
          this.countries = data.data.Countries.filter(
            (element) =>
              element.country_merci_facteur != null &&
              element.country_merci_facteur != "" &&
              element.send_authorized == true
          );

          var defaultCountry =
            this.pays !== undefined && this.pays !== null
              ? this.pays.country_code
              : localStorageService.getCountryCode();
          var selectedCountries = data.data.Countries.filter(
            (element) =>
              element.country_merci_facteur !== null &&
              element.country_merci_facteur !== "" &&
              element.send_authorized === true &&
              element.country_code === defaultCountry
          );
          if (selectedCountries.length > 0) {
            this.pays = selectedCountries[0];
          }
        });
    },
    // Method to handle focus event
    handleFocus() {
      this.isClicked = true;
    },
    // Method to handle blur event
    handleBlur() {
      this.isClicked = false;
    },
    // Method to navigate to the next step
    next() {
      // Store address data in local storage and navigate to "YourOrderDetail" route
      localStorageService.setAdresse({
        firstName: this.nom,
        lastName: this.prenom,
        address: this.adresse,
        postalCode: this.codePostal,
        complementAdresse: this.complementAdresse,
        nomBL: this.nomBL,
        city: this.ville,
        region: this.region,
        //doit renvoyer this.country_merci_facteur du flux customized
        country: this.pays,
      });
      this.$router.push({
        name: "YourOrderDetail",
      });
    },
    // Method to validate the address form
    checkForm(e) {
      this.errors = [];
      if (
        !this.nom ||
        !this.prenom ||
        !this.adresse ||
        !this.ville ||
        !this.codePostal ||
        !this.pays
      ) {
        this.errors.push(
          this.$t("AddressLivraison.address_error", this.language)
        ); // Push an error message if any required field is missing
        return;
      } else {
        this.next(); // Proceed to the next step if all fields are filled
      }
      e.preventDefault();
    },
  },
  mounted() {
    var address = localStorageService.getAdresse();
    if (address !== null && address !== undefined) {
      this.nom = address.firstName;
      this.prenom = address.lastName;
      this.adresse = address.address;
      this.codePostal = address.postalCode;
      this.complementAdresse = address.complementAdresse;
      this.nomBL = address.nomBL;
      this.ville = address.city;
      this.region = address.region;
      this.pays = address.country;
    }

    this.language = localStorageService.getLanguage();
    this.price = localStorageService
      .getPrice()
      .optional_offers.find((x) => x.product.send_by_post_needed);

    localStorageService.setStripeClientSecret(null);
    var price = localStorageService.getPrice();

    var countryCode = localStorageService.getCountryCode();
    if (
      price === null ||
      price === undefined ||
      countryCode === null ||
      countryCode === undefined
    ) {
      this.$router.push({ name: "welcomeMobile" });
      return;
    }

    var photoUidDetoured = localStorageService.getPhotoUidDetoured();
    if (photoUidDetoured === null || photoUidDetoured === undefined) {
      this.$router.push({
        name: "TakePhotoMobile",
        params: { countryCode: countryCode, priceId: this.price.id },
      });
      return;
    }

    var email = localStorageService.getEmailOwner();
    if (email === null || email === undefined) {
      this.$router.push({
        name: "EmailApp",
        params: { country: localStorageService.getCountryCode() },
      });
      return;
    }
    this.load();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
@media only screen and (max-width: 700px) {
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;

    width: 350px;
    height: 52px;
    margin-left: 2%;
    margin-top: 10%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }

  .alert {
    font-family: Georama;
  }
  .textpl {
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .search-input-container .search-input {
    width: 100%;
  }

  .search-input-container {
    display: flex;
    justify-content: center;
    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }
  .search-input-container .search-input::placeholder {
    color: #4d4d4d;
  }
  .text1 {
    margin-top: 2%;
    font-family: "Georama";
    font-style: normal;
    font-weight: bold;
    color: black;
    font-stretch: 96;
    margin-left: 3%;
  }
  .conf {
    margin-top: 3%;
  }
  .country {
    margin-left: 3%;
    margin-top: 2%;
    margin-bottom: 15%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;

    width: 350px;
    height: 52px;
    margin-left: 2%;
    margin-top: 10%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .hup {
    margin-top: -2%;
  }

  .textpl {
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .search-input-container .search-input {
    width: 100%;
  }
  .search-input-container {
    display: flex;
    justify-content: center;
    border: 2px solid #2fc977;
    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
  }
  .search-input-container .search-input::placeholder {
    color: #4d4d4d;
  }
  .text1 {
    margin-top: 2%;
    font-family: "Georama";
    font-style: normal;
    color: black;
    font-weight: bold;
    font-stretch: 96;
    margin-left: 3%;
  }
  .conf {
    margin-top: 3%;
  }
  .country {
    margin-left: 3%;
    margin-top: 2%;
    margin-bottom: 15%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    width: 250px;
    height: 52px;
    margin-left: 29%;
    margin-top: 10%;
    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .textpl {
    text-transform: capitalize;

    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .search-input-container .search-input {
  }
  .search-input-container {
    display: flex;
    justify-content: center;
    border: 2px solid #2fc977;
    margin-left: 3%;
    margin-right: 5%;
    flex-direction: column;
    width: 130%;
  }
  .search-input-container .search-input::placeholder {
    color: #4d4d4d;
  }
  .text1 {
    margin-top: 2%;
    font-family: "Georama";
    font-style: normal;
    color: black;
    font-weight: bold;
    font-stretch: 96;
    margin-left: 3%;
  }
  .conf {
    margin-top: 3%;
  }
  .country {
    margin-left: 3%;
    margin-top: 2%;
    margin-bottom: 15%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
</style>
