<template>
  <v-app dark>
    <div class="header-bar-style">
      <div class="d-flex justify-space-between bg-surface-variant">
        <!-- Button goBack -->
        <v-sheet class="mt-1 mb-1 mr-5 py-0">
          <v-btn
            v-if="goBack"
            text
            density="compact"
            icon
            @click="goBackAction"
          >
            <v-icon color="black">mdi-arrow-left</v-icon>
          </v-btn>
        </v-sheet>
        <!-- logo or title -->
        <v-sheet class="ma-1 pa-2 text-h6" v-if="title !== undefined">
          {{ $t("headerMobile." + title, language) }}
        </v-sheet>
        <v-sheet class="ma-2 pa-0" v-else-if="smidIcone">
          <v-img
            src="../assets/images/logo-smartphone-id-sm.png"
            width="170px"
          ></v-img>
        </v-sheet>

        <!-- Right button, display popup screen -->
        <v-sheet class="mt-1 mb-1 ml-5 py-0">
          <v-select
            :items="languages"
            item-text="lang"
            item-value="code"
            variant="underlined"
            v-on:change="changeLanguage"
            v-model="selectedLanguage"
            style="width: 100px"
          ></v-select>
          <!-- help button  -->
          <v-dialog
            v-if="displayInfoScreen"
            v-model="dialog"
            :fullscreen="showInFullScreen"
            :scrim="false"
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ props }">
              <v-btn
                text
                density="compact"
                icon
                v-bind="props"
                @click="dialog = true"
              >
                <v-icon color="black">mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>

            <v-card>
              <div class="header-bar-style" v-if="showInFullScreen">
                <div class="d-flex justify-space-between bg-surface-variant">
                  <!-- Button goBack -->
                  <v-sheet class="mt-1 mb-1 mr-5 py-0">
                    <v-btn
                      v-if="goBack"
                      text
                      density="compact"
                      icon
                      @click="goBackAction"
                    >
                      <v-icon color="black">mdi-arrow-left</v-icon>
                    </v-btn>
                  </v-sheet>
                  <!-- logo or title -->
                  <v-sheet class="ma-1 pa-2 text-h6" v-if="title !== undefined">
                    {{ $t("headerMobile." + title, language) }}
                  </v-sheet>
                  <v-sheet class="ma-2 pa-0" v-else-if="smidIcone">
                    <v-img
                      src="../assets/images/logo-smartphone-id-sm.png"
                      width="170px"
                    ></v-img>
                  </v-sheet>

                  <!-- Right button, display popup screen -->
                  <v-sheet class="mt-1 mb-1 ml-5 py-0">
                    <v-btn text density="compact" icon @click="dialog = false">
                      <v-icon color="black">mdi-close-box-outline</v-icon>
                    </v-btn>
                  </v-sheet>
                </div>
                <!-- Slider for progression status -->
                <v-progress-linear
                  :value="(level * 100) / totalSteps"
                  bg-color="#efefef"
                  color="#2fc977"
                ></v-progress-linear>
                <v-sheet class="d-flex justify-space-around">
                  <v-chip
                    v-for="index in totalSteps"
                    :key="index"
                    class="ma-2"
                    color="#2fc977"
                    label
                    :outlined="index > level"
                    :text-color="index > level ? '#2fc977' : '#fff'"
                    >{{ index }}</v-chip
                  >
                </v-sheet>
              </div>
              <tips-app v-if="show() == 'tips-app'" :close="close"></tips-app>
              <alert-message-1
                v-if="show() == 'alert-message-1'"
                :close="close"
                :text="$t('headerMobile.mess_alert1', language)"
                :buttonClose="$t('headerMobile.continue', language)"
              ></alert-message-1>
            </v-card>
          </v-dialog>
        </v-sheet>
      </div>
      <!-- Slider for progression status -->
      <v-progress-linear
        :value="(level * 100) / totalSteps"
        bg-color="#efefef"
        color="#2fc977"
      ></v-progress-linear>
      <v-sheet class="d-flex justify-space-around">
        <v-chip
          v-for="index in totalSteps"
          :key="index"
          class="ma-2"
          color="#2fc977"
          label
          :outlined="index > level"
          :text-color="index > level ? '#2fc977' : '#fff'"
          >{{ index }}</v-chip
        >
      </v-sheet>
    </div>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import AlertMessage1 from "../components/AlertMessage1.vue";
import TipsApp from "../components/TipsApp.vue";
import { localStorageService } from "@/services/localStorage.service";
import { countryLanguageDictionary } from "@/services/countryLanguage.dictionary";
export default {
  components: { AlertMessage1, TipsApp },

  data: () => ({
    smidIcone: false, //smartphone id logo in the middle top. displayed if title is missing
    title: null, //The title in the header
    goBack: true, //allow go back. show the button back in the coner left top
    level: 0, // from 0 to 100, indicate the progression in the sell step
    displayInfoScreen: false, // allow the display of the info screen
    showInFullScreen: false, //display the help screen in fullscreen or not
    dialog: false, //info screen visibility
    totalSteps: 0, //number total of step
    language: localStorageService.getLanguage(), // default language
    languages: countryLanguageDictionary.getLanguages(), //List language for the webapp
    selectedLanguage: null, //selected language by the user
  }),
  computed: {},
  methods: {
    show() {
      const info = localStorageService.getInfo();
      this.showInFullScreen =
        info !== null && info !== undefined ? info.fullscreen : false;
      return info !== null && info !== undefined ? info.page : "";
    },
    goBackAction() {
      this.$router.back();
      document.activeElement.blur();
    },
    close() {
      this.dialog = false;
    },
    async load() {
      this.title = this.$route.meta.title;
      this.smidIcone = this.$route.meta.smidIcone;
      this.goBack = this.$route.meta.goBack;
      this.level = this.$route.meta.level;
      this.displayInfoScreen = this.$route.meta.displayInfoScreen;
      this.totalSteps = 4;
      this.language = localStorageService.getLanguage();
      if (this.language == null) {
        await this.getLocation();
      }
      var objLanguage = this.languages.find((x) => x.code === this.language);
      this.selectedLanguage = objLanguage.code;
    },
    changeLanguage() {
      localStorageService.setLanguage(this.selectedLanguage);
      this.language = localStorageService.getLanguage();
      window.location.reload();
    },
    /**
     * call the funtion load and save data on the localstorage (countryCode, language, device)
     * // Get user location via external API
     */
    async getLocation() {
      const res = await fetch("https://ipapi.co/json/");
      this.finalRes = await res.json().then((data) => {
        //save location
        localStorageService.setLanguage(
          countryLanguageDictionary.getLanguage(data.country_code)
        );
        this.language = countryLanguageDictionary.getLanguage(
          data.country_code
        );
        localStorageService.setCountryCode(data.country_code);
        localStorageService.setCountryName(data.country_name);
        localStorageService.setDeviceName(
          "navigator:" + navigator.userAgent + " _ip: " + data.ip
        );
      });
    },
  },
  async updated() {
    await this.load();
  },
  async mounted() {
    await this.load();
  },
};
</script>
<style>
.header-bar-style {
  margin-left: 20px;
  margin-right: 20px;
}
</style>
