<template>
  <v-container>
    <div class="country">
      <p>One step away from taking your photo</p>
    </div>
    <div class="text">
      <p>Country</p>
    </div>
    <template>
      <v-container>
        <v-row>
          <v-col cols="auto">
            <input
              class="recherche"
              v-model="selectedConty"
              v-on:change="loadProducts"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <hr class="trait" />
    <template>
      <div class="text">
        <span>Select document type you require</span>
      </div>
      <br />
      <v-container class="container">
        <v-row>
          <div class="button-grid">
            <v-btn
              v-model="selectedProduct"
              :items="products"
              item-text="name"
              persistent-hint
              return-object
              single-line
              v-for="product in products.slice(0, 4)"
              :key="product.id"
              @click="next(product)"
              >{{ product.name }}</v-btn
            >
          </div>
          <v-col cols="6"> </v-col>
        </v-row>
      </v-container>
    </template>
  </v-container>
</template>

<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
export default {
  name: "chooseDocument",
  data: () => ({
    selectedConty: localStorage.getItem("selectedCounty"),
    products: [],
    selectedProduct: null,
  }),
  methods: {
    loadProducts() {
      var userCountry = localStorageService.getCountryCode();
      var language = localStorageService.getLanguage();
      requestService
        .get(
          `/price/from-country/` +
            userCountry +
            `/to/` +
            this.selectedCounty.country_code,
          null,
          null,
          { language: language }
        )
        .then((data) => {
          this.products = data.data.result.map(function (x) {
            return {
              id: x.id,
              name: x.purpose.label,
              needSignature: x.purpose.signature_needed,
              price: x,
            };
            //   console.log(name);
          });
        });
    },
  },
  mounted() {
    this.loadProducts;
  },
};
</script>

<style scoped>
@media only screen and (max-width: 700px) {
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 5%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    margin: auto;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */
    text-align: center;
    letter-spacing: 1px;
    /* Secondary Gray */
    color: #4d4d4d;
    font-stretch: 100;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 20%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    z-index: 5;
    width: 42px;
    height: 60px;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
    height: 52px;
    width: 52px;
  }
  .btn {
    box-sizing: border-box;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 16px;

    width: 169px;
    height: 142px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;
    margin-left: 25px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .btn1 {
    box-sizing: border-box;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 16px;

    width: 169px;
    height: 142px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .recherche {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
    gap: 18px;
    position: absolute;
    width: 330px;
    height: 68px;
    margin-top: 280px;
    margin: auto;
    /* White */
    background: #ffffff;
    /* Accent */
    border: 2px solid #2fc977;
    border-radius: 4px;
  }
  .ronde {
    display: flex;
    justify-content: center;
    border-radius: 20%;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 25%;
    /* Tetriary Ligjht */

    color: #efefef;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
    margin-left: 5%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    margin: auto;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */
    text-align: center;
    letter-spacing: 1px;
    /* Secondary Gray */
    color: #4d4d4d;
    font-stretch: 100;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
  }
  .title {
    font-size: 3%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    z-index: 5;
    width: 42px;
    height: 60px;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
    height: 52px;
    width: 52px;
  }
  .btn {
    box-sizing: border-box;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 20px;

    width: 169px;
    height: 142px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 20%;
  }
  .btn1 {
    box-sizing: border-box;

    /* Auto layout */

    padding: 12px 32px 16px;
    gap: 20px;

    width: 169px;
    height: 142px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin-left: 20%;
  }
  .recherche {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 24px 16px;
    gap: 100px;
    position: absolute;
    width: 700px;
    height: 130px;

    margin-left: 5.2%;

    /* White */
    background: #ffffff;
    /* Accent */
    border: 2px solid #2fc977;
    border-radius: 4px;
  }
  .ronde {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    border-radius: 20%;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 20%;
    /* Tetriary Ligjht */

    color: #efefef;
  }
}
/**Pour les galaxy fold */
</style>
