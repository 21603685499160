const dictionary = {};
dictionary["Default"] = "en";

dictionary["AF"] = "ar";

dictionary["AL"] = "en";

dictionary["DZ"] = "ar";

dictionary["AS"] = "en";

dictionary["AD"] = "es";

dictionary["AO"] = "en";

dictionary["AQ"] = "en";

dictionary["AG"] = "en";

dictionary["AR"] = "es";

dictionary["AM"] = "en";

dictionary["AW"] = "en";

dictionary["AU"] = "en";

dictionary["AT"] = "de";

dictionary["AZ"] = "en";

dictionary["BS"] = "en";

dictionary["BH"] = "ar";

dictionary["BD"] = "en";

dictionary["BB"] = "en";

dictionary["BY"] = "ru";

dictionary["BE"] = "fr";

dictionary["BZ"] = "en";

dictionary["BJ"] = "fr";

dictionary["BM"] = "en";

dictionary["BT"] = "en";

dictionary["BO"] = "es";

dictionary["BA"] = "en";

dictionary["BW"] = "en";

dictionary["BV"] = "en";

dictionary["BR"] = "en";

dictionary["IO"] = "en";

dictionary["BN"] = "en";

dictionary["BG"] = "en";

dictionary["BF"] = "fr";

dictionary["BI"] = "fr";

dictionary["KH"] = "en";

dictionary["CM"] = "fr";

dictionary["CA"] = "en";

dictionary["CV"] = "en";

dictionary["KY"] = "en";

dictionary["CF"] = "fr";

dictionary["TD"] = "fr";

dictionary["CL"] = "es";

dictionary["CN"] = "en";

dictionary["CX"] = "en";

dictionary["CC"] = "en";

dictionary["CO"] = "es";

dictionary["KM"] = "fr";

dictionary["CG"] = "fr";

dictionary["CD"] = "fr";

dictionary["CK"] = "en";

dictionary["CR"] = "es";

dictionary["CI"] = "fr";

dictionary["HR"] = "en";

dictionary["CU"] = "es";

dictionary["CY"] = "tr";

dictionary["CZ"] = "en";

dictionary["DK"] = "en";

dictionary["DJ"] = "fr";

dictionary["DM"] = "en";

dictionary["DO"] = "es";

dictionary["EC"] = "es";

dictionary["EG"] = "ar";

dictionary["SV"] = "es";

dictionary["GQ"] = "fr";

dictionary["ER"] = "ar";

dictionary["EE"] = "et";

dictionary["ET"] = "en";

dictionary["FK"] = "en";

dictionary["FO"] = "en";

dictionary["FJ"] = "en";

dictionary["FI"] = "en";

dictionary["FR"] = "fr";

dictionary["GF"] = "fr";

dictionary["PF"] = "fr";

dictionary["TF"] = "fr";

dictionary["GA"] = "fr";

dictionary["GM"] = "en";

dictionary["GE"] = "en";

dictionary["DE"] = "de";

dictionary["GH"] = "en";

dictionary["GI"] = "en";

dictionary["GR"] = "en";

dictionary["GL"] = "en";

dictionary["GD"] = "en";

dictionary["GP"] = "fr";

dictionary["GU"] = "en";

dictionary["GT"] = "es";

dictionary["GN"] = "fr";

dictionary["GW"] = "en";

dictionary["GY"] = "en";

dictionary["HT"] = "fr";

dictionary["HM"] = "en";

dictionary["HN"] = "es";

dictionary["HK"] = "en";

dictionary["HU"] = "en";

dictionary["IS"] = "en";

dictionary["IN"] = "en";

dictionary["ID"] = "en";

dictionary["IR"] = "ar";

dictionary["IQ"] = "ar";

dictionary["IE"] = "en";

dictionary["IL"] = "en";

dictionary["IT"] = "it";

dictionary["JM"] = "en";

dictionary["JP"] = "en";

dictionary["JO"] = "ar";

dictionary["KZ"] = "ru";

dictionary["KE"] = "en";

dictionary["KI"] = "en";

dictionary["KP"] = "en";

dictionary["KR"] = "en";

dictionary["KW"] = "ar";

dictionary["KG"] = "ru";

dictionary["LA"] = "en";

dictionary["LV"] = "lv";

dictionary["LB"] = "ar";

dictionary["LS"] = "en";

dictionary["LR"] = "en";

dictionary["LY"] = "ar";

dictionary["LI"] = "en";

dictionary["LT"] = "en";

dictionary["LU"] = "fr";

dictionary["MO"] = "en";

dictionary["MK"] = "en";

dictionary["MG"] = "fr";

dictionary["MW"] = "en";

dictionary["MY"] = "en";

dictionary["MV"] = "en";

dictionary["ML"] = "fr";

dictionary["MT"] = "en";

dictionary["MH"] = "en";

dictionary["MQ"] = "fr";

dictionary["MR"] = "ar";

dictionary["MU"] = "en";

dictionary["YT"] = "fr";

dictionary["MX"] = "es";

dictionary["FM"] = "en";

dictionary["MD"] = "en";

dictionary["MD"] = "fr";

dictionary["MN"] = "en";

dictionary["MS"] = "en";

dictionary["MA"] = "ar";

dictionary["MZ"] = "en";

dictionary["MM"] = "en";

dictionary["NA"] = "en";

dictionary["NR"] = "en";

dictionary["NP"] = "en";

dictionary["NL"] = "nl";

dictionary["AN"] = "en";

dictionary["NC"] = "en";

dictionary["NZ"] = "en";

dictionary["NI"] = "es";

dictionary["NE"] = "fr";

dictionary["NG"] = "en";

dictionary["NU"] = "en";

dictionary["NF"] = "en";

dictionary["MP"] = "en";

dictionary["NO"] = "en";

dictionary["OM"] = "ar";

dictionary["PK"] = "en";

dictionary["PW"] = "en";

dictionary["PS"] = "ar";

dictionary["PA"] = "es";

dictionary["PG"] = "en";

dictionary["PY"] = "es";

dictionary["PE"] = "es";

dictionary["PH"] = "en";

dictionary["PN"] = "en";

dictionary["PL"] = "pl";

dictionary["PR"] = "es";

dictionary["QA"] = "ar";

dictionary["RE"] = "fr";

dictionary["RO"] = "en";

dictionary["RU"] = "en";

dictionary["RW"] = "fr";

dictionary["SH"] = "en";

dictionary["KN"] = "en";

dictionary["LC"] = "en";

dictionary["PM"] = "en";

dictionary["VC"] = "en";

dictionary["WS"] = "en";

dictionary["SM"] = "it";

dictionary["ST"] = "en";

dictionary["SA"] = "ar";

dictionary["SN"] = "fr";

dictionary["CS"] = "en";

dictionary["SC"] = "fr";

dictionary["SL"] = "en";

dictionary["SG"] = "en";

dictionary["SK"] = "en";

dictionary["SI"] = "en";

dictionary["SB"] = "en";

dictionary["SO"] = "ar";

dictionary["ZA"] = "en";

dictionary["GS"] = "en";

dictionary["ES"] = "es";

dictionary["LK"] = "en";

dictionary["SD"] = "ar";

dictionary["SR"] = "en";

dictionary["SJ"] = "en";

dictionary["SZ"] = "en";

dictionary["SE"] = "en";

dictionary["CH"] = "fr";

dictionary["SY"] = "ar";

dictionary["TW"] = "en";

dictionary["TJ"] = "en";

dictionary["TZ"] = "en";

dictionary["TH"] = "en";

dictionary["TL"] = "en";

dictionary["TG"] = "fr";

dictionary["TK"] = "en";

dictionary["TO"] = "en";

dictionary["TT"] = "en";

dictionary["TN"] = "ar";

dictionary["TR"] = "tr";

dictionary["TM"] = "en";

dictionary["TC"] = "en";

dictionary["TV"] = "en";

dictionary["UG"] = "en";

dictionary["UA"] = "ru";

dictionary["AE"] = "ar";

dictionary["GB"] = "en";

dictionary["US"] = "en";

dictionary["UM"] = "en";

dictionary["UY"] = "es";

dictionary["UZ"] = "en";

dictionary["VU"] = "fr";

dictionary["VE"] = "es";

dictionary["VN"] = "en";

dictionary["VG"] = "en";

dictionary["VI"] = "en";

dictionary["WF"] = "en";

dictionary["EH"] = "ar";

dictionary["YE"] = "ar";

dictionary["ZM"] = "en";

dictionary["ZW"] = "en";

function getLanguage(country) {
  return dictionary[country] !== undefined
    ? dictionary[country]
    : dictionary["Default"];
}

function getLanguages() {
  return [
    { lang: "Deutsch", code: "de" },
    { lang: "Eesti", code: "et" },
    { lang: "English", code: "en" },
    { lang: "Español", code: "es" },
    { lang: "Français", code: "fr" },
    { lang: "Italiano", code: "it" },
    { lang: "Latviešu", code: "lv" },
    { lang: "Nederlands", code: "nl" },
    { lang: "Polski", code: "pl" },
    { lang: "Русский", code: "ru" },
    { lang: "Türkçe", code: "tr" },
    { lang: "عرب", code: "ar" },
  ];
}

export const countryLanguageDictionary = {
  getLanguage,
  getLanguages,
};
