<template>
  <v-container>
    <div>
      <v-container>
        <v-card class="btn" variant="outlined">
          <v-card-item>
            <v-img
              class="noir"
              src="../../assets/images/Pro2.png"
              width="48"
              height="48"
              cover
            ></v-img>
          </v-card-item>
        </v-card>
      </v-container>
    </div>
    <br />

    <div>
      <v-container>
        <p class="your">
          {{ $t("yourPlan.title_your_plan", language) }}
        </p>
      </v-container>
    </div>
    <template>
      <v-container>
        <div>
          <span class="features">{{
            $t("yourPlan.what_feature", language)
          }}</span>
        </div>
        <hr class="line" />

        <v-container class="bg-surface-variant">
          <v-row no-gutters>
            <v-col cols="9">
              <v-sheet
                class="pa-2"
                style="
                  margin-left: -20px;
                  color: #949494;
                  font-family: Georama;
                  font-size: 14px;
                "
              >
                {{ $t("yourPlan.Compliance_guarantee", language) }}
              </v-sheet>
            </v-col>
            <v-col cols="3">
              <v-sheet class="pa-2 iconV">
                <v-img
                  width="15"
                  height="15"
                  src="../../assets/images/check-circle-Bold.png"
                ></v-img>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="9">
              <v-sheet
                class="pa-2"
                style="
                  margin-left: -20px;
                  color: #949494;
                  font-family: Georama;
                  font-size: 14px;
                "
              >
                {{ $t("yourPlan.Background_removal", language) }}
              </v-sheet>
            </v-col>
            <v-col cols="3">
              <v-sheet class="pa-2 iconV">
                <v-img
                  width="15"
                  height="15"
                  src="../../assets/images/check-circle-Bold.png"
                ></v-img>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="9">
              <v-sheet
                class="pa-2"
                style="
                  margin-left: -20px;
                  color: #949494;
                  font-family: Georama;
                  font-size: 14px;
                "
              >
                {{ $t("yourPlan.Photo_check_by_trained_expert", language) }}
              </v-sheet>
            </v-col>
            <v-col cols="3">
              <v-sheet class="pa-2 iconV">
                <v-img
                  width="15"
                  height="15"
                  src="../../assets/images/check-circle-Bold.png"
                ></v-img>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="9">
              <v-sheet
                class="pa-2"
                style="
                  margin-left: -20px;
                  color: #949494;
                  font-family: Georama;
                  font-size: 14px;
                "
              >
                {{ $t("yourPlan.Adjustments", language) }}
              </v-sheet>
            </v-col>
            <v-col cols="3">
              <v-sheet class="pa-2 iconV">
                <v-img
                  width="15"
                  height="15"
                  src="../../assets/images/check-circle-Bold.png"
                ></v-img>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
        <div v-if="more">
          <v-container class="bg-surface-variant">
            <v-row no-gutters>
              <v-col cols="9">
                <v-sheet
                  class="pa-2"
                  style="
                    margin-left: -20px;
                    color: #949494;
                    font-family: Georama;
                    font-size: 14px;
                  "
                >
                  {{ $t("yourPlan.Receive_immediately_in_e-mail", language) }}
                </v-sheet>
              </v-col>
              <v-col cols="3">
                <v-sheet class="pa-2 iconV">
                  <v-img
                    width="15"
                    height="15"
                    src="../../assets/images/check-circle-Bold.png"
                  ></v-img>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="9">
                <v-sheet
                  class="pa-2"
                  style="
                    margin-left: -20px;
                    color: #949494;
                    font-family: Georama;
                    font-size: 14px;
                  "
                >
                  {{ $t("yourPlan.Printable_template", language) }}
                </v-sheet>
              </v-col>
              <v-col cols="3">
                <v-sheet class="pa-2 iconV">
                  <v-img
                    width="15"
                    height="15"
                    src="../../assets/images/check-circle-Bold.png"
                  ></v-img>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="9">
                <v-sheet
                  class="pa-2"
                  style="
                    margin-left: -20px;
                    color: #949494;
                    font-family: Georama;
                    font-size: 14px;
                  "
                >
                  {{ $t("yourPlan.receive_exact", language) }}
                </v-sheet>
              </v-col>
              <v-col cols="3">
                <v-sheet class="pa-2 iconV">
                  <v-img
                    width="15"
                    height="15"
                    src="../../assets/images/check-circle-Bold.png"
                  ></v-img>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="9">
                <v-sheet
                  class="pa-2"
                  style="
                    margin-left: -20px;
                    color: #949494;
                    font-family: Georama;
                    font-size: 14px;
                  "
                >
                  {{ $t("yourPlan.Print_order", language) }}
                </v-sheet>
              </v-col>
              <v-col cols="3">
                <v-sheet class="pa-2 iconV">
                  <v-img
                    width="15"
                    height="15"
                    src="../../assets/images/check-circle-Bold.png"
                  ></v-img>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-container>
    </template>
    <div class="linbtn">
      <v-container>
        <div v-if="more == false">
          <hr class="line" />
        </div>
        <div
          class="d-flex justify-space-around align-center flex-column flex-sm-row"
          v-if="more == false"
        >
          <button class="take1" color="white" rounded @click="viewAll()">
            <span class="text5">{{ $t("yourPlan.view_all", language) }}</span>
          </button>
        </div>
      </v-container>
    </div>
    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <button class="take3" color="black" rounded @click="next">
        <span class="textpl"
          >{{ $t("yourPlan.proceed_price", language) }}
          {{ price.amount_in_local_currency }}
          {{ price.local_currency_symbol }}
        </span>
      </button>
    </div>
  </v-container>
</template>
<script>
import { localStorageService } from "@/services/localStorage.service";

export default {
  name: "YourPlan",
  data: () => ({
    price: 0,
    more: false,
    language: "",
  }),
  methods: {
    viewAll() {
      // Set 'more' flag to true to display more details
      this.more = true;
    },
    // Method to navigate to the next step
    next() {
      //check if the add-offer print exist else move directly in the confirmation order page
      var printPrice = this.price.optional_offers.find(
        (x) => x.product.send_by_post_needed
      );
      if (printPrice == undefined || printPrice == null) {
        // If no print offer, navigate to order confirmation
        this.$router.push({
          name: "YourOrderDetail",
        });
      } else {
        // If print offer exists, navigate to printed copy page
        this.$router.push({ name: "PrintedCopy" });
      }
    },
  },
  mounted() {
    localStorageService.setStripeClientSecret(null);
    this.price = localStorageService.getPrice();
    this.language = localStorageService.getLanguage();

    var countryCode = localStorageService.getCountryCode();
    if (
      this.price === null ||
      this.price === undefined ||
      countryCode === null ||
      countryCode === undefined
    ) {
      this.$router.push({ name: "welcomeMobile" });
      return;
    }

    var photoUidDetoured = localStorageService.getPhotoUidDetoured();
    if (photoUidDetoured === null || photoUidDetoured === undefined) {
      this.$router.push({
        name: "TakePhotoMobile",
        params: { countryCode: countryCode, priceId: this.price.id },
      });
      return;
    }

    var email = localStorageService.getEmailOwner();
    if (email === null || email === undefined) {
      this.$router.push({
        name: "EmailApp",
        params: { country: localStorageService.getCountryCode() },
      });
      return;
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Georama");
@media only screen and (max-width: 700px) {
  .text1 {
    text-transform: capitalize;
  }
  .linbtn {
    margin-top: -10%;
  }
  .free {
    margin-left: 80%;
    margin-top: -4%;
  }
  .block1 {
    margin-top: 5%;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;

    width: 300px;
    height: 52px;
    margin-left: 2%;
    margin-top: 25%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
    font-family: "Georama";
  }
  .textpl {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /*text-transform: capitalize;*/
    /* identical to box height, or 125% */

    text-align: center;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text5 {
    width: 51px;
    height: 20px;
    text-transform: lowercase;
    font-family: "Georama";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;

    /* identical to box height, or 125% */

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .features {
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    /* Primary dark */

    color: #000000;
  }
  .mini {
    text-transform: lowercase;
  }

  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 5%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Primary dark */

    color: #000000;
  }
  .document1 {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Accent */

    color: #2fc977;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 3%;
    margin-left: 6%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
    margin-bottom: 3px;
    z-index: 5;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
  }
  .btn {
    border: 2px solid #2fc977;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }

  .ronde {
    border-radius: 20%;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 20%;

    /* Tetriary Ligjht */

    color: #efefef;
  }
  .search-icon {
    align-self: flex-end;
    margin-top: -3px;
    margin-left: -18%;
  }
  .feabasic {
    margin-left: 75%;
    margin-top: -6%;
  }
  .feapro {
    margin-left: 90%;
    margin-top: -6%;
  }
  .textfeature {
    margin-left: -20px;
    color: #4d4d4d;
  }

  .gotit {
    margin-left: 85%;
    margin-top: -2%;
  }
  .iconV {
    margin-left: 20px;
    margin-top: 5%;
  }
  .line {
    /*width: 340px;*/
    height: 1px;
    margin-top: 6%;

    /* Tetriary Ligjht */

    background: #efefef;
    color: #efefef;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .lineview {
    /*width: 340px;*/
    margin-top: 20%;
    height: 1px;
    color: #efefef;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .take1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: 110px;
    height: 38px;
    margin-top: -5%;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .your {
    font-family: "Georama";
    font-style: normal;
    font-weight: 550;
    font-size: 16px;

    color: #949494;
    font-stretch: 96;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .text1 {
    text-transform: capitalize;
  }
  .linbtn {
    margin-top: -10%;
  }
  .free {
    margin-left: 80%;
    margin-top: -4%;
  }
  .block1 {
    margin-top: 5%;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;

    width: 350px;
    height: 52px;
    margin-left: 2%;
    margin-top: 15%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
    font-family: "Georama";
  }
  .textpl {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    /* identical to box height, or 125% */

    text-align: center;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text5 {
    width: 51px;
    height: 20px;
    text-transform: lowercase;
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    /* identical to box height, or 125% */

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .features {
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    /* Primary dark */

    color: #000000;
  }
  .mini {
    text-transform: lowercase;
  }

  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 5%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Primary dark */

    color: #000000;
  }
  .document1 {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Accent */

    color: #2fc977;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 3%;
    margin-left: 6%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
    margin-bottom: 3px;
    z-index: 5;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
  }
  .btn {
    border: 2px solid #2fc977;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }

  .ronde {
    border-radius: 20%;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 20%;

    /* Tetriary Ligjht */

    color: #efefef;
  }
  .search-icon {
    align-self: flex-end;
    margin-top: -3px;
    margin-left: -18%;
  }
  .feabasic {
    margin-left: 75%;
    margin-top: -6%;
  }
  .feapro {
    margin-left: 90%;
    margin-top: -6%;
  }
  .textfeature {
    margin-left: -20px;
    color: #4d4d4d;
  }

  .gotit {
    margin-left: 85%;
    margin-top: -2%;
  }
  .line {
    /* width: 690px;*/
    height: 1px;
    margin-top: 6%;
    margin-left: 3.5%;

    /* Tetriary Ligjht */

    background: #efefef;
    color: #efefef;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .lineview {
    /*width: 690px;*/
    margin-top: 10%;
    height: 1px;
    left: 0px;
    top: 22px;

    color: #efefef;
  }
  .take1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-right: 5%;
    gap: 10px;

    width: 116px;
    height: 38px;
    margin-top: -3%;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .your {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    color: #949494;
    font-stretch: 96;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .text1 {
    text-transform: capitalize;
  }
  .linbtn {
    margin-top: -10%;
  }
  .free {
    margin-left: 80%;
    margin-top: -4%;
  }
  .block1 {
    margin-top: 5%;
  }
  .take3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "Georama";
    gap: 10px;

    width: 300px;
    height: 52px;
    margin-left: 30%;
    margin-top: 25%;

    /* Primary dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .textpl {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    /* identical to box height, or 125% */

    text-align: center;

    /* White */

    color: #ffffff;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .text5 {
    width: 51px;
    height: 20px;
    text-transform: lowercase;
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-right: 12%;
    /* identical to box height, or 125% */

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 96;

    text-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .features {
    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* Primary dark */

    color: #000000;
  }
  .mini {
    text-transform: lowercase;
  }

  .text {
    width: 350px;
    height: 28px;
    margin-top: 10%;
    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-left: 5%;
    /* identical to box height, or 140% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .document {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Primary dark */

    color: #000000;
  }
  .document1 {
    left: 4.73%;
    right: 4.73%;
    top: 58.82%;
    bottom: 14.71%;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;
    letter-spacing: -0.05em;

    /* Accent */

    color: #2fc977;
  }
  .position {
    margin-right: 30%;
    font-family: "Georama";
    text-transform: capitalize;
    color: #000000;
    font-style: normal;
    font-weight: 500;
  }
  .title {
    font-size: 3%;
  }
  .country {
    width: 350px;
    height: 80px;
    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
    margin-top: 3%;
    margin-left: 6%;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .noir {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
    margin-bottom: 3px;
    z-index: 5;
  }
  .ellipse {
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    margin-bottom: -73px;
  }
  .btn {
    border: 2px solid #2fc977;
  }
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }

  .ronde {
    border-radius: 20%;
  }
  .pen {
    right: 5.33%;
    top: 8.34%;
    bottom: 8.34%;
    color: black;

    /* Secondary Gray */
  }
  .trait {
    margin-top: 20%;

    /* Tetriary Ligjht */

    color: #efefef;
  }
  .search-icon {
    align-self: flex-end;
    margin-top: -3px;
    margin-left: -18%;
  }
  .feabasic {
    margin-left: 75%;
    margin-top: -6%;
  }
  .feapro {
    margin-left: 90%;
    margin-top: -6%;
  }
  .textfeature {
    margin-left: -20px;
    color: #4d4d4d;
  }

  .gotit {
    width: 18px;
    height: 18px;
    margin-left: 100%;
    margin-top: -2;
  }
  .line {
    /*width: 320px;*/
    height: 1px;
    margin-top: 6%;
    margin-left: 3.5%;

    /* Tetriary Ligjht */

    background: #efefef;
    color: #efefef;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .lineview {
    /* width: 320px;*/
    margin-top: 20%;
    height: 1px;
    left: 0px;
    top: 22px;

    /* Tetriary Ligjht */

    color: #efefef;
  }
  .take1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 125px;
    gap: 10px;

    width: 116px;
    height: 38px;
    margin-top: -5%;
    margin-left: 25%;
    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 2px solid #efefef;
    border-radius: 28px;
  }
  .your {
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    /* Secondary Gray */

    color: #949494;
    font-stretch: 96;
  }
}
</style>
