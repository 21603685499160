<template>
  <v-app dark>
    <div class="header-bar-style">
      <div
        class="d-flex justify-space-between bg-surface-variant"
        style="background: white"
      >
        <v-sheet></v-sheet>
        <!-- logo or title -->
        <v-sheet class="ma-1 pa-2 text-h6" v-if="title !== undefined">
          {{ $t("headerMobile." + title, language) }}
        </v-sheet>
        <v-sheet class="ma-2 pa-0" v-else-if="smidIcone">
          <v-img
            src="../assets/images/logo-smartphone-id-sm.png"
            width="170px"
          ></v-img>
        </v-sheet>
        <v-sheet>
          <v-select
            :items="languages"
            item-text="lang"
            item-value="code"
            variant="underlined"
            v-on:change="changeLanguage"
            v-model="selectedLanguage"
            style="width: 100px"
          ></v-select
        ></v-sheet>
      </div>
      <!-- Slider for progression status -->
      <v-progress-linear
        :value="(level * 100) / totalSteps"
        bg-color="#efefef"
        color="#2fc977"
      ></v-progress-linear>
      <v-sheet
        class="d-flex justify-space-around"
        style="background: transparent"
      >
        <v-chip
          v-for="index in totalSteps"
          :key="index"
          class="ma-2"
          color="#2fc977"
          label
          :outlined="index > level"
          :text-color="index > level ? '#2fc977' : '#fff'"
          >{{ index }}</v-chip
        >
      </v-sheet>
    </div>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import { localStorageService } from "@/services/localStorage.service";
import { countryLanguageDictionary } from "@/services/countryLanguage.dictionary";
export default {
  components: {},

  data: () => ({
    smidIcone: false, //smartphone id logo in the middle top. displayed if title is missing
    title: null, //The title in the header
    goBack: true, //allow go back. show the button back in the coner left top
    level: 0, // from 0 to 100, indicate the progression in the sell step
    displayInfoScreen: false, // allow the display of the info screen
    showInFullScreen: false, //display the help screen in fullscreen or not
    dialog: false, //info screen visibility
    totalSteps: 0, //number total of step
    language: localStorageService.getLanguage(), // default language
    languages: countryLanguageDictionary.getLanguages(), //List language for the webapp
    selectedLanguage: null, //selected language by the user
  }),
  computed: {},
  methods: {
    show() {
      const info = localStorageService.getInfo();
      this.showInFullScreen =
        info !== null && info !== undefined ? info.fullscreen : false;
      return info !== null && info !== undefined ? info.page : "";
    },
    goBackAction() {
      this.$router.back();
      document.activeElement.blur();
    },
    close() {
      this.dialog = false;
    },
    async load() {
      this.title = this.$route.meta.title;
      this.smidIcone = this.$route.meta.smidIcone;
      this.goBack = this.$route.meta.goBack;
      this.level = this.$route.meta.level;
      this.displayInfoScreen = this.$route.meta.displayInfoScreen;
      this.totalSteps = 4;
      this.language = localStorageService.getLanguage();
      if (this.language == null) {
        await this.getLocation();
      }
      var objLanguage = this.languages.find((x) => x.code === this.language);
      this.selectedLanguage = objLanguage.code;
    },
    changeLanguage() {
      localStorageService.setLanguage(this.selectedLanguage);
      this.language = localStorageService.getLanguage();
      window.location.reload();
    },
    /**
     * call the funtion load and save data on the localstorage (countryCode, language, device)
     * // Get user location via external API
     */
    async getLocation() {
      const res = await fetch("https://ipapi.co/json/");
      this.finalRes = await res.json().then((data) => {
        //save location
        localStorageService.setLanguage(
          countryLanguageDictionary.getLanguage(data.country_code)
        );
        this.language = countryLanguageDictionary.getLanguage(
          data.country_code
        );
        localStorageService.setCountryCode(data.country_code);
        localStorageService.setCountryName(data.country_name);
        localStorageService.setDeviceName(
          "navigator:" + navigator.userAgent + " _ip: " + data.ip
        );
      });
    },
  },
  async updated() {
    await this.load();
  },
  async mounted() {
    await this.load();
  },
};
</script>
<style>
.header-bar-style {
  margin-left: 0px;
  margin-right: 0px;
  background: transparent;
}
</style>
