import Vue from "vue";
import VueRouter from "vue-router";
//import CountryProductChoice from "@/views/CountryProductChoice.vue";
import QrCodeApp from "@/views/QrCodeApp.vue";
import QrCode from "@/views/desktop/QrCode";
import TakePhoto from "@/views/desktop/TakePhoto.vue";
import MessageApp from "@/views/MessageApp.vue";
import NotFound from "@/views/NotFound.vue";
import TraitePhotoApp from "@/views/TraitePhotoApp.vue";
import ChoixPhoto from "@/views/ChoixPhoto.vue";
import ValidationCommande from "@/views/ValidationCommande.vue";
import DetailsPaiement from "@/views/DetailsPaiement.vue";
import SignatureApp from "@/views/desktop/Signature";
import PopUpApp from "@/views/PopUpApp.vue";
import ShootPhoto from "@/views/ShootPhoto.vue";
import DataLinked from "@/views/DataLinked.vue";
import Payment from "@/views/Payment.vue";
import homeApp from "@/views/notuses/homeApp.vue";
import loadingApp from "@/views/notuses/loadingApp.vue";
import chooseCountry from "@/views/notuses/chooseCountry.vue";
import chooseDocument from "@/views/notuses/chooseDocument.vue";
import EditCountryDocument from "@/views/notuses/EditCountryDocument.vue";
import SelectPhoto from "@/views/notuses/SelectPhoto.vue";
import EmailApp from "@/views/mobile/EmailApp.vue";
import SearchApp from "@/views/notuses/SearchApp.vue";
import SearchEdit from "@/views/notuses/SearchEdit.vue";
import ChoosePlan from "@/views/mobile/ChoosePlan.vue";
import TakePhotoMobile from "@/views/mobile/TakePhotoMobile.vue";
import welcomeMobile from "@/views/mobile/welcome.vue";
//import EditPlan from "@/views/mobile/EditPlan.vue";
import EditPlan from "@/views/notuses/EditPlan.vue";
import PrintedCopy from "@/views/mobile/PrintedCopy.vue";
import EditPrintedCopy from "@/views/notuses/EditPrintedCopy.vue";
import AddressLivraison from "@/views/mobile/AddressLivraison.vue";
import YourPlan from "@/views/mobile/YourPlan.vue";
import YourOrder from "@/views/notuses/YourOrder.vue";
import BravoApp from "@/views/mobile/BravoApp.vue";
import YourOrderDetail from "@/views/mobile/YourOrderDetail.vue";
import SignatureMob from "@/views/mobile/SignatureMob.vue";
import Welcome from "@/views/desktop/Welcome.vue";
import TraitePhoto from "@/views/desktop/TraitePhoto.vue";
import AddressLivraisonDesk from "@/views/desktop/AddressLivraisonDesk.vue";
import RecapOrder from "@/views/desktop/RecapOrder.vue";
import ChoosePlanDesk from "@/views/desktop/ChoosePlanDesk.vue";
import BravoAppDesk from "@/views/desktop/BravoAppDesk.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/images-linked",
    name: "DataLinked",
    component: DataLinked,
  },
  {
    path: "/takephoto/:uid",
    name: "TakePhoto",
    component: TakePhoto,
  },
  {
    path: "/message",
    name: "MessageApp",
    component: MessageApp,
  },
  {
    path: "/error",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/qrcodeapp",
    name: "QrCodeApp",
    component: QrCodeApp,
  },
  {
    path: "/suivie",
    name: "TraitePhotoApp",
    component: TraitePhotoApp,
  },
  {
    path: "/validationCommande",
    name: "ValidationCommande",
    component: ValidationCommande,
  },
  {
    path: "/detailsPaiement",
    name: "DetailsPaiement",
    component: DetailsPaiement,
  },
  {
    path: "/ShootPhoto",
    name: "ShootPhoto",
    component: ShootPhoto,
  },
  {
    path: "/popup",
    name: "PopUpApp",
    component: PopUpApp,
  },
  {
    path: "/signature/:uid",
    name: "SignatureApp",
    component: SignatureApp,
  },

  {
    path: "/choixPhoto",
    name: "ChoixPhoto",
    component: ChoixPhoto,
  },
  {
    path: "/welcome",
    name: "homeApp",
    component: homeApp,
  },

  //mobile path
  {
    path: "/mobile",
    name: "welcomeMobile",
    component: welcomeMobile,
  },
  {
    path: "/mobile/photo/:priceId/:countryCode",
    name: "TakePhotoMobile",
    component: TakePhotoMobile,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 1,
      displayInfoScreen: true,
      previous: ["welcomeMobile"],
    },
  },
  {
    path: "/mobile/signature/:country",
    name: "SignatureMob",
    component: SignatureMob,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 1,
      previous: ["TakePhotoMobile"],
    },
  },
  {
    path: "/mobile/authentification/:country",
    name: "EmailApp",
    component: EmailApp,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 2,
      previous: ["TakePhotoMobile", "SignatureMob"],
    },
  },
  {
    path: "/mobile/plan/:country",
    name: "YourPlan",
    component: YourPlan,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 2,
      previous: ["EmailApp"],
    },
  },
  {
    path: "/mobile/choose-plan/:country",
    name: "ChoosePlan",
    component: ChoosePlan,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 2,
      previous: ["EmailApp"],
    },
  },

  {
    path: "/mobile/print/:country",
    name: "PrintedCopy",
    component: PrintedCopy,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 3,
      previous: ["ChoosePlan", "YourPlan"],
    },
  },
  {
    path: "/mobile/address/:country",
    name: "AddressLivraison",
    component: AddressLivraison,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 3,
      previous: ["PrintedCopy"],
    },
  },
  {
    path: "/mobile/your-order-detail/:country",
    name: "YourOrderDetail",
    component: YourOrderDetail,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 4,
      previous: ["PrintedCopy", "AddressLivraison"],
    },
  },
  {
    path: "/loading",
    name: "loadingApp",
    component: loadingApp,
  },
  {
    path: "/country",
    name: "chooseCountry",
    component: chooseCountry,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/document",
    name: "chooseDocument",
    component: chooseDocument,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/edit",
    name: "EditCountryDocument",
    component: EditCountryDocument,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/select",
    name: "SelectPhoto",
    component: SelectPhoto,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/search",
    name: "SearchApp",
    component: SearchApp,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/searchedit",
    name: "SearchEdit",
    component: SearchEdit,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/edit-plan",
    name: "EditPlan",
    component: EditPlan,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/edit-printed",
    name: "EditPrintedCopy",
    component: EditPrintedCopy,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/address",
    name: "AddressLivraison",
    component: AddressLivraison,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/your-order",
    name: "YourOrder",
    component: YourOrder,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: true,
      level: 30,
      rightBtn: {
        icon: "mdi-help-circle-outline",
      },
    },
  },
  {
    path: "/mobile/winner",
    name: "BravoApp",
    component: BravoApp,
    meta: {
      layout: "mobile",
      smidIcone: true,
      goBack: false,
      level: 30,
    },
  },

  //desktop path
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/desktop/photo/:priceId/:countryCode",
    name: "QrCode",
    component: QrCode,
    meta: {
      layout: "desktop",
      smidIcone: true,
      goBack: true,
      level: 1,
      displayInfoScreen: true,
      previous: ["Welcome"],
    },
  },
  {
    path: "/desktop/suivie",
    name: "TraitePhoto",
    component: TraitePhoto,
    meta: {
      layout: "desktop",
      smidIcone: true,
      goBack: true,
      level: 2,
      displayInfoScreen: true,
      previous: ["QrCode"],
    },
  },
  {
    path: "/desktop/choosePlanDesk",
    name: "ChoosePlanDesk",
    component: ChoosePlanDesk,
    meta: {
      layout: "desktop",
      smidIcone: true,
      goBack: true,
      level: 2,
      displayInfoScreen: true,
      previous: ["TraitePhoto"],
    },
  },
  {
    path: "/desktop/addressLivraisonDesk",
    name: "AddressLivraisonDesk",
    component: AddressLivraisonDesk,
    meta: {
      layout: "desktop",
      smidIcone: true,
      goBack: true,
      level: 3,
      displayInfoScreen: true,
      previous: ["TraitePhoto, ChoosePlanDesk"],
    },
  },
  {
    path: "/desktop/recapOrder",
    name: "RecapOrder",
    component: RecapOrder,
    meta: {
      layout: "desktop",
      smidIcone: true,
      goBack: true,
      level: 4,
      displayInfoScreen: true,
      previous: ["AddressLivraisonDesk"],
    },
  },
  {
    path: "/desktop/bravoAppDesk",
    name: "BravoAppDesk",
    component: BravoAppDesk,
    meta: {
      layout: "desktop",
      smidIcone: true,
      goBack: true,
      level: 4,
      displayInfoScreen: true,
      previous: ["RecapOrder"],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  if (
    from !== undefined &&
    from.name !== null &&
    to.meta !== undefined &&
    to.meta.previous !== undefined &&
    to.meta.previous.find((element) => element == from.name) === undefined
  ) {
    window.location.reload();

    console.log(from.name !== "null");
    console.log(to.meta.previous.find((element) => element == from.name));
  }
});

export default router;
