<template>
  <v-container>
    <v-span class="top" v-if="photoReady">{{
      $t("photoShootMobile.take_photo_mobile", language)
    }}</v-span>
    <v-span class="top" v-else>{{
      $t("beforePhoto.title_before_photo", language)
    }}</v-span>
    <v-row no-gutters>
      <v-col>
        <v-sheet>
          <div class="prise">
            <photo-shooter-mob
              ref="shooter"
              @photoReady="photoReady = $event"
              @cacherImage="cacherImage"
              :price="price"
            ></photo-shooter-mob>
          </div>
          <div class="prise1 justify-center d-flex">
            <v-btn
              style="
                background-color: black;
                color: white;
                font-family: Georama;
                text-transform: none;
              "
              block
              rounded
              v-if="photoReady"
              @click="saveAndContinue"
              :disabled="uploading"
              :loading="uploading"
            >
              {{ $t("photoShootMobile.yes_continue", language) }}
            </v-btn>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
    <div>
      <!--<div v-show="imageVisible">
        <v-img src="../../assets/images/reduced_size_gif.gif" alt="" />
      </div>-->

      <div>
        <v-sheet v-if="imageVisible" class="d-flex justify-center">
          <img
            v-for="(image, index) in images"
            :key="index"
            :src="image.url"
            v-show="index === currentIndex2"
            max-height="500"
            max-width="500"
            width="500"
            height="500"
            cover
          />
        </v-sheet>
        <v-sheet v-if="imageVisible" class="d-flex justify-center">
          <p style="font-family: Georama" class="font-weight-bold">
            {{ slideItem }}
          </p>
        </v-sheet>
      </div>

      <!-- <v-sheet v-if="imageVisible" class="d-flex justify-center">
      <p style="font-family: Georama" class="font-weight-bold">
        {{ images[currentIndex2].title }}
      </p>
    </v-sheet> -->

      <!--<v-img
        class="pictureCss"
        v-show="imageVisible"
        src="../../assets/images/home-removebg-preview.png"
      ></v-img> -->
    </div>
  </v-container>
</template>

<script>
import PhotoShooterMob from "@/components/PhotoShooterMob";
import { localStorageService } from "@/services/localStorage.service";
import { requestService } from "@/services/request.service";
import { countryLanguageDictionary } from "@/services/countryLanguage.dictionary";
//import myVideo from "vue-video";
export default {
  name: "TakePhoto",
  data() {
    return {
      photoReady: false,
      uploading: false,
      photoUrl: null,
      price: null,
      dialog: false,
      qrcode: null,
      imageVisible: true,
      imageV: false,
      language: "",
      slides: [],
      images: [],
      currentIndex1: 0,
      currentIndex2: 0,
      slideItem: "",
      imageItem: "",
      video: {
        sources: [
          {
            src: "https://storage.googleapis.com/smid-static/video.mp4",
            type: "video/mp4",
          },
        ],
        options: {
          autoplay: "",
          volume: 0.6,
          playsinline: "",
        },
      },
    };
  },
  components: {
    PhotoShooterMob,
    //myVideo,
  },
  methods: {
    startCarousel() {
      setInterval(() => {
        this.currentIndex1 = (this.currentIndex1 + 1) % this.slides.length;
        this.slideItem = this.slides[this.currentIndex1];
      }, 4000); // Changer la diapositive toutes les 2 secondes (ajustez la durée selon vos besoins)
      setInterval(() => {
        this.currentIndex2 = (this.currentIndex2 + 1) % this.images.length;
        this.imageItem = this.images[this.currentIndex2];
      }, 2000);
    },
    cacherImage() {
      this.imageVisible = false;
      this.imageV = true;
    },
    afficherSuivant() {
      if (this.currentIndex < this.slides.length - 1) {
        this.currentIndex++;
      }
    },

    async saveAndContinue() {
      this.uploading = true;
      this.$refs.shooter.stopCameraStream();
      this.$refs.shooter.save();
      //need to pin photo on the qrcode
      if (this.$route.params.uid !== undefined) {
        const photoFormData = new FormData();
        const photoBlob = await (
          await fetch(this.$store.state.photoUrl)
        ).blob();
        photoFormData.append("file", photoBlob);
        photoFormData.append("type", "photo");
        requestService.post(`/file/upload`, photoFormData).then((data) => {
          this.$store.commit("photoUid", data.data.uid);
          requestService
            .get(
              `/service/pin-photo-qrcode/${data.data.uid}/${this.$route.params.uid}`
            )
            .then(() => {
              requestService
                .get(`/service/qrcode-check/${this.$route.params.uid}`)
                .then((data) => {
                  this.qrCode = data.data;
                  if (data === null) {
                    //Qrcode already consumed
                    this.$router.push({ name: "QrCodeConsumed" });
                  } else if (data.data.needPhoto) {
                    //Photo is not needed
                    if (data.data.needSignature) {
                      this.$router.push({
                        name: "SignatureApp",
                        params: { id: this.$route.params.uid },
                      });
                    } else {
                      this.$router.push({ name: "DataLinked" });
                    }
                  }
                });
            });
        });
      } else {
        this.$router.push({ name: "ErrorPage" });
        this.uploading = false;
      }
    },
    load() {
      localStorageService.setInfo({
        page: "alert-message-1",
        fullscreen: false,
      });
      //need to take photo and link it with the qrcode (uid)
      if (this.$route.params.uid !== undefined) {
        requestService
          .get(`/service/qrcode-check/${this.$route.params.uid}`)
          .then((data) => {
            this.qrCode = data.data;
            this.price = data.data.price;

            requestService.get(
              `/service/running-qrcode/${this.$route.params.uid}/1`
            );

            if (data === null) {
              //Qrcode already consumed
              this.$router.push({ name: "QrCodeConsumed" });
            } else if (!data.data.needPhoto) {
              //Photo is not needed
              if (data.data.needSignature) {
                this.$router.push({
                  name: "SignatureApp",
                  params: { id: this.$route.params.uid },
                });
              } else {
                this.$router.push({ name: "ErrorPage" });
              }
            } else {
              this.photoUrl = data.data.photoUrl;
            }
          });
      }
    },

    /**
     * call the funtion load and save data on the localstorage (countryCode, language, device)
     */
    async getLocation() {
      const res = await fetch("https://ipapi.co/json/");
      this.finalRes = await res.json().then((data) => {
        //Load countries
        this.load(data.country_code);

        //save location
        localStorageService.setLanguage(
          countryLanguageDictionary.getLanguage(data.country_code)
        );
        this.language = countryLanguageDictionary.getLanguage(
          data.country_code
        );
        localStorageService.setCountryCode(data.country_code);
        localStorageService.setCountryName(data.country_name);
        localStorageService.setDeviceName(
          "navigator:" + navigator.userAgent + " _ip: " + data.ip
        );
      });
    },
  },

  async mounted() {
    //localStorageService.clearAll();
    await this.getLocation();
    this.load();
    const title1 = this.$t("beforePhoto.neutral_face", this.language);
    const title2 = this.$t("beforePhoto.hair_behind", this.language);
    const title3 = this.$t("beforePhoto.off_glasses", this.language);
    const title4 = this.$t("beforePhoto.even_light", this.language);
    this.slides = [title1, title2, title3, title4];
    this.slideItem = title1;
    (this.images = [
      { url: "https://storage.googleapis.com/smid-static/smile.png" },
      { url: "https://storage.googleapis.com/smid-static/normal.png" },
      { url: "https://storage.googleapis.com/smid-static/hair.png" },
      { url: "https://storage.googleapis.com/smid-static/normal.png" },
      { url: "https://storage.googleapis.com/smid-static/glasses.png" },
      { url: "https://storage.googleapis.com/smid-static/normal.png" },
      { url: "https://storage.googleapis.com/smid-static/shadow.png" },
      { url: "https://storage.googleapis.com/smid-static/normal.png" },
      // Ajoutez autant d'images que vous le souhaitez
    ]),
      (this.imageItem = {
        url: "https://storage.googleapis.com/smid-static/smile.png",
      });
    this.startCarousel();
  },
};
</script>

<style scoped>
body {
  overflow: hidden;
}
.pictureCss {
  width: 100%;
  margin-right: auto;
  margin-left: 4%;
  height: 100%;
}

.qrcode {
  margin-top: 50px;
}
.top {
  margin-top: 2%;
  height: 40px;

  font-family: "Georama";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  /* Primary dark */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.services li {
  margin: 30px;
  margin-left: 70%;
  margin-top: -15px;
}

.prise1 {
  margin-left: 26%;
  margin-right: 24%;
  margin-top: 2%;
  font-family: "Georama";
}

.vidapp {
  margin-left: 70%;
  margin-top: -300px;
}

.vidapp2 {
  color: black;
}

.text {
  color: black;
}

.lien {
  margin-top: 10%;
  color: black;
  margin-left: 10%;
}

.services {
  margin-top: -20%;
}

.rigth {
  margin-left: 10%;
}

.rigth1 {
  margin-left: 4%;
}

.rigth2 {
  margin-left: 10%;
}

.photo {
  margin-right: 50%;
  margin-top: 5%;
}

.decaler {
  margin-left: 10%;
  margin-top: 10%;
}
.pic {
  width: 70%;
  height: 20%;
  margin-left: 18%;
}
.prise {
  margin-right: 2%;
  font-family: "Georama";
}
</style>
