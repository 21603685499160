<template>
  <v-container>
    <template class="container-fluid px-0">
      <v-toolbar flat>
        <v-toolbar-title class="font-weight-black" style="font-family: Georama"
          >Smartphone iD</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <div>
      <v-container fluid>
        <v-row>
          <div class="font-weight-black" style="font-family: Georama">
            <h5>{{ $t("homeMobile.mess_welcome", language) }}</h5>
          </div>
        </v-row>
        <v-row class="align-center">
          <v-col cols="2">
            <v-img
              class="vector align-self-auto"
              :src="loadImg(selectedCounty.country_code.toLowerCase())"
            ></v-img>
          </v-col>
          <v-col>
            <v-select
              v-model="selectedCounty"
              :items="countries"
              item-text="country_name"
              :label="$t('homeMobile.choose_country', language)"
              persistent-hint
              return-object
              single-line
              v-on:change="handleSelectChange"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <div class="font-weight-black" style="font-family: Georama">
            <h5>{{ $t("homeMobile.mess_welcome1", language) }}</h5>
          </div>
        </v-row>
        <v-row class="align-center">
          <v-col cols="2">
            <v-img
              class="vector align-self-auto"
              :src="loadImg(selectedCounty.country_code.toLowerCase())"
            ></v-img>
          </v-col>
          <v-col>
            <v-select
              v-model="selectedProduct"
              :items="products"
              item-text="name"
              :label="$t('homeMobile.choose_document', language)"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="space-around">
          <div class="text-center">
            <v-btn
              style="font-family: Georama; text-transform: none"
              @click="next(null)"
              class="font-weight-black"
              rounded
              color="with"
              dark
            >
              {{ $t("homeMobile.take_photo", language) }}
            </v-btn>
          </div>
        </v-row>
      </v-container>
    </div>
    <br />
    <br />
  </v-container>
</template>

<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
import { countryLanguageDictionary } from "@/services/countryLanguage.dictionary";
//import { isMobile } from "mobile-device-detect";
export default {
  //components: { HeaderApp },
  name: "CountryProductChoice",
  data: () => ({
    countries: [],
    selectedCounty: null,
    products: [],
    selectedProduct: null,
    language: "",
    userCountry: "FR",
    images: [],
    countryFlag: "",
    countryCode: "",
  }),
  methods: {
    handleSelectChange() {
      this.selectedProduct = null;
      this.loadProducts();
      this.loadImg(this.selectedCounty.country_code.toLowerCase());
    },
    /**
     * call the funtion load and save data on the localstorage (countryCode, language, device)
     */
    async getData() {
      const res = await fetch("https://ipapi.co/json/");
      this.finalRes = await res.json().then((data) => {
        //Load countries
        this.userCountry = data.country_code;
        this.load(data.country_code);

        //save location
        localStorageService.setLanguage(
          countryLanguageDictionary.getLanguage(data.country_code)
        );
        this.language = countryLanguageDictionary.getLanguage(
          data.country_code
        );
        localStorageService.setCountryCode(data.country_code);
        localStorageService.setDeviceName(
          "navigator:" + navigator.userAgent + " _ip: " + data.ip
        );
      });
    },

    loadImg(img) {
      return this.images("./" + img + ".jpg");
    },
    /**
     * Load the list of countries
     */
    async load(countryCode) {
      this.countryFlag = "";
      //this.countryCode = localStorageService.getCountryCode().toLowerCase();
      // this.countryCode = "sn";
      var language = localStorageService.getLanguage();
      requestService
        .get(`/country/customized?country_code=${countryCode}`, null, null, {
          language: language,
        })
        .then((data) => {
          this.countries = data.data.Countries;
          this.countries.forEach((country) => {
            if (country.country_code === countryCode) {
              this.selectedCounty = country;
              this.loadProducts(countryCode);
              this.countryCode = this.selectedCounty.country_code.toLowerCase();
              // alert(this.countryCode);
              this.loadImg(countryCode.toLowerCase());

              //load the product for the selected country
            }
          });
        });
      this.countryFlag =
        "../../assets/images/Drapeaux/" + this.countryCode + ".jpg";
      //this.language = localStorageService.getLanguage();
      this.images = require.context(
        "../../assets/images/Drapeaux/",
        false,
        /\.jpg$/
      );
    },

    /**
     * Load the list of products for the selected country
     */
    loadProducts() {
      var language = countryLanguageDictionary.getLanguage(this.userCountry);
      requestService
        .get(
          `/price/from-country/` +
            this.userCountry +
            `/to/` +
            this.selectedCounty.country_code,
          null,
          null,
          { language: language }
        )
        .then((data) => {
          this.products = data.data.result.map(function (x) {
            return {
              id: x.id,
              name: x.purpose.label,
              needSignature: x.purpose.signature_needed,
              price: x,
            };
          });
        });
    },

    /**
     * Navigate to the next screen. if you are using pc you will be redirected to the qrCode screen otherwise it will be the takePhoto page
     */
    next(price = null) {
      if (price == null && this.selectedProduct == null) {
        // price("Vous devez choisir un produit.");
        alert("Vous devez choisir un produit.");
      }
      if (price == null) {
        price = this.selectedProduct;
      }

      localStorageService.setPrice(price.price);
      localStorage.setItem("selectedProduct", this.selectedProduct.name);
      localStorage.setItem("selectedCountry", this.selectedCounty.country_name);
      // window.open(
      //   process.env.VUE_APP_URL +
      //     "/mobile/photo/" +
      //     price.id +
      //     "/" +
      //     this.selectedCounty.country_code,
      //   "_blank"
      // );
      this.$router.push({
        name: "TakePhotoMobile",
        params: {
          priceId: price.id,
          countryCode: this.selectedCounty.country_code,
        },
      });
    },
  },
  computed: {
    countryCode() {
      if (this.selectedCounty) {
        return this.selectedCounty.country_code.toLowerCase();
      }
      return ""; // Valeur par défaut lorsque selectedCounty n'est pas défini
    },
  },
  mounted() {
    localStorageService.clearAll();
    this.getData();
  },
};
</script>

<style scoped>
.pays {
  margin-left: 22%;
  height: 2px;
}
.vector {
  align-self: flex-start;
  width: 16px;
  height: 16px;
  border-radius: 10%;
}
.font2 {
  margin-left: 600px;
  margin-top: -100px;
  width: 1100px;
}
.button2 {
  margin-left: 45%;
  margin-top: -70px;
}
.pied {
  margin-left: 350px;
  margin-top: -100px;
}
.milliongauche {
  margin-top: 200px;
}
.pieds {
  margin-left: 800px;
  margin-top: -100px;
}
.pieds > .hk {
  margin-left: 30px;
  margin-top: -20px;
  margin-bottom: 5px;
}
.piedss {
  margin-left: 1150px;
  margin-top: -100px;
}
.piedss > .hk {
  margin-left: 30px;
  margin-top: -20px;
  margin-bottom: 10px;
}
.rigth {
  margin-left: 3%;
}
.rigth1 {
  margin-left: 3%;
}
.rigth2 {
  margin-left: 2%;
}
.rigth3 {
  margin-left: 6%;
}
.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 560px;
}
v-list-item-title {
  margin-right: 1em;
}
</style>
