<template>
  <v-container
    style="
      position: absolute;
      width: 90%;
      height: 100%;
      max-width: inherit;
      padding-bottom: 0px;
      left: 5%;
    "
  >
    <v-row no-gutters style="height: 100%">
      <v-col cols="8" style="margin-top: 5%" class="d-flex align-end">
        <v-sheet
          class="image"
          style="position: inherit; background-color: transparent"
        >
          <div
            style="
              font-family: Georama;
              font-size: medium;
              text-align: right;
              margin-right: 5%;
              margin-top: 25%;
              position: relative;
              z-index: 1;
            "
          >
            <v-sheet
              v-show="showInfo"
              style="
                font-family: Georama;
                font-size: 15px;
                text-align: left;
                line-height: 17.91px
                margin-right: 5%;
                position: absolute;
                padding: 10px;
                background-color: #4d4d4de8;
                border-radius: 10px;
                color: white;
                right: -90px;
                top: -100px;
                width: 250px;
              "
            >
              {{ $t("Desktop.beforePhotoDesktop.mess_alert1", language) }}
            </v-sheet>
            <v-btn
              v-bind="props"
              class="ma-0"
              color="grey"
              @click="showInfo = !showInfo"
              icon
            >
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </div>
          <div v-if="shooterRunning" class="mx-auto" style="text-align: center">
            <img
              height="20%"
              src="https://storage.googleapis.com/smid-static/Attente%20chargement.gif"
            />
          </div>
          <div
            v-if="!shooterRunning"
            style="text-align: center; margin-top: 5%; margin-bottom: 10%"
            class="fontStyle22 mx-10"
          >
            <h4>
              <span style="padding-left: 9px">{{
                $t("Desktop.beforePhotoDesktop.scan_qr", language)
              }}</span>
            </h4>
          </div>
          <div
            v-if="!shooterRunning"
            class="qrcode mx-auto"
            style="text-align: center; margin-bottom: 15%"
          >
            <v-img :src="'data:image/jpeg;base64,' + qrcode.qrCode"></v-img>
            <p>{{ qrcode.url }}</p>
          </div>
          <div
            v-if="!shooterRunning"
            style="text-align: center; margin-bottom: 10%"
          >
            <v-dialog v-model="dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on" flat>
                  <p
                    class="lien mt-20 text-center"
                    style="
                      font-size: 15px;
                      line-height: 15.52px;
                      font-weight: 400;
                      font-family: Georama;
                    "
                  >
                    {{ $t("Desktop.beforePhotoDesktop.how_scan_qr", language) }}
                  </p>
                </a>
              </template>
              <v-card v-if="dialog">
                <div class="play-video">
                  <embed
                    style="width: 500px; height: 350px"
                    :src="
                      $t('Desktop.beforePhotoDesktop.how_scan_qr_url', language)
                    "
                  />
                </div>
                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    style="
                      background-color: black;
                      color: white;
                      font-weight: bold;
                    "
                    class="px-10 rounded-pill bottom fontStyle15"
                    @click="dialog = false"
                  >
                    {{ $t("Desktop.headerDesktop.close", language) }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="4">
        <v-card
          style="background-color: #efefef; border-radius: 10px"
          class="pa-5 ma-5"
        >
          <v-card-title class="text-center justify-center fontStyle22">
            {{ $t("Desktop.beforePhotoDesktop.title_before_photo1", language) }}
          </v-card-title>
          <v-sheet style="background: transparent" class="d-flex flex-column">
            <img
              class="pa-5 mx-auto"
              style="max-height: 400px"
              src="../../assets/images/reduced_size_gif2.gif"
              alt=""
            />
            <br />
            <div>
              <div>
                <v-list
                  class="fontStyle15"
                  style="font-family: Georama; background-color: #efefef"
                >
                  <v-list-item
                    ><v-icon style="font-size: 14px" color="black" class="icon"
                      >mdi-check-bold</v-icon
                    ><span class="numéro fontStyle22">01.</span
                    >{{
                      $t("Desktop.beforePhotoDesktop.off_glasses", language)
                    }}</v-list-item
                  >
                  <v-list-item
                    ><v-icon style="font-size: 14px" color="black" class="icon"
                      >mdi-check-bold</v-icon
                    ><span class="numéro fontStyle22">02.</span
                    >{{
                      $t("Desktop.beforePhotoDesktop.even_light", language)
                    }}</v-list-item
                  >
                  <v-list-item
                    ><v-icon style="font-size: 14px" color="black" class="icon"
                      >mdi-check-bold</v-icon
                    ><span class="numéro fontStyle22">03.</span
                    >{{
                      $t("Desktop.beforePhotoDesktop.hair_behind", language)
                    }}</v-list-item
                  >
                  <v-list-item
                    ><v-icon style="font-size: 14px" color="black" class="icon"
                      >mdi-check-bold</v-icon
                    ><span class="numéro fontStyle22">04.</span
                    >{{
                      $t("Desktop.beforePhotoDesktop.neutral_face", language)
                    }}</v-list-item
                  >
                </v-list>
              </div>
            </div>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { requestService } from "@/services/request.service";
import { localStorageService } from "@/services/localStorage.service";
import { isMobile } from "mobile-device-detect";
export default {
  name: "QrCodeApp",
  data: () => ({
    qrcode: null,
    refreshTimeout: null,
    dialog: false,
    price: {},
    countryCode: {},
    amount: {},
    currency: {},
    showInfo: true,
    shooterRunning: false,
    language: "",
  }),
  methods: {
    /**
     * create the qrCode Request for taking the photo/signature
     * This qrcode will be the link between the current order and the photo/signature done by phone
     */
    load() {
      this.price = localStorageService.getPrice();
      var qrCode = localStorageService.getQrCode();

      if (qrCode !== null) {
        this.qrcode = qrCode;
      } else {
        //Need the take the signature and the photo
        if (
          (this.price.purpose !== null &&
            this.price.purpose.signature_needed) ||
          (this.price.product !== null && this.price.product.signature_needed)
        ) {
          requestService
            .get(`/service/photo-signature-qrcode-request/` + this.price.id)
            .then((data) => {
              this.qrcode = data.data;
              localStorageService.setQrCode(data.data);
            });
        } else {
          //Need to take only the photo
          requestService
            .get(`/service/photo-qrcode-request/` + this.price.id)
            .then((data) => {
              this.qrcode = data.data;
              localStorageService.setQrCode(data.data);
            });
        }
      }

      //Each 5 secondes, check if the photo/signature was taken
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(() => {
        this.uploadPhotoSignature();
      }, 5000);
    },

    /** Navigate to the next screen ( TraitePhoto ) */
    next(photoResizedUid, photoDetouredUid) {
      localStorageService.setPhotoUidDetoured(photoDetouredUid);
      localStorageService.setPhotoUidResized(photoResizedUid);
      var photoDetouredPromise = null;
      var photoResizedPromise = null;

      if (photoDetouredUid !== null) {
        photoDetouredPromise = requestService
          .get(
            `/file/download-anonymously/` +
              photoDetouredUid +
              `/` +
              this.qrcode.uid,
            {},
            {},
            null,
            "arraybuffer"
          )
          .then(function (response) {
            const image64 = btoa(
              String.fromCharCode(...new Uint8Array(response.data))
            );
            localStorageService.setPhotoBase64Detoured(image64);
          });
      }

      if (photoResizedUid !== null) {
        photoResizedPromise = requestService
          .get(
            `/file/download-anonymously/` +
              photoResizedUid +
              `/` +
              this.qrcode.uid,
            {},
            {},
            null,
            "arraybuffer"
          )
          .then(function (response) {
            const image64 = btoa(
              String.fromCharCode(...new Uint8Array(response.data))
            );
            localStorageService.setPhotoBase64Resize(image64);
          });
      }

      Promise.all([photoResizedPromise, photoDetouredPromise]).then(() => {
        if (this.price.purpose.verification_needed) {
          this.$router.push({
            name: "TraitePhoto",
            params: {
              priceId: this.price.id,
              photoUid: this.photoUid,
              signatureUid: this.signatureUid,
              description:
                "suppression de l'arriére-plan traitement supplementaires éventuels garantie que le photo soit acceptée satisfait ou remboursé",
              needEphoto:
                (this.price.purpose !== null &&
                  this.price.purpose.needEphoto) ||
                (this.price.product !== null && this.price.product.needEphoto),
              verificationNeeded:
                (this.price.purpose !== null &&
                  this.price.purpose.verification_needed) ||
                (this.price.product !== null &&
                  this.price.product.verification_needed),
              amount:
                (this.price.purpose !== null && this.price.purpose.amount) ||
                (this.price !== null && this.price.amount),
              currency:
                (this.price.purpose !== null && this.price.purpose.currency) ||
                (this.price !== null && this.price.currency),
              addOffers: this.price.optional_offers.map(function (x) {
                return {
                  id: x.id,
                  name: x.product.label,
                  description: x.product.description,
                  needShipment: x.product.send_by_post_needed,
                  amount: x.amount,
                  currency: x.currency,
                };
              }),
            },
          });
        } else {
          this.$router.push({ name: "ChoosePlanDesk" });
        }
      });
    },

    showQrCode() {
      requestService
        .get(`/service/running-qrcode/${this.qrcode.uid}/0`)
        .then(() => (this.shooterRunning = false));
    },
    /**
     * Check if the photo /signature is done.
     * if it is, save the uid in the localstorage and move to the next screen
     */
    uploadPhotoSignature() {
      requestService
        .get(`/service/qrcode-check/` + this.qrcode.uid, null, null, {
          language: "fr",
        })
        .then((data) => {
          this.shooterRunning = data.data.running;
          if (data.data.needPhoto) {
            if (data.data.photoUrl !== null) {
              localStorageService.setPhotoUid(data.data.photoUid);
              localStorageService.setPhotoUrl(data.data.photoUrl);
              localStorageService.setPhotoUidDigitalDetoured(
                data.data.photoDetouredForDigital
              );
              if (!data.data.needSignature)
                this.next(data.data.photoResized, data.data.photoDetoured); //Go to next screen if signature not needed
            } else {
              //Photo not available, try 5 secondes later
              clearTimeout(this.refreshTimeout);
              this.refreshTimeout = setTimeout(() => {
                this.uploadPhotoSignature();
              }, 5000);
            }
          }
          if (data.data.needSignature) {
            if (data.data.signatureUrl !== null) {
              localStorageService.setSignatureUid(data.data.signatureUid);
              localStorageService.setSignatureUrl(data.data.signatureUrl);
              this.next(data.data.photoResized, data.data.photoDetoured);
            } else {
              //Signature not available, try 5 secondes later
              clearTimeout(this.refreshTimeout);
              this.refreshTimeout = setTimeout(() => {
                this.uploadPhotoSignature();
              }, 5000);
            }
          }
        });
    },
  },
  mounted() {
    if (isMobile) {
      this.$router.push({
        name: "TakePhoto",
      });
    } else {
      this.load();
    }
    this.language = localStorageService.getLanguage();
  },
};
</script>

<style scoped>
.pictureCheck img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.numéro {
  margin-right: 2%;
  font-weight: bold;
}
.image {
  background-image: url("https://storage.googleapis.com/smid-static/mobile-vector.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 5%;
  margin-left: 5%;
  max-width: 90%;
  width: 90%;
}
.icon {
  margin-right: 10px;
  color: #000000;
  border: 2px solid white;
  border-radius: 10%;
  background-color: white;
  width: 24px;
  height: 24px;
}
.qrcode {
  width: 200px;
  height: 200px;
}
.services li {
  margin: 30px;
  margin-left: 65%;
  margin-top: -15px;
}
.vidapp {
  margin-left: 60%;
  margin-top: -370px;
}
vidapp2 {
  color: black;
}
.text {
  color: black;
}
.lien {
  color: black;
  font-family: Georama;
  text-decoration: underline;
  text-decoration-color: black;
}
.fontStyle22 {
  font-size: 22px;
  line-height: 26.27px;
  font-weight: 600;
  font-family: Georama;
}
.fontStyle15 {
  font-size: 15px;
  line-height: 17.91px;
  font-weight: 400;
  font-family: Georama;
}
.services {
  margin-top: -20%;
}
</style>
