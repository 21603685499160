<template>
  <v-container>
    <div class="country">
      <p>Select one photo</p>
    </div>
    <br />
    <template>
      <div>
        <template>
          <button height="72" min-width="350" class="recherche">
            <v-container fluid>
              <v-checkbox v-model="checkbox" color="green">
                <template v-slot:label>
                  <div class="checktext">
                    exempts are applicable to my photo
                  </div>
                </template>
              </v-checkbox>
            </v-container>
          </button>
        </template>
      </div>
    </template>
    <v-row>
      <v-col cols="6">
        <div class="image">
          <div class="ma-1">
            <v-img
              :aspect-ratio="1"
              src="../../assets/images/portrait-expressive-young-woman 1.png"
            >
              <v-img
                class="ronde"
                color="dark"
                :aspect-ratio="1"
                src="../../assets/images/minus-square-Bold.png"
              ></v-img>
            </v-img>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="image">
          <div class="ma-1">
            <v-img
              :aspect-ratio="1"
              src="../../assets/images/portrait-expressive-young-woman 2.png"
              cover
            >
              <v-img
                class="ronde"
                color="dark"
                :aspect-ratio="1"
                src="../../assets/images/minus-square-Bold.png"
              ></v-img>
            </v-img>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="image">
          <div class="ma-1">
            <v-img
              :aspect-ratio="1"
              src="../../assets/images/portrait-expressive-young-woman 3.png"
              cover
            >
              <v-img
                class="ronde"
                color="dark"
                :aspect-ratio="1"
                src="../../assets/images/minus-square-Bold.png"
              ></v-img>
            </v-img>
          </div>
        </div>
      </v-col>
    </v-row>
    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <v-btn class="take2" color="white" rounded @click="loading = !loading">
        <span class="text">Try Another Photo</span>
      </v-btn>
    </div>

    <div
      class="d-flex justify-space-around align-center flex-column flex-sm-row"
    >
      <v-btn class="take" color="white" rounded @click="loading = !loading">
        <span class="text5">Select Photo to Start Order</span>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "PhotoConfirmation",
  data: () => ({
    return: {
      checkbox: false,
    },
  }),
};
</script>

<style scoped>
@media only screen and (max-width: 700px) {
  .text {
    text-transform: capitalize;
  }
  .mini {
    text-transform: lowercase;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 45%;
    margin-left: 4%;
    padding: 16px 125px;
    gap: 10px;

    position: absolute;
    width: 350px;
    height: 52px;
    left: 20px;
    top: 522px;

    /* Primary Dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .take2 {
    margin-top: 30%;
    margin-left: 4%;
    padding: 16px 125px;
    gap: 10px;

    position: absolute;
    width: 350px;
    height: 100%;
    left: 20px;
    top: 522px;

    /* Primary Dark */

    background: white;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .country {
    width: 350px;
    height: 40px;
    margin-top: 20%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .picture {
    margin-left: 10%;
    margin-top: 8%;
  }
  .recherche {
    box-sizing: border-box;
    margin-right: 9.8%;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px 24px 12px;
    gap: 12px;

    width: 400px;
    height: 44px;
    left: 20px;
    top: 186px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;
  }
  .checktext {
    width: 250px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 100;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .text5 {
    text-transform: capitalize;
    color: #4d4d4d;
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;
  }
  .imageText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px 12px 14px;
    gap: 12px;

    width: 260px;
    height: 60px;
    left: 40px;
    top: 604px;
    margin-top: 80%;
    margin-left: 2%;

    background: rgba(2, 149, 255, 0.95);
    border-radius: 4px;
  }
  .ronde {
    left: 6.33%;
    right: 8.33%;
    top: 8.33%;
    bottom: 8.33%;
    margin-top: -8%;

    /* White */

    /* Secondary Gray */
  }
  .position {
    width: 200px;
    height: 36px;
    left: 48px;
    top: 12px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    letter-spacing: 0.2px;

    color: #ffffff;
  }
  .image {
    left: -35.54%;
    right: -36.25%;
    top: -7.65%;
    bottom: -18.82%;
  }

  .image .ronde {
    width: 20px;
    height: 20px;
    margin-left: 75%;
    margin-top: 3%;

    /* Secondary Gray */

    color: black;
  }
}
/* Styles pour les écrans de grande taille */
@media only screen and (min-width: 700px) {
  .text {
    text-transform: capitalize;
  }
  .mini {
    text-transform: lowercase;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 45%;
    margin-left: 4%;
    padding: 16px 125px;
    gap: 10px;

    position: absolute;
    width: 350px;
    height: 52px;
    left: 20px;
    top: 522px;

    /* Primary Dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .take2 {
    margin-top: 30%;
    margin-left: 4%;
    padding: 16px 125px;
    gap: 10px;

    position: absolute;
    width: 350px;
    height: 100%;
    left: 20px;
    top: 522px;

    /* Primary Dark */

    background: white;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .country {
    width: 350px;
    height: 40px;
    margin-top: 20%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .picture {
    margin-left: 10%;
    margin-top: 8%;
  }
  .recherche {
    box-sizing: border-box;
    margin-right: 9.8%;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px 24px 12px;
    gap: 12px;

    width: 400px;
    height: 44px;
    left: 20px;
    top: 186px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;
  }
  .checktext {
    width: 250px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 100;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .text5 {
    text-transform: capitalize;
    color: #4d4d4d;
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;
  }
  .imageText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px 12px 14px;
    gap: 12px;

    width: 260px;
    height: 60px;
    left: 40px;
    top: 604px;
    margin-top: 80%;
    margin-left: 2%;

    background: rgba(2, 149, 255, 0.95);
    border-radius: 4px;
  }
  .ronde {
    left: 6.33%;
    right: 8.33%;
    top: 8.33%;
    bottom: 8.33%;
    margin-top: -8%;

    /* White */

    /* Secondary Gray */
  }
  .position {
    width: 200px;
    height: 36px;
    left: 48px;
    top: 12px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    letter-spacing: 0.2px;

    color: #ffffff;
  }
  .image {
    left: -35.54%;
    right: -36.25%;
    top: -7.65%;
    bottom: -18.82%;
  }

  .image .ronde {
    width: 20px;
    height: 20px;
    margin-left: 75%;
    margin-top: 3%;

    /* Secondary Gray */

    color: black;
  }
}
/**Pour les Galaxy Fold */
@media only screen and (max-width: 280px) {
  .text {
    text-transform: capitalize;
  }
  .mini {
    text-transform: lowercase;
  }
  .take {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 45%;
    margin-left: 4%;
    padding: 16px 125px;
    gap: 10px;

    position: absolute;
    width: 350px;
    height: 52px;
    left: 20px;
    top: 522px;

    /* Primary Dark */

    background: #000000;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
  }
  .take2 {
    margin-top: 30%;
    margin-left: 4%;
    padding: 16px 125px;
    gap: 10px;

    position: absolute;
    width: 350px;
    height: 100%;
    left: 20px;
    top: 522px;

    /* Primary Dark */

    background: white;
    box-shadow: 0px 0.5px 16px rgba(0, 0, 0, 0.08);
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .country {
    width: 350px;
    height: 40px;
    margin-top: 20%;

    font-family: "Georama";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    /* Primary dark */

    color: #000000;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .picture {
    margin-left: 10%;
    margin-top: 8%;
  }
  .recherche {
    box-sizing: border-box;
    margin-right: 9.8%;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px 24px 12px;
    gap: 12px;

    width: 400px;
    height: 44px;
    left: 20px;
    top: 186px;

    /* White */

    background: #ffffff;
    /* Tetriary Ligjht */

    border: 1px solid #efefef;
    border-radius: 4px;
  }
  .checktext {
    width: 250px;
    height: 20px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    /* Secondary Gray */

    color: #4d4d4d;
    font-stretch: 100;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .text5 {
    text-transform: capitalize;
    color: #4d4d4d;
    font-family: "Georama";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;
  }
  .imageText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px 12px 14px;
    gap: 12px;

    width: 260px;
    height: 60px;
    left: 40px;
    top: 604px;
    margin-top: 80%;
    margin-left: 2%;

    background: rgba(2, 149, 255, 0.95);
    border-radius: 4px;
  }
  .ronde {
    left: 6.33%;
    right: 8.33%;
    top: 8.33%;
    bottom: 8.33%;
    margin-top: -8%;

    /* White */

    /* Secondary Gray */
  }
  .position {
    width: 200px;
    height: 36px;
    left: 48px;
    top: 12px;

    font-family: "Georama";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    letter-spacing: 0.2px;

    color: #ffffff;
  }
  .image {
    left: -35.54%;
    right: -36.25%;
    top: -7.65%;
    bottom: -18.82%;
  }

  .image .ronde {
    width: 20px;
    height: 20px;
    margin-left: 75%;
    margin-top: 3%;

    /* Secondary Gray */

    color: black;
  }
}
</style>
